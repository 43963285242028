import {
    faCheckCircle,
    faExclamationCircle,
    faExclamationTriangle,
    faQuestionCircle,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import styles from "./css/SnackBar.module.css";

const iconProps = {
    info: {
        icon: faQuestionCircle,
        alt: "info",
        title: "info",
    },
    success: {
        icon: faCheckCircle,
        alt: "success",
        title: "success",
    },
    warning: {
        icon: faExclamationCircle,
        alt: "warning",
        title: "warning",
    },
    error: {
        icon: faExclamationTriangle,
        alt: "error",
        title: "error",
    },
};

/**
 * @param {Object} props
 * @param {string!} props.type - info, success, warning, error
 * @param {(string|JSX.Element)} props.children
 * @param {string} [props.size=big] - big, small
 * @param {number} [props.duration=5000]
 * @param {function} [props.action]
 * @param {string} [props.actionDesc]
 * @param {string} [props.className]
 * @param {function} [props.onClose]
 */
function SnackBar({
    type,
    children: msg,
    size = "big",
    duration = 5000,
    action,
    actionDesc,
    className,
    onClose,
}) {
    useEffect(() => {
        const timeout = setTimeout(() => {
            onClose();
        }, duration);

        return () => {
            clearTimeout(timeout);
        };
    }, [duration]);

    return (
        <div className={`${styles.bar} ${styles[type]} ${styles[size]} ${className ?? ""}`}>
            <FontAwesomeIcon {...iconProps[type]} size="1x" className={styles.icon} />
            <span>{msg}</span>
            <div className={styles.separator}></div>
            {action && (
                <button
                    onClick={() => {
                        action();
                        onClose();
                    }}
                    className={styles["action-btn"]}
                >
                    {actionDesc}
                </button>
            )}
            <button onClick={() => onClose()} className={styles["close-btn"]}>
                <FontAwesomeIcon icon={faTimes} size="1x" className={styles.icon} />
            </button>
        </div>
    );
}

export default SnackBar;

export function SnackGroup({ children: snacks, position }) {
    return <div className={`${styles.group} ${styles[position]}`}>{snacks}</div>;
}
