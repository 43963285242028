import { useCallback, useEffect, useState } from "react";
import { ReactComponent as IconReset } from "../svg/IconReload.svg";
import styles from "./css/RangeSlider.module.css";

/**
 * @typedef RangeOptions
 * @type {object}
 * @property {number} min - min value of slider
 * @property {number} max - max value of slider
 * @property {number} [step=1] - step value of slider
 * @property {number} [defaultValue=0] - default value of slider
 * @property {Boolean} [showIndicator=true] - show current value indicator
 * @property {Boolean} [lazy=true] - when false, trigger `onChange` given to `RangeSlider` whenever change event is triggered in the wrapped <input>
 * @property {Boolean} [controlled=true] - whether the input's value is decided by `defaultValue`. Note `defaultValue` does not
 *
 * @param {{name: string, value: number, action: function, options: RangeOptions}}
 */
function RangeSlider({ name, unit = "", onChange, options, disabled = false }) {
    const { min, max, step = 1, currentValue = 0, defaultValue = 0, showIndicator = true, lazy = true } = options;
    const [value, setValue] = useState(defaultValue);

    const changeAndUpdate = useCallback(
        (value) => {
            setValue(Number(value));
            if (!lazy) {
                onChange(Number(value));
            }
        },
        [setValue, lazy, onChange]
    );

    useEffect(() => {
        setValue(currentValue);
    }, [currentValue])

    return (
        <div className={styles.container}>
            <h3 className={styles.label}>{name}</h3>
            <span className={styles.range}>
                <div className={`${styles["slider-wrapper"]}`}>
                    <input
                        type="range"
                        value={value}
                        {...{ min, max, step }}
                        onChange={(e) => changeAndUpdate(e.target.value)}
                        onMouseUp={() => onChange(value)}
                        onTouchEnd={() => onChange(value)}
                        disabled={disabled}
                    />
                </div>

                {showIndicator && (
                    <span className={styles.indicator}>
                        {value.toFixed(1)} {unit}
                    </span>
                )}
            </span>
            <button
                type="button"
                className={styles.reset}
                disabled={disabled}
                onClick={() => {
                    setValue(defaultValue);
                    onChange(defaultValue);
                }}
                onTouchEnd={(e) => {
                    setValue(defaultValue);
                    onChange(defaultValue);
                    e.preventDefault();
                }}
            >
                <IconReset width="10px" height="10px" />
            </button>
        </div>
    );
}

export default RangeSlider;
