import { useHistory } from "react-router-dom";
import styles from "./css/EntityAbsence.module.css";

import { onClickAndTouchEnd } from "../lib/utils";
import { ReactComponent as IconNoFile } from "../svg/IconNoFile.svg";
import Icon from "./Icon";

function EntityAbsence({
    title,
    description = null,
    btnText = null,
    icon = IconNoFile,
    iconClass = "",
    path = "/main/new-record",
}) {
    const history = useHistory();

    return (
        <div className={styles.container}>
            <Icon className={iconClass} icon={icon} type="svg" width="80px" height="80px"></Icon>
            <h1 className={styles.title}>{title}</h1>
            {description && <p>{description}</p>}
            {btnText && (
                <button className={styles.button} {...onClickAndTouchEnd(() => history.push(path))}>
                    {btnText}
                </button>
            )}
        </div>
    );
}

export default EntityAbsence;
