import { useEffect, useState } from "react";

import { DEFAULT_PTZ_STATE } from "../consts";
import RangeSlider from "./RangeSlider";

import { usePTZControl } from "./PTZControlProvider";
import ActionStyles from "./css/ActionTab.module.css";
import PTZStyles from "./css/PTZ.module.css";

const PTZ_LEFT = "LEFT";
const PTZ_RIGHT = "RIGHT";
const PTZ_FRONT = "FRONT";
const PTZ_TOP = "TOP";
const PTZ_CUSTOM = "CUSTOM";

const PTZStateValue = {
    left: {
        pan: 180,
        tilt: 0
    },
    right: {
        pan: 0,
        tilt: 0
    },
    front: {
        pan: 90,
        tilt: 0
    },
    top: {
        pan: 90,
        tilt: 90
    }
}

const PTZControlPanel = ({ addToast, setSelectedAction, addPTZInspect }) => {
    const { setInPTZControl, pan, tilt, zoom, changePTZMovement, sendPTZMovement } = usePTZControl();
    const [customPosition, setCustomPosition] = useState({ pan: pan, tilt: tilt });
    const [activeButton, setActiveButton] = useState("")

    const handleRangeSliderChange = (angle, value) => {
        changePTZMovement({ angle: angle, value: value })
    }

    const handleButtonPTZPosition = (event) => {
        switch (event.target.innerHTML) {
            case "Left":
                changePTZMovement({ angle: "pan", value: PTZStateValue.left.pan });
                changePTZMovement({ angle: "tilt", value: PTZStateValue.left.tilt });
                break;
            case "Right":
                changePTZMovement({ angle: "pan", value: PTZStateValue.right.pan });
                changePTZMovement({ angle: "tilt", value: PTZStateValue.right.tilt });
                break;
            case "Front":
                changePTZMovement({ angle: "pan", value: PTZStateValue.front.pan });
                changePTZMovement({ angle: "tilt", value: PTZStateValue.front.tilt });
                break;
            case "Top":
                changePTZMovement({ angle: "pan", value: PTZStateValue.top.pan });
                changePTZMovement({ angle: "tilt", value: PTZStateValue.top.tilt });
                break;
            case "Custom":
                changePTZMovement({ angle: "pan", value: customPosition.pan });
                changePTZMovement({ angle: "tilt", value: customPosition.tilt });
                break;
            default:
                return;
        }
    };

    const cancelPTZAction = () => {
        setSelectedAction(null);
        setInPTZControl(false);
    };

    const addPTZAction = () => {
        addToast({
            type: "info",
            message: "Adding PTZ inspection to mission...",
            duration: 2000,
        });
        addPTZInspect({ pan: pan, tilt: tilt });
        sendPTZMovement({ pan: pan, tilt: tilt });

        setSelectedAction(null);
        setInPTZControl(false);
    };

    useEffect(() => {
        const position = JSON.stringify({
            pan: pan,
            tilt: tilt
        });

        if (position === JSON.stringify(PTZStateValue.left)) {
            setActiveButton(PTZ_LEFT);
        }
        else if (position === JSON.stringify(PTZStateValue.right)) {
            setActiveButton(PTZ_RIGHT);
        }
        else if (position === JSON.stringify(PTZStateValue.front)) {
            setActiveButton(PTZ_FRONT);
        }
        else if (position === JSON.stringify(PTZStateValue.top)) {
            setActiveButton(PTZ_TOP);
        }
        else if (position === JSON.stringify((customPosition))) {
            setActiveButton(PTZ_CUSTOM);
        }
        else {
            setActiveButton("");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pan, tilt]);

    return (
        <>
            <div className={PTZStyles["PTZ-Control-Panel"]}>
                <hr className={PTZStyles["hr"]}></hr>
                <h2>PTZ Action:</h2>
                <h5>Current Pan: {pan}° - Tilt: {tilt}° - Zoom: {zoom}</h5>
                <div className={PTZStyles["PTZ-Button-Panel"]}>
                    <button
                        className={`${PTZStyles["PTZ-button"]} ${activeButton === PTZ_LEFT ? ActionStyles["active"] : ""}`}
                        onClick={(e) => handleButtonPTZPosition(e)}
                    >
                        Left
                    </button>
                    <button
                        className={`${PTZStyles["PTZ-button"]} ${activeButton === PTZ_RIGHT ? ActionStyles["active"] : ""}`}
                        onClick={(e) => handleButtonPTZPosition(e)}
                    >
                        Right
                    </button>
                    <button
                        className={`${PTZStyles["PTZ-button"]}
                        ${activeButton === PTZ_FRONT ? ActionStyles["active"] : ""}`}
                        onClick={(e) => handleButtonPTZPosition(e)}
                    >
                        Front
                    </button>
                    <button
                        className={`${PTZStyles["PTZ-button"]} ${activeButton === PTZ_TOP ? ActionStyles["active"] : ""}`}
                        onClick={(e) => handleButtonPTZPosition(e)}
                    >
                        Top
                    </button>
                    <button
                        className={`${PTZStyles["PTZ-button"]} ${activeButton === PTZ_CUSTOM ? ActionStyles["active"] : ""}`}
                        onClick={(e) => handleButtonPTZPosition(e)}
                    >
                        Custom
                    </button>
                    <div className={PTZStyles["panel-box"]}>
                        <h5>Custom Pan: {customPosition.pan}</h5>
                        <h5>Custom Tilt: {customPosition.tilt}</h5>
                    </div>
                </div>
                <div className={PTZStyles["slider-panel"]}>
                    <RangeSlider
                        name="Pan"
                        unit="Degree"
                        onChange={(value) => handleRangeSliderChange("pan", value)}
                        options={{
                            min: 0,
                            max: 180,
                            step: 1,
                            currentValue: pan,
                            defaultValue: DEFAULT_PTZ_STATE.pan,
                            lazy: true
                        }}
                    ></RangeSlider>
                    <RangeSlider
                        name="Tilt"
                        unit="Degree"
                        onChange={(value) => handleRangeSliderChange("tilt", value)}
                        options={{
                            min: 0,
                            max: 90,
                            step: 1,
                            currentValue: tilt,
                            defaultValue: DEFAULT_PTZ_STATE.tilt,
                            lazy: true
                        }}
                    ></RangeSlider>
                    <RangeSlider
                        name="Zoom"
                        unit="Zoom"
                        onChange={(value) => handleRangeSliderChange("zoom", value)}
                        options={{
                            min: 0.0,
                            max: 1.0,
                            step: 0.1,
                            currentValue: zoom,
                            defaultValue: DEFAULT_PTZ_STATE.zoom,
                            lazy: true
                        }}
                    ></RangeSlider>
                </div>
                <hr className={PTZStyles["hr"]}></hr>
                <div className={PTZStyles["PTZ-Button-Panel"]}>
                    <button
                        className={`${PTZStyles["PTZ-button"]} ${PTZStyles["button-save"]}`}
                        onClick={addPTZAction}
                    >
                        Save
                    </button>
                    <button
                        className={`${PTZStyles["PTZ-button"]} ${PTZStyles["button-cancel"]}`}
                        onClick={cancelPTZAction}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </>
    );
}

export default PTZControlPanel;
