import { HashRouter, Route, Switch } from "react-router-dom";
import AuthProvider from "./components/AuthProvider";
import ErrorBoundary from "./components/ErrorBoundary";
import Fallback from "./components/Fallback";
import GamepadList from "./components/GamepadList";
import LoginPanel from "./components/LoginPanel";
import MainPanel from "./components/MainPanel";
import ProtectedRoute from "./components/ProtectedRoute";
import Providers from "./components/Providers";

import "./App.css";

function App() {
    return (
        <ErrorBoundary>
            <AuthProvider>
                <HashRouter>
                    <Switch>
                        <Route exact path="/login">
                            <LoginPanel />
                        </Route>
                        <ProtectedRoute path="/main">
                            <Providers>
                                <MainPanel></MainPanel>
                            </Providers>
                        </ProtectedRoute>
                        <Route path="/gamepad">
                            <GamepadList />
                        </Route>
                        <Route path="/">
                            <Fallback />
                        </Route>
                    </Switch>
                </HashRouter>
            </AuthProvider>
        </ErrorBoundary>
    );
}

export default App;
