import React, { useState } from "react";
import { ReactComponent as IconBell } from "../svg/IconBell.svg";
import { ReactComponent as IconBellBadged } from "../svg/IconBellBadged.svg";
import styles from "./css/Notification.module.css";
import MenuButton from "./MenuButton";
import NotificationPanel from "./NotificationPanel";
import { useNoti } from "./NotificationProvider";
import { useRobotErrors } from "./RobotErrorProvider";

const NotificationArea = () => {
    const { hasUnreadNoti, hasNewNoti } = useNoti();
    const { hasNewErrors } = useRobotErrors();
    const [showNotification, setShowNotification] = useState(false);

    return (
        <React.Fragment>
            <MenuButton
                icon={hasUnreadNoti ? IconBellBadged : IconBell}
                iconType="svg"
                btnClass={`${(hasNewNoti && styles["new-noti"]) || ""} ${
                    (hasNewErrors && styles["new-errors"]) || ""
                }`}
                iconClass={(hasUnreadNoti && styles["unread-noti"]) || null}
                action={() => setShowNotification(!showNotification)}
            ></MenuButton>
            <NotificationPanel
                closePanel={() => setShowNotification(false)}
                isShow={showNotification}
            ></NotificationPanel>
        </React.Fragment>
    );
};

export default NotificationArea;
