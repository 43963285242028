import Logo from "./Logo";
import React, { useContext, useState  } from "react";
import { COMPACT_MODE } from "../consts";
import DeviceStatus from "./DeviceStatus";
import EStop from "./EStop";
import PowerSwitch from "./PowerSwitch";

import { useSocket } from "./ControlSocketProvider";
import styles from "./css/Header.module.css";
import { STATUS_TYPE_JETPACK, STATUS_TYPE_SPOT } from "./DeviceStatus";
import { RobotStateContext } from "./RobotStateProvider";
import TimeDisplay from "./TimeDisplay";

const jetpackName = process.env.REACT_APP_JETPACK_SN;
const robotName = "Explorer";

function StatusContainer() {
    const { socketState } = useSocket();
    const { motorState, robotConnectionState } = useContext(RobotStateContext);
    return (
        <div className={styles["status-container"]}>
            <DeviceStatus
                device={STATUS_TYPE_JETPACK}
                name={jetpackName}
                showTemp={false}
                showBattery={false}
                statuses={[socketState]}
            />
            <DeviceStatus
                device={STATUS_TYPE_SPOT}
                name={robotName}
                statuses={[motorState, robotConnectionState]}
            />
        </div>
    );
}

const Spacer = () => <div className={styles.spacer} aria-hidden={true}></div>;

function Header() {
    const user = process.env.REACT_APP_USER;
    return (
        <>
            <header className={`${styles.container} ${COMPACT_MODE ? styles.compact : ""}`}>
                <Logo
                    className={styles.logo}
                    clientName={user}
                    clientLogo={`/${process.env.REACT_APP_LOGO}`}
                />
                {COMPACT_MODE && (
                    <>
                        <Spacer />
                        <PowerSwitch className={styles["power-switch"]} />
                        <StatusContainer />
                    </>
                )}
                {!COMPACT_MODE && (
                    <>
                        <StatusContainer />
                        <PowerSwitch />
                        <Spacer />
                    </>
                )}
                <div
                    className={`${!COMPACT_MODE ? `${styles["estop-margin"]}` : ``} ${
                        styles["header-end"]
                    }`}
                >
                    <EStop />
                    {COMPACT_MODE && <TimeDisplay />}
                </div>
            </header>
        </>
    );
}

export default React.memo(Header);
