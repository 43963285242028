import { useRef, useState } from "react";
import styles from "./css/MissionEditor.module.css";
import DialogBox from "./DialogBox";
import { useMission } from "./MissionProvider";

// list onclose -> define outside component
// close onclose -> define outside component

function MissionEditor({ currName, onClose, editAction }) {
    const newNameInput = useRef();
    const [error, setError] = useState(null);
    const { listMission, renameMission, deleteMission } = useMission();

    const handleRename = (e) => {
        e.preventDefault();
        const newName = newNameInput.current.value.trim();
        setError(null);

        if (currName !== newName) {
            if (newName === "") {
                setError("Mission name cannot be empty");
                return;
            }
            renameMission(currName, newName)
                .then(() => {
                    listMission(true);
                    onClose(newName);
                })
                .catch((err) => {
                    setError(err.message);
                });
        } else {
            setError("Same with original name");
        }
    };

    const handleDelete = () => {
        setError(null);

        deleteMission(currName)
            .then(() => {
                listMission(true);
                onClose(null);
            })
            .catch((err) => {
                setError(err.message);
            });
    };

    const resetInputs = () => {
        this.newNameInput.current.value = "";
    };

    return (
        <>
            {editAction === "rename" && (
                <DialogBox
                    title="Rename Mission"
                    textConfirm="Save"
                    options={{ confirmStyle: { backgroundColor: "#696ad2" } }}
                    onConfirm={handleRename}
                    onCancel={() => onClose(currName)}
                >
                    <section className={styles.input}>
                        <label htmlFor="new-mission-name">Name of the mission</label>
                        <input
                            type="text"
                            id="new-mission-name"
                            name="new-mission-name"
                            placeholder="Mission name"
                            ref={newNameInput}
                            defaultValue={currName}
                        />
                        {error && <p className={styles.error}>{error}</p>}
                    </section>
                </DialogBox>
            )}
            {editAction === "delete" && (
                <DialogBox
                    title="Delete Mission"
                    textConfirm="Delete"
                    onConfirm={handleDelete}
                    onCancel={() => onClose(currName)}
                >
                    The recorded mission cannot be recovered. Are you sure to delete {currName}?
                </DialogBox>
            )}
        </>
    );
}
export default MissionEditor;
