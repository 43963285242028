import { useContext, useEffect, useMemo, useState } from "react";
import {
    STATE_CLAIM_CLAIMED,
    STATE_MOTOR_POWERED_OFF,
    STATE_MOTOR_POWERED_ON,
    STATE_MOTOR_UNKNOWN,
    STATE_ROBOT_CONNECTED,
    STATE_ROBOT_DISCONNECTED,
} from "../lib/state";

import { claimEstop, configEstop, motorOff } from "../lib/protocols/control";
import { useSocket } from "./ControlSocketProvider";
import ErrorPopup from "./ErrorPopup";
import { NotificationContext } from "./NotificationProvider";
import { RobotStateContext } from "./RobotStateProvider";
import Tooltip from "./Tooltip";

import { onClickAndTouchEnd } from "../lib/utils";
import styles from "./css/Header.module.css";

// internal states
const ESTOP_ROBOT_NOT_CONNECTED = "ESTOP_ROBOT_NOT_CONNECTED";
const ESTOP_NOT_CLAIMED = "ESTOP_NOT_CLAIMED";
const ESTOP_READY = "ESTOP_READY";
const ESTOP_HARD_STOPPED = "ESTOP_HARD_STOPPED";
const ESTOP_SOFT_STOPPED = "ESTOP_SOFT_STOPPED";
const estopStateToMsg = (state) => {
    switch (state) {
        case ESTOP_ROBOT_NOT_CONNECTED:
            return "Robot is not connected";
        case ESTOP_READY:
            return "Stop the robot now!";
        case ESTOP_HARD_STOPPED:
            return "Robot stopped by hardware estop";
        case ESTOP_SOFT_STOPPED:
            return "Reactivate the robot";
        default:
        case ESTOP_NOT_CLAIMED:
            return "Estop access not claimed";
    }
};
const EstopConfirmDialog = ({ cancel, confirm, setSnack,estopState }) => {
    let estop_state_css, estop_state_string;
    if(!estopState.software){
        estop_state_css = "red"
        estop_state_string = "Emergency Stop"
    }else{
        estop_state_css = "green"
        estop_state_string = "Unlock Spot"
    }
    return (
        <>
            <div className={`${styles["estop-dialog"]}`}>
                <h2 className={`${styles["estop-dialog-title"]}`}>
                    Are You Sure
                    <span className={`${styles[estop_state_css]}`}> {estop_state_string} </span>
                    ?
                </h2>
                <div className={`${styles["estop-dialog-row"]}`}>
                    <button
                        className={"emergency-stop-cancel-btn emergency-stop-confirm-btn "}
                        onClick={cancel}
                    >
                        CANCEL
                    </button>
                    <button
                        className={"emergency-stop-confirm-btn"}
                        onClick={(e) => {
                            confirm(e);
                            cancel();
                            setSnack({
                                type: "info",
                                message: `${estop_state_string}!!`,
                                duration: 3000,
                            });
                        }}
                    >
                        SURE
                    </button>
                </div>
                <div className={`${styles["estop-container"]}`}></div>
            </div>
        </>
    );
};

function EStop() {
    const { sendControl } = useSocket();
    const { setSnack } = useContext(NotificationContext);
    const { motorState, estopState, eStopClaimState, robotConnectionState } =
        useContext(RobotStateContext);
    const [lostControl, setLostControl] = useState(false);
    const [showEstopConfirmPopup, setShowEstopConfirmPopup] = useState(false);

    /**
     * states for estop button
     * 1. no estop claim -> grey, "CLAIM ESTOP" -> ESTOP_NOT_CLIAMED
     * 2. has estop claim, not estopped -> red, "EMERGENCY STOP" -> ESTOP_READY
     * 3. has estop claim, hardware stopped -> grey, "HARDWARE STOPPED" -> ESTOP_HARD_STOPPED
     * 4. has estop claim, software stopped -> green, "UNLOCK" -> ESTOP_SOFT_STOPPED
     */
    const internalState = useMemo(() => {
        if (robotConnectionState === STATE_ROBOT_DISCONNECTED) return ESTOP_ROBOT_NOT_CONNECTED;
        if (eStopClaimState !== STATE_CLAIM_CLAIMED) return ESTOP_NOT_CLAIMED;
        if (estopState.hardware) return ESTOP_HARD_STOPPED;
        if (estopState.software) return ESTOP_SOFT_STOPPED;
        return ESTOP_READY;
    }, [robotConnectionState, eStopClaimState, estopState]);

    useEffect(() => {
        if (motorState === STATE_MOTOR_POWERED_ON && internalState === ESTOP_NOT_CLAIMED)
            setLostControl(true);
    }, [internalState, motorState]);

    const handleClick = () => {
        if (robotConnectionState === STATE_ROBOT_DISCONNECTED) {
            setSnack({
                type: "error",
                message: "Robot is not connected. Please connect it to Jetpack.",
            });
        } else if (estopState.hardware) {
            setSnack({
                type: "error",
                message: "Hardware emergency stop is active. Deactivate to power on robot.",
            });
        } else if (eStopClaimState === STATE_CLAIM_CLAIMED) {
            console.log("Confirm EStop");
            setShowEstopConfirmPopup((prev) => !prev);
        } else if (motorState === STATE_MOTOR_POWERED_ON) {
            setSnack({
                type: "error",
                message:
                    "You do not have access to use emergency stop. To claim access, turn off motor and claim access.",
                action: () => {
                    sendControl(motorOff());
                },
                actionDesc: "Power off",
                duration: 20000,
            });
        } else if (motorState === STATE_MOTOR_POWERED_OFF) {
            setSnack({
                type: "error",
                message: "You do not have permission to use emergency stop.",
                action: () => {
                    sendControl(claimEstop());
                },
                actionDesc: "Fix issue",
                duration: 20000,
            });
        } else if (motorState === STATE_MOTOR_UNKNOWN) {
            setSnack({
                type: "error",
                message: "Motor is in an unknown state. Cannot perform emergency stop",
            });
        }
    };

    return (
        <>
            <div className={`${styles["estop-position"]}`}>
                <Tooltip content={estopStateToMsg(internalState)}>
                    <button
                        type="button"
                        className={styles.estop}
                        data-state={internalState}
                        {...onClickAndTouchEnd(() => handleClick())}
                    >
                        {estopState.hardware
                            ? "Hardware stopped"
                            : estopState.software
                            ? "Unlock robot"
                            : "Emergency stop"}
                    </button>
                </Tooltip>
                {showEstopConfirmPopup && (
                    <EstopConfirmDialog
                        setSnack={setSnack}
                        cancel={() => setShowEstopConfirmPopup(false)}
                        estopState = {estopState}
                        confirm={() => sendControl(configEstop(!estopState.software))}
                    />
                )}
            </div>

            {robotConnectionState === STATE_ROBOT_CONNECTED && lostControl && (
                <ErrorPopup
                    type="error"
                    title="Lost control to the robot"
                    content="For safety, emergency stop must be ready all the time. To control the robot, make sure you have access to emergency stop."
                    onClose={() => setLostControl(false)}
                ></ErrorPopup>
            )}
        </>
    );
}

export default EStop;
