import { useEffect, useState } from "react";
import { toTimestamp, normalizeDetectResultLink } from "../lib/utils";
import styles from "./css/LogHistory.module.css";

function LogHistoryInspection({ isShow = true, events = [] }) {
    const eventToImg = (event) => {
        return normalizeDetectResultLink(event?.data?.img);
    };
    const [selectedImg, setSelectedImg] = useState(null);
    const eventToName = (event) => `Art ${event?.data?.img.substr(0, 1)}`;
    const eventToDamaged = (event) => {
        return event?.data?.result ? event.data.result : "Unknown";
    };

    useEffect(() => {
        setSelectedImg(eventToImg(events[0]));
    }, [events]);
    if (!isShow) return <></>;
    return (
        <>
            <img src={selectedImg} alt="" className={styles["inspection-img"]} />
            <div className={styles["detail"]}>
                <h2 className={styles["sub-title"]}>Details</h2>
                {/* don't change the table id as export function depends on it */}
                <table id="inspection-events-details" className={styles["detail-table"]}>
                    <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Target name</th>
                            <th>Damaged</th>
                        </tr>
                    </thead>
                    <tbody>
                        {events.length > 0 ? (
                            events.map((event, index) => (
                                <tr key={index} onClick={() => setSelectedImg(eventToImg(event))}>
                                    <td>{toTimestamp(event.timestamp)}</td>
                                    <td>{eventToName(event)}</td>
                                    <td>{eventToDamaged(event)}</td>
                                </tr>
                            ))
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default LogHistoryInspection;
