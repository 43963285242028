import { useEffect, useRef } from "react";

/**
 *
 * @param {function} onClickOutside - callback for clicking outside the element
 * @returns React ref
 */
export default function useClickOutside(onClickOutside) {
    const ref = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                // alert("You clicked outside of me!");
                onClickOutside();
            }
        };

        document.addEventListener("mousedown", handleClickOutside, true);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, true);
        };
    }, [ref, onClickOutside]);
    return ref;
}
