import React, { useCallback, useContext, useEffect, useState } from "react";
import { STATE_STREAM_RECORD_STARTED, TOPIC_STREAM_STATE_RECORD } from "../lib/state";

import { setVideoRecording } from "../lib/protocols/streams";
import { subscribe, unsubscribe } from "../lib/socket";
import { useSocket } from "./ControlSocketProvider";

export const StreamControlContext = React.createContext();
StreamControlContext.displayName = "StreamControl";
export const StreamControlConsumer = StreamControlContext.Consumer;
export const useStreamControl = () => useContext(StreamControlContext);

function StreamControlProvider({ children }) {
    const { controlSocket: socket, sendControl } = useSocket();

    const startVideoRecording = () => sendControl(setVideoRecording(true));
    const stopVideoRecording = () => sendControl(setVideoRecording(false));

    const [videoIsRecording, setvideoIsRecording] = useState(false);
    const [videoRecords, setVideoRecords] = useState([]);

    const handlevideoIsRecording = useCallback(({ event, videos }) => {
        setvideoIsRecording(event === STATE_STREAM_RECORD_STARTED);
        setVideoRecords(videos);
    }, []);

    useEffect(() => {
        socket && subscribe(socket, TOPIC_STREAM_STATE_RECORD, handlevideoIsRecording);

        return () => {
            socket && unsubscribe(socket, TOPIC_STREAM_STATE_RECORD, handlevideoIsRecording);
        };
    }, [socket, handlevideoIsRecording]);

    return (
        <StreamControlContext.Provider
            value={{
                startVideoRecording,
                stopVideoRecording,
                videoIsRecording,
                videoRecords,
            }}
        >
            {children}
        </StreamControlContext.Provider>
    );
}

export default StreamControlProvider;
