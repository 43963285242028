import sha from "sha.js";

export async function digest(data) {
    if (Object.keys(window.crypto).length !== 0) {
        // crypto is not supported in insecure context
        const msgUint8 = new TextEncoder().encode(data); // encode as (utf-8) Uint8Array
        const hashBuffer = await crypto.subtle.digest("SHA-512", msgUint8); // hash the data
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
        return hashHex;
    } else {
        return new sha.sha512().update(data).digest("hex");
    }
}

export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function deleteCookie(cname) {
    document.cookie = cname + "=;" + new Date(1970, 0, 1).toUTCString() + ";path=/";
}

export function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
