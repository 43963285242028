import styles from "./css/DialogBox.module.css";

function DialogBox({
    title = "",
    textConfirm = "Confirm",
    textCancel = "Cancel",
    // confirmStyle = null,
    // cancelStyle = null,
    titleStyle = null,
    onConfirm = null,
    formStyle = null,
    onCancel,
    options = {},
    style = {},
    children,
}) {
    // default position is fixed to the window,
    // if you want it to absolute to certain element, provide the inset value in absolute property of options
    const { confirmStyle, cancelStyle, confirmDisabled, cancelDisabled, areYouSure, absolute } =
        options;
    const titleText = areYouSure ? (
        <span>
            Are you sure to <span style={{ color: "rgb(204, 30, 36)" }}>"{areYouSure}" </span>?
        </span>
    ) : (
        title
    );

    const absoluteStyle = { position: "absolute", inset: absolute, zIndex: 80 };

    return (
        <div
            className={(!absolute && styles.container) || null}
            style={(absolute && absoluteStyle) || null}
        >
            <form
                style={formStyle}
                className={styles.form}
                style={style}
                onSubmit={(e) => {
                    e.preventDefault();
                    onConfirm();
                }}
            >
                <h2 className={`${styles.title}`} style={titleStyle}>
                    {title}
                </h2>
                <div style={{ margin: "1rem auto" }}>{children}</div>

                <div className={styles["btn-wrapper"]}>
                    {textCancel && (
                        <button
                            type="button"
                            className={styles.cancel}
                            style={cancelStyle}
                            onClick={onCancel}
                            disabled={cancelDisabled}
                        >
                            {textCancel}
                        </button>
                    )}
                    {textConfirm && (
                        <button
                            type="submit"
                            className={styles.confirm}
                            style={confirmStyle}
                            disabled={confirmDisabled}
                        >
                            {textConfirm}
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default DialogBox;
