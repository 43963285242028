import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import DialogBox from "./DialogBox";
import ProgressBar from "./ProgressBar";
import ReactPortal from "./ReactPortal";

import { missionDelete, missionRename } from "../lib/protocols/control";
import { callSpotService } from "../lib/service";
import { STATE_MISSION_GENERATED, STATE_MISSION_GENERATING } from "../lib/state";
import styles from "./css/MissionTab.module.css";
import utilStyles from "./css/utils.module.css";
import { useMission } from "./MissionProvider";
import { useSnack } from "./SnackProvider";

function RecordSavePrompt({ onClose }) {
    const { recordState, recordPayload, listMission, missions } = useMission();
    const { addToast, setSnack } = useSnack();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [generateProgress, setGenerateProgress] = useState(null);
    const [missionName, setMissionName] = useState(null);
    const [latestMission, setLatestMission] = useState(null);
    const [renameError, setRenameError] = useState(null);

    // fake progress bar
    useEffect(() => {
        let timeoutId;
        if (recordState === STATE_MISSION_GENERATING) {
            setGenerateProgress(1);
            timeoutId = setInterval(() => {
                setGenerateProgress((prev) =>
                    prev >= 99 ? 99 : prev + Math.floor(Math.random() * 3 + 1)
                );
            }, 500);
        } else if (recordState === STATE_MISSION_GENERATED) {
            clearTimeout(timeoutId);
            setGenerateProgress(100);
            setTimeout(() => setGenerateProgress(null), 750);

            const { mission_id } = recordPayload;
            setLatestMission(mission_id);
        }
        return () => clearInterval(timeoutId);
    }, [recordState, recordPayload]);

    const handleSave = () => {
        if (!missionName) {
            setSnack({ type: "success", message: `Successfully saved to ${latestMission}` });
            onClose();
        } else {
            callSpotService(...missionRename(latestMission, missionName))
                .then(() => {
                    setSnack({ type: "success", message: `Successfully saved to ${missionName}` });
                    onClose();
                })
                // .catch((err) => addToast({ type: "error", message: err.message }));
                .catch((err) => {
                    setRenameError(
                        err.message ||
                            `Unknown error. Failed to rename mission. Save as-is (${latestMission}}`
                    );
                });
        }
    };

    const handleDelete = () => {
        callSpotService(...missionDelete(latestMission))
            .then(() => {
                setSnack({ type: "success", message: `Deleted ${latestMission}` });
                setShowDeleteConfirm(false);
                onClose();
            })
            .catch((err) => addToast({ type: "error", message: err.message }));
    };

    return (
        <ReactPortal wrapperId="dialog-portal">
            <>
                <DialogBox
                    title="FINISH THIS RECORD?"
                    textConfirm="SAVE"
                    textCancel="DELETE"
                    options={{
                        confirmStyle: { backgroundColor: "hsla(239, 54%, 62%, 1)" },
                        confirmDisabled: generateProgress !== null,
                        cancelDisabled: generateProgress !== null,
                    }}
                    onConfirm={handleSave}
                    onCancel={() => {
                        if (generateProgress === null) setShowDeleteConfirm(true);
                        else onClose();
                    }}
                >
                    <p>
                        Please name this mission
                        <br /> Original name: {latestMission ? `${latestMission}` : ""}
                    </p>
                    <input
                        type="text"
                        className={styles["mission-name-input"]}
                        placeholder="Leave empty to keep default name"
                        onChange={debounce((e) => setMissionName(e.target.value), 200)}
                    />
                    {renameError && <p className={utilStyles["error-text"]}>{renameError}</p>}
                    {generateProgress !== null && (
                        <ProgressBar
                            title="Saving"
                            progress={generateProgress}
                            options={{
                                color: "hsla(239, 54%, 62%, 1)",
                                height: "1rem",
                                kind: "embed",
                            }}
                        />
                    )}
                </DialogBox>
                {showDeleteConfirm && (
                    <DialogBox
                        textConfirm="Sure"
                        options={{ areYouSure: "Delete Record" }}
                        onCancel={() => setShowDeleteConfirm(false)}
                        onConfirm={handleDelete}
                    ></DialogBox>
                )}
            </>
        </ReactPortal>
    );
}

export default RecordSavePrompt;
