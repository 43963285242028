import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ActionTab from "./ActionTab";
import GeneralTab from "./GeneralTab";
import Icon from "./Icon";
import LogsTab from "./LogsTab";
import MissionTab from "./MissionTab";
import Tabs from "./Tabs";

import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { onClickAndTouchEnd, toKebabCase } from "../lib/utils";
import { useMission } from "./MissionProvider";
import { useActionState } from "./ActionStateProvider";

import { HAS_INSPECTION } from "../consts";
import {
    STATE_RECORD_STARTED,
    STATE_RECORD_STARTING,
    STATE_RECORD_STATE_UPDATED,
} from "../lib/state";
import styles from "./css/SidePanel.module.css";

function SidePanel() {
    const history = useHistory();
    const location = useLocation();
    const { recordState } = useMission();
    const [isShow, setIsShow] = useState(true);

    const { disableAction } = useActionState();

    const tab = location.pathname.split("/")[2] ?? "new-record";
    const isRecording = [
        STATE_RECORD_STARTED,
        STATE_RECORD_STARTING,
        STATE_RECORD_STATE_UPDATED,
    ].includes(recordState);

    return (
        <aside className={styles.container}>
            <div className={`${styles.body} ${!isShow && styles.hidden}`}>
                <Tabs
                    defaultTab={tab}
                    tab={tab}
                    isShow={isShow}
                    onSwitchTab={({ label }) => history.push(`/main/${toKebabCase(label)}`)}
                >
                    <Tabs.Item index={0} label="General">
                        <GeneralTab></GeneralTab>
                    </Tabs.Item>

                    <Tabs.Item index={1} label="Mission" hidden={isRecording || !disableAction}>
                        <MissionTab></MissionTab>
                    </Tabs.Item>

                    <Tabs.Item index={2} label="Logs" hidden={isRecording || !disableAction}>
                        <LogsTab></LogsTab>
                    </Tabs.Item>

                    <Tabs.Item index={3} label="Action" hidden={!HAS_INSPECTION || (disableAction && !isRecording)}>
                        <ActionTab />
                    </Tabs.Item>
                </Tabs>

                <p className={styles.copyright}>
                    &copy;{new Date().getFullYear()} <b>R2C2</b> Ltd.
                </p>
            </div>
            <button
                className={`${styles.toggler} ${!isShow && styles.fade}`}
                style={{ background: "url(/panel-toggler-bg.png) no-repeat" }}
                {...onClickAndTouchEnd(() => setIsShow(!isShow))}
            >
                <Icon
                    icon={isShow ? faCaretLeft : faCaretRight}
                    type="fa"
                    size="lg"
                    style={{ color: "hsla(250, 49%, 50%, 1)" }}
                />
            </button>
        </aside>
    );
}

export default SidePanel;
