// config mode
export const MODE_SIT = "SIT";
export const MODE_STAND = "STAND";
export const MODE_WALK = "WALK";
export const MODE_CRAWL = "CRAWL";
export const MODE_STAIR = "STAIR";
export const MODE_STANDARD = "STANDARD";

export const configMode = (m) => ["/config/mode", m];
export const sit = () => configMode(MODE_SIT);
export const stand = () => configMode(MODE_STAND);
export const walk = () => configMode(MODE_WALK);
export const crawl = () => configMode(MODE_CRAWL);
export const stair = () => configMode(MODE_STAIR);
export const standard = () => configMode(MODE_STANDARD);

// config height
export const configHeight = (height) => ["/config/height", height];

// config aviidance
export const configAvoidance = (m) => ["/config/avoidance", m];

//config arm torque
export const configArmTorque = (torque) => ["/arm/config/gripper_torque", torque];

//config arm torque
export const configGripDepth = (depth) => ["/arm/config/gripper_depth", depth];

// config light
export const configLight = (on) => ["/config/light", on];
export const configBrightness = (brightness) => ["/config/brightness", brightness];

// config max_speed
export const configMaxSpeed = (speed) => ["/config/max_speed", speed];

// config motor
export const MOTOR_POWER_OFF = "POWER_OFF";
export const MOTOR_POWER_ON = "POWER_ON";

export const configMotor = (action) => ["/config/motor", action];
export const motorOn = () => configMotor(MOTOR_POWER_ON);
export const motorOff = () => configMotor(MOTOR_POWER_OFF);
export const motorOnOff = (on) => (on ? motorOn() : motorOff());

// config estop
export const configEstop = (activate) => ["/config/estop", activate];

// claim motor
export const claimMotor = () => ["/claim/motor", true];
export const releaseMotor = () => ["/claim/motor", false];

// claim estop
export const claimEstop = () => ["/claim/estop", true];
export const releaseEstop = () => ["/claim/estop", false];

// perform actions
export const selfRight = () => ["/perform/selfright"];
export const turnOver = () => ["/perform/turnover"];
export const move = ({ x, y, z }) => [
    "/perform/movement",
    {
        x: -x,
        y: -y,
        z: -z,
    },
];
export const resetPose = () => ["/perform/reset"];

// arm control modes
export const ARM_STOW = "Stow";
export const ARM_UNSTOW = "Unstow";
export const ARM_CARRY = "Carry";
export const ARM_AUTO_GRIP = "ARM_AUTO_GRIP";
export const ARM_MANIP_VALVE_TURN = "ARM_MANIP_VALVE_TURN";
export const ARM_MANIP_MANUAL = "ARM_MANIP_MANUAL";

// arm grip defaults
export const ARM_DEFAULT_GRIP_DEPTH = 0.3;
export const ARM_DEFAULT_GRIP_TORQUE = 0.1;

export const stow = () => ["/arm/perform/stow"];
export const unstow = () => ["/arm/perform/unstow"];
export const carry = () => ["/arm/perform/carry"];
export const autoGrip = (position) => ["/arm/perform/grasp", position];
export const armStop = () => ["/arm/perform/stop"];
export const joyCtrl = (left_x, left_y, right_x, right_y, left_trigger, right_trigger) => [
    "/arm/perform/joy_ctrl",
    { left_x, left_y, right_x, right_y, left_trigger, right_trigger },
];
export const joyValveCtrl = (left_x, left_y, right_x, right_y, left_trigger, right_trigger) => [
    "/arm/perform/joy_valve_ctrl",
    { left_x, left_y, right_x, right_y, left_trigger, right_trigger },
];
export const gripperClose = () => ["/arm/perform/gripper_close"];
export const gripperOpen = () => ["/arm/perform/gripper_open"];
export const pickObject = () => ["/arm/perform/pickup_object_in_image"];
export const configArm = (max_speed) => ["/arm/config/arm_velocity", max_speed];
export const configWrist = (max_speed) => ["/arm/config/wrist_velocity", max_speed];
export const configVelocityDuration = (sec) => ["/arm/config/gripper_torque", sec];
export const configGripper = (torque) => ["/arm/config/gripper_torque", torque];
export const configFollowArm = (follow) => ["/arm/config/follow_arm", follow];
export const configDisableWalk = (disable) => ["/arm/config/follow_arm_disable_walk", disable];

// mission
export const missionRecordStart = () => ["/mission/record/start"];
export const missionRecordStop = () => ["/mission/record/stop"];
export const missionRunStart = (id, enableLog) => [
    "/mission/run/start",
    { mission_id: id, enableLog },
];
export const missionRunPause = () => ["/mission/run/pause"];
export const missionRunStop = () => ["/mission/run/stop"];
export const missionClear = () => ["/mission/clear"];
export const missionRename = (from, to) => ["/mission/rename", { from_name: from, to_name: to }];
export const missionDelete = (id) => ["/mission/delete", { mission_id: id }];
export const missionList = () => ["/mission/list"];
export const missionMap = (id) => ["/mission/map", { mission_id: id }];

// custom action

export const missionRecordCustomStart = () => ["/mission/record/custom_movement/start", {}];
export const missionRecordCustomEnd = () => ["/mission/record/custom_movement/end", {}];

export const missionAddCustomAction = (action, data) => [
    "/mission/record/add_custom_action",
    { action_name: action, action_data: data },
];
export const CUSTOM_ACTION_TEST_CMD = "TEST_CMD";
export const CUSTOM_ACTION_DETECT_ART_DMG = "DETECT_ART_DMG";

export const CUSTOM_ACTION_PTZ_INSPECTION = "PTZ_INSPECTION";

export const CUSTOM_ACTION_PREPARE_ART_REF = "PREPARE_ART_REF";
export const missionDetectArtDmg = (art_id) =>
    missionAddCustomAction(CUSTOM_ACTION_DETECT_ART_DMG, { art_id });
export const missionLockInspection = (ptzMovement) =>
    missionAddCustomAction(CUSTOM_ACTION_PTZ_INSPECTION, ptzMovement);
export const missionTestCmd = () => missionAddCustomAction(CUSTOM_ACTION_TEST_CMD, null);
export const missionPrepareArtRef = () => ["/art_inspection", { cmd: "/prepare_ref", data: "" }];
export const missionListArtRef = () => ["/art_inspection", { cmd: "/list_art", data: "" }];
export const missionDeleteArtRef = (art_id) => [
    "/art_inspection",
    { cmd: "/delete_ref", data: art_id },
];

export const handleKeyDown = (keyboardCode) => {
    switch (keyboardCode) {
        case "KeyW":
        case "ArrowUp":
            // Handle "forward"
            return { axis: "x", value: -1 };
        case "KeyS":
        case "ArrowDown":
            // Handle "back"
            return { axis: "x", value: 1 };
        case "KeyA":
        case "ArrowLeft":
            // Handle "turn left"
            return { axis: "y", value: -1 };
        case "KeyD":
        case "ArrowRight":
            // Handle "turn right"
            return { axis: "y", value: 1 };
        case "KeyQ":
            return { axis: "z", value: -1 };
        case "KeyE":
            return { axis: "z", value: 1 };
        default:
            return {};
    }
};

export const handleKeyUp = (keyboardCode) => {
    switch (keyboardCode) {
        case "KeyS":
        case "ArrowDown":
        case "KeyW":
        case "ArrowUp":
            // Handle "back"
            return { axis: "x", value: 0 };
        case "KeyA":
        case "ArrowLeft":
        case "KeyD":
        case "ArrowRight":
            // Handle "turn left"
            return { axis: "y", value: 0 };
        case "KeyE":
        case "KeyQ":
            return { axis: "z", value: 0 };
        default:
            return {};
    }
};

export const ptzCtrl = ({ pan, tilt, zoom }) => [
    "/ptz/perform/movement",
    JSON.stringify({ pan, tilt, zoom }),
];
export const inspectPTZImage = ({ pan, tilt, zoom }) => [
    "/ptz_inspection",
    {
        init_theta: pan,
        init_phi: tilt,
        init_zoom: zoom,
    },
];
export const getPTZFrame = () => ["/ptz/get_frame"];
