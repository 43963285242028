import { ReactComponent as IconArrowDown19 } from "../svg/IconArrowDown19.svg";
import { ReactComponent as IconArrowDownAZ } from "../svg/IconArrowDownAZ.svg";
import { ReactComponent as IconArrowUp19 } from "../svg/IconArrowUp19.svg";
import { ReactComponent as IconArrowUpAZ } from "../svg/IconArrowUpAZ.svg";
import { ReactComponent as IconSearch } from "../svg/IconSearch.svg";
import styles from "./css/SearchBar.module.css";
import Icon from "./Icon";

const SortButton = ({ onClick, text, icon, index, ...otherProps }) => {
    return (
        <div
            className={`${styles.sort}  ${styles.pointer}`}
            onClick={onClick}
            {...otherProps}
            key={index}
        >
            {`${text} `}
            <Icon icon={icon} type={"svg"} width={"24px"} height={"24px"} />
        </div>
    );
};

function SearchBar({ text, onChange, isAscending, toggleAscending, setSelectedSort }) {
    const btnList = [
        {
            onClick: () => {
                toggleAscending("name");
                setSelectedSort("name");
            },
            text: "Name",
            icon: isAscending["name"] ? IconArrowDownAZ : IconArrowUpAZ,
        },
        {
            onClick: () => {
                toggleAscending("time");
                setSelectedSort("time");
            },
            text: "Time",
            icon: isAscending["time"] ? IconArrowDown19 : IconArrowUp19,
        },
    ];
    return (
        <div className={styles.container} style={{ position: "relative" }}>
            <div className={styles.searchBar}>
                <Icon
                    className={styles.icon}
                    icon={IconSearch}
                    type={"svg"}
                    width={"20px"}
                    height={"20px"}
                />
                <input
                    className={styles["search-input"]}
                    onChange={(e) => onChange(e.target.value)}
                    value={text}
                    type={"text"}
                    placeholder={"Search by Name / Time"}
                />
            </div>
            <div className={styles["sort-wrapper"]}>
                {btnList.map((props, index) => (
                    <SortButton {...props} index={index} />
                ))}
            </div>
        </div>
    );
}

export default SearchBar;
