import { useState } from "react";
import { debugLogs } from "../testdata";
import styles from "./css/DebugConsole.module.css";
import FloatingWindow from "./FloatingWindow";

function ConsoleLogBox({ log }) {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className={styles.container} style={{ maxHeight: isExpanded ? "400px" : "120px" }}>
            <div className={styles.header}>
                <h1 className={styles.title}>{log.title}</h1>

                <button
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}
                >
                    Expand
                </button>
            </div>

            <p className={styles.content}>{log.output}</p>
        </div>
    );
}

function DebugConsole() {
    const logs = debugLogs || [];

    return (
        <FloatingWindow title="Debug Console">
            <div className={styles.logContainer}>
                {logs.length > 0 ? (
                    logs.map((log, index) => (
                        <>
                            <ConsoleLogBox key={index} log={log}></ConsoleLogBox>
                            {index + 1 !== logs.length && (
                                <hr style={{ border: "1px solid #616180" }} />
                            )}
                        </>
                    ))
                ) : (
                    <></>
                )}
            </div>
        </FloatingWindow>
    );
}

export default DebugConsole;
