import React, { useContext, useEffect, useState } from "react";
import { subscribe, unsubscribe } from "../lib/socket";
import { useSocket } from "./ControlSocketProvider";

export const GpsContext = React.createContext({
    gpsLatLong: null,
    gpsStatus: null,
});
GpsContext.displayName = "GPS";
export const GpsConsumer = GpsContext.Consumer;
export const useGps = () => useContext(GpsContext);

export const TOPIC_GPS = "/navigation/gps";
export const TOPIC_GPS_STATUS = "/navigation/gps/status";
export const STATE_GPS_LOST = "LOST";
export const STATE_GPS_SETUPERROR = "SETUPERROR";
export const STATE_GPS_ACQUIRED = "ACQUIRED";

export const isLost = (status) => status !== STATE_GPS_ACQUIRED;

const USE_DUMMY = false;

// used to display dummy value in development env only. Do not show in production
const R2C2_HQ_COORDS = {
    lat: 22.33843148092259,
    lng: 114.19854600858655,
};

function GpsProvider({ children }) {
    const { controlSocket: socket } = useSocket();
    const [gpsLatLong, setGpsLatLong] = useState(null);
    const [gpsStatus, setGpsStatus] = useState(null);

    useEffect(() => {
        if (USE_DUMMY) {
            console.log("set gps provider values to dummy values.");
            setGpsStatus(STATE_GPS_LOST);
            setGpsLatLong(R2C2_HQ_COORDS);
        }
    }, []);

    useEffect(() => {
        if (USE_DUMMY) {
            return;
        } else {
            const gpsStatusCallback = (data) => {
                const { status } = data;
                setGpsStatus(status);
            };

            const gpsLatLongCallback = (data) => {
                const { latitude, longitude } = data;
                setGpsLatLong({ lat: Number(latitude), lng: Number(longitude) });
            };
            subscribe(socket, TOPIC_GPS, gpsLatLongCallback);
            subscribe(socket, TOPIC_GPS_STATUS, gpsStatusCallback);
            return () => {
                socket && unsubscribe(socket, TOPIC_GPS_STATUS, gpsStatusCallback);
                socket && unsubscribe(socket, TOPIC_GPS, gpsLatLongCallback);
            };
        }
    }, [socket]);

    return (
        <GpsContext.Provider
            value={{
                gpsLatLong,
                gpsStatus,
            }}
        >
            {children}
        </GpsContext.Provider>
    );
}
export default GpsProvider;
