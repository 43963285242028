import React, { useContext } from "react";
import { DEFAULT_CONTROL_PORT, DEFAULT_HOST } from "../consts";
import { MODE_CRAWL, MODE_SIT, MODE_STAIR, MODE_STAND, MODE_WALK } from "../lib/protocols/control";
import {
    STATE_CLAIM_CLAIMED,
    STATE_CLAIM_NOT_YOURS,
    STATE_MOTOR_POWERED_OFF,
    STATE_MOTOR_POWERED_ON,
    STATE_MOTOR_POWERING_OFF,
    STATE_MOTOR_POWERING_ON,
    STATE_MOTOR_UNKNOWN,
    STATE_RECORD_STARTING,
    STATE_RECORD_STARTED,
    STATE_RECORD_STOPPING,
    STATE_RECORD_STOPPED,
    STATE_RECORD_FAILED,
    STATE_ROBOT_CONNECTED,
    STATE_ROBOT_DISCONNECTED,
} from "../lib/state";
import { address } from "../lib/utils";
import { useArmControl } from "./ArmControlProvider";
import { useArmState } from "./ArmStateProvider";
import { ControlSocketContext } from "./ControlSocketProvider";
import styles from "./css/GeneralTab.module.css";
import Dropdown from "./Dropdown";
import { useMission } from "./MissionProvider";
import { useRobotErrors } from "./RobotErrorProvider";
import { RobotStateContext } from "./RobotStateProvider";
import Section from "./Section";
import Switch from "./Switch";

function DummyForDev() {
    const {
        robotMode,
        motorState,
        motorClaimState,
        estopState,
        setRobotState,
        eStopClaimState,
        robotConnectionState,
        robotControllable,
    } = useContext(RobotStateContext);
    const { socketAddress, setSocketAddress } = useContext(ControlSocketContext);
    const { toggleArmControl } = useArmControl();

    const { hasArm, isGripperOpen, armEndEffectorState, setArmState } = useArmState();
    const { addRobotError } = useRobotErrors();

    const { recordState, setRecordState } = useMission();
    // console.log("recordState:", recordState);

    return (
        <Section className={styles["advanced-control"]} retracted={false}>
            <Section.Header>Dev controls</Section.Header>
            <Section.Content style={{ margin: "5px auto" }}>
                <button
                    onClick={() => {
                        setRobotState("robotConnectionState", STATE_ROBOT_CONNECTED);
                        setRobotState("motorClaimState", STATE_CLAIM_CLAIMED);
                        setRobotState("eStopClaimState", STATE_CLAIM_CLAIMED);
                    }}
                >
                    Connect fake robot
                </button>
                <Switch
                    title="Disable control socket"
                    isOn={socketAddress === null}
                    onToggle={() => {
                        // setting address to null closes the socket.
                        setSocketAddress(
                            socketAddress === null
                                ? address({
                                      host: DEFAULT_HOST,
                                      port: DEFAULT_CONTROL_PORT,
                                  })
                                : null
                        );
                        setSocketAddress(socketAddress === null ? DEFAULT_HOST : null);
                        console.log("turned off control socket completely.");
                    }}
                ></Switch>
                <p>
                    <small>↑ Prevent control socket from connecting</small>
                </p>
                <Switch
                    title="Robot Connected"
                    isOn={robotConnectionState === STATE_ROBOT_CONNECTED}
                    onToggle={() => {
                        const val =
                            robotConnectionState === STATE_ROBOT_CONNECTED
                                ? STATE_ROBOT_DISCONNECTED
                                : STATE_ROBOT_CONNECTED;
                        console.log("toggling robot connection with value", val);
                        setRobotState("robotConnectionState", val);
                    }}
                ></Switch>
                <Dropdown
                    title="Motor state"
                    options={[
                        STATE_MOTOR_POWERED_ON,
                        STATE_MOTOR_POWERED_OFF,
                        STATE_MOTOR_POWERING_ON,
                        STATE_MOTOR_POWERING_OFF,
                        STATE_MOTOR_UNKNOWN,
                    ].map((s) => ({ value: s, name: s }))}
                    onChange={(val) => {
                        console.log("changing motor state to dummy value", val);
                        setRobotState("motorState", val);
                    }}
                    value={motorState}
                />
                <Dropdown
                    title="Pose"
                    options={[MODE_SIT, MODE_STAND, MODE_WALK, MODE_CRAWL, MODE_STAIR].map((s) => ({
                        value: s,
                        name: s,
                    }))}
                    onChange={(val) => {
                        console.log("changing pose to dummy value", val);
                        setRobotState("robotMode", val);
                    }}
                    value={robotMode}
                />
                <Switch
                    title="Motor claim"
                    isOn={motorClaimState === STATE_CLAIM_CLAIMED}
                    onToggle={() => {
                        const val =
                            motorClaimState === STATE_CLAIM_CLAIMED
                                ? STATE_CLAIM_NOT_YOURS
                                : STATE_CLAIM_CLAIMED;
                        console.log("toggling dummy claim with value", val);
                        setRobotState("motorClaimState", val);
                    }}
                ></Switch>
                <Switch
                    title="sw estopped"
                    isOn={estopState.software}
                    onToggle={() => {
                        const val = { ...estopState, software: !estopState.software };
                        console.log("setting estop with value", val);
                        setRobotState("estopState", val);
                    }}
                ></Switch>
                <Switch
                    title="hw estopped"
                    isOn={estopState.hardware}
                    onToggle={() => {
                        const val = { ...estopState, hardware: !estopState.hardware };
                        console.log("setting estop with value", val);
                        setRobotState("estopState", val);
                    }}
                ></Switch>
                <Switch
                    title="Estop claim"
                    isOn={eStopClaimState === STATE_CLAIM_CLAIMED}
                    onToggle={() => {
                        const val =
                            eStopClaimState === STATE_CLAIM_CLAIMED
                                ? STATE_CLAIM_NOT_YOURS
                                : STATE_CLAIM_CLAIMED;
                        console.log("toggling dummy claim with value", val);
                        setRobotState("eStopClaimState", val);
                    }}
                ></Switch>
                <Dropdown
                    title="mission record state"
                    options={[
                        null,
                        STATE_RECORD_STARTING,
                        STATE_RECORD_STARTED,
                        STATE_RECORD_STOPPING,
                        STATE_RECORD_STOPPED,
                        STATE_RECORD_FAILED,
                    ].map((s) => ({
                        value: s,
                        name: s,
                    }))}
                    onChange={(val) => {
                        console.log("changing pose to dummy value", val);
                        setRecordState(val);
                    }}
                    value={recordState}
                />
                <Switch
                    title="Has Arm"
                    isOn={hasArm}
                    onToggle={() => {
                        const val = !hasArm;
                        console.log("toggling dummy claim with value", val);
                        setArmState("hasArm", val);
                        toggleArmControl(val);
                    }}
                ></Switch>
                <Switch
                    title="Gripper opened"
                    isOn={isGripperOpen}
                    onToggle={() => {
                        const val = isGripperOpen ? 0 : 1;
                        console.log("toggling dummy gripper open with value", val);
                        setArmState(
                            "armEndEffectorState",
                            armEndEffectorState
                                ? {
                                      ...armEndEffectorState,
                                      open_fraction: val,
                                  }
                                : { open_fraction: val }
                        );
                    }}
                ></Switch>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        addRobotError({
                            level: "ERROR",
                            message: "Congratulations you just triggered a human error",
                            description: "here is the description",
                        });
                    }}
                >
                    Trigger dummy robot fault
                </button>
            </Section.Content>
        </Section>
    );
}

export default React.memo(DummyForDev);
