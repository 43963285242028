import { useContext, useMemo, useState } from "react";
import { COMPACT_MODE } from "../consts";

import useClickOutside from "../lib/hooks/useClickOutside";
import { claimMotor } from "../lib/protocols/control";
import {
    deviceStateToMsg,
    STATE_CLAIM_CLAIMED,
    STATE_ERROR,
    STATE_JETPACK_CONNECTED,
    STATE_JETPACK_CONNECTING,
    STATE_JETPACK_DISCONNECTED,
    STATE_MOTOR_POWERED_OFF,
    STATE_MOTOR_POWERED_ON,
    STATE_MOTOR_POWERING_OFF,
    STATE_MOTOR_POWERING_ON,
    STATE_MOTOR_UNKNOWN,
    STATE_ROBOT_CONNECTED,
    STATE_ROBOT_CONNECTING,
    STATE_ROBOT_DISCONNECTED,
} from "../lib/state";
import { ReactComponent as IconDualGear } from "../svg/IconDualGear.svg";
import { ReactComponent as IconError } from "../svg/IconError.svg";
import { ReactComponent as IconGear } from "../svg/IconGear.svg";
import { ReactComponent as IconNoSignal } from "../svg/IconNoSignal.svg";
import { ReactComponent as IconTemp } from "../svg/IconTemp.svg";
import { ReactComponent as IconWifiConnected } from "../svg/IconWifiConnected.svg";
import { ReactComponent as IconWifiConnecting } from "../svg/IconWIfiConnecting.svg";
import { ReactComponent as IconWifiDisconnected } from "../svg/IconWifiDisconnected.svg";
import { useSocket } from "./ControlSocketProvider";
import styles from "./css/Header.module.css";
import { RobotStateContext } from "./RobotStateProvider";

import { onClickAndTouchEnd } from "../lib/utils";
import Icon from "./Icon";
import Tooltip from "./Tooltip";
import WifiSettings from "./WifiSettings";

const stateSVGMap = {
    [STATE_JETPACK_CONNECTING]: {
        icon: IconWifiConnecting,
        color: "yellow",
    },
    [STATE_JETPACK_CONNECTED]: {
        icon: IconWifiConnected,
        color: "lime",
    },
    [STATE_JETPACK_DISCONNECTED]: {
        icon: IconWifiDisconnected,
        color: "#c4c4c4",
    },
    [STATE_MOTOR_POWERING_ON]: { icon: IconDualGear, color: "yellow" },
    [STATE_MOTOR_POWERING_OFF]: { icon: IconDualGear, color: "yellow" },
    [STATE_MOTOR_POWERED_ON]: { icon: IconDualGear, color: "lime" },
    [STATE_MOTOR_POWERED_OFF]: { icon: IconDualGear, color: "#c4c4c4" },
    [STATE_ERROR]: { icon: IconError, color: "#ff0000" },
    [STATE_MOTOR_UNKNOWN]: { icon: IconDualGear, color: "#c4c4c4" },
    [STATE_ROBOT_CONNECTED]: { icon: IconWifiConnected, color: "lime" },
    [STATE_ROBOT_CONNECTING]: { icon: IconNoSignal, color: "#666680" },
    [STATE_ROBOT_DISCONNECTED]: {
        icon: IconWifiDisconnected,
        color: "#666680",
    },
};

// see corresponding classes in Header.module.css
const stateMotorIconClassMap = {
    [STATE_MOTOR_POWERING_OFF]: "motor-transitioning",
    [STATE_MOTOR_POWERING_ON]: "motor-transitioning",
    [STATE_MOTOR_POWERED_ON]: "motor-on",
    [STATE_MOTOR_POWERED_OFF]: "motor-off",
};
const stateWIfiIconClassMap = {
    [STATE_ROBOT_CONNECTED]: "STATE_ROBOT_CONNECTED",
    [STATE_ROBOT_CONNECTING]: "STATE_ROBOT_CONNECTING",
    [STATE_ROBOT_DISCONNECTED]: "STATE_ROBOT_DISCONNECTED",
};

export const STATUS_TYPE_JETPACK = "ARC";
export const STATUS_TYPE_SPOT = "SPOT";

const iconProp = (state) => {
    return {
        icon: stateSVGMap[state].icon,
        type: "svg",
        alt: deviceStateToMsg(state),
        title: deviceStateToMsg(state),
        className: `${styles.icon} ${styles["status-icon"]} ${
            styles[stateMotorIconClassMap[state]] ?? ""
        } ${styles[stateWIfiIconClassMap] ?? ""}`,
    };
};

const TemperatureStatus = ({ degree = null, show = true, className }) => {
    const color =
        degree === null
            ? styles["motor-off"]
            : degree >= 40
            ? styles["temperature-overheat"]
            : styles["motor-on"];
    const props = {
        icon: IconTemp,
        type: "svg",
        title: "Temperature",
    };

    return (
        show && (
            <div className={`${styles.row} ${color} ${className ?? ""}`}>
                <span>{Math.round(degree) || 0}</span>
                <Icon {...props} className={`${styles.temperature} ${color}`} />
            </div>
        )
    );
};

function DeviceStatus({ device, name, showBattery = true, showTemp = true, statuses = [] }) {
    const { controlSocket: socket } = useSocket();
    const { controlState, batteryPercent, batteryTemperature, robotConnectionState, motorState } =
        useContext(RobotStateContext);
    const [showSetting, setShowSetting] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const clickOutsideRef = useClickOutside(() => setShowButton(false));
    const { sendControl } = useSocket();
    const deviceName = useMemo(
        () => (COMPACT_MODE ? `${device}` : `${device} - ${name}`),
        [device, name]
    );
    return (
        <>
            <div>
                <div className={styles["status-box"]}>
                    <div className={styles["status-bar"]} ref={clickOutsideRef}>
                        <p
                            className={styles["status-title"]}
                            onTouchEnd={(e) => {
                                setShowButton((prev) => !prev);
                                e.preventDefault();
                            }}
                            onClick={() => setShowButton((prev) => !prev)}
                        >
                            {deviceName}
                        </p>
                        <TemperatureStatus degree={batteryTemperature} show={showTemp} />
                        {showBattery && (
                            <>
                                <Tooltip content={`${batteryPercent}%`}>
                                    <span
                                        className={styles["battery-text"]}
                                    >{`${batteryPercent}%`}</span>
                                    <div className={styles.battery}>
                                        <div
                                            className={styles["battery-percentage"]}
                                            style={{
                                                height: `${batteryPercent}%`,
                                            }}
                                        ></div>
                                    </div>
                                </Tooltip>
                            </>
                        )}
                        {statuses.map((s, i) => (
                            <Icon key={i} {...iconProp(s)} />
                        ))}

                        {/* this is a hack of putting absolute setting inside status-bar but is relative to status-box */}
                        {showButton && (
                            <div className={styles.setting}>
                                {device === "SPOT" && controlState !== STATE_CLAIM_CLAIMED && (
                                    <button
                                        className={`${styles["button"]} ${styles["claim-button"]}`}
                                        {...onClickAndTouchEnd(() => sendControl(claimMotor()))}
                                    >
                                        Claim
                                    </button>
                                )}

                                {/* <button
                                    className={styles.button}
                                    onClick={() => {
                                        setShowSetting(true);
                                        setShowButton(false);
                                    }}
                                    onTouchEnd={(e) => {
                                        setShowSetting(true);
                                        setShowButton(false);
                                        e.preventDefault();
                                    }}
                                >
                                    <IconGear
                                        width="20px"
                                        height="20px"
                                        style={{ color: "hsla(239, 69%, 78%, 1)" }}
                                    ></IconGear>
                                    Connection Settings
                                </button> */}
                            </div>
                        )}
                    </div>
                </div>
                {showSetting && (
                    <WifiSettings device={device} onClose={() => setShowSetting(false)} />
                )}
            </div>
        </>
    );
}

export default DeviceStatus;
