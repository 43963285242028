import { useEffect } from "react";

import { ReactComponent as IconArrowUp } from "../svg/IconPTZArrowUp.svg";
import { ReactComponent as IconArrowLeft } from "../svg/IconPTZArrowLeft.svg";
import { ReactComponent as IconArrowRight } from "../svg/IconPTZArrowRight.svg";
import { ReactComponent as IconArrowDown } from "../svg/IconPTZArrowDown.svg";
import { ReactComponent as IconInspection } from "../svg/IconPTZInspection.svg";
import { ReactComponent as IconPreview } from "../svg/IconPreview.svg"
import { ReactComponent as IconZoomIn } from "../svg/IconZoomIn.svg"
import { ReactComponent as IconZoomOut } from "../svg/IconZoomOut.svg"

import { MODE_SIT } from "../lib/protocols/control";
import { constrainValue } from "../lib/utils";
import MenuButton from "./MenuButton";

import { usePTZControl } from "./PTZControlProvider";
import { useRobotState } from "./RobotStateProvider";
import { useSnack } from "./SnackProvider";

import PTZStyles from "./css/PTZ.module.css";

function PTZJoystick() {
    const { robotMode } = useRobotState();
    const { pan, tilt, zoom, changePTZMovement,
        isPTZInspectionLoading, inspectPTZImage,
        isGetPTZFrameLoading, getPTZFrame } = usePTZControl();
    const { addToast } = useSnack();

    const handleMovement = ({ p = 0, t = 0, z = 0.0 }) => {
        const newPan = constrainValue(pan + p, 0, 180);
        const newTilt = constrainValue(tilt + t, 0, 90);
        const newZoom = constrainValue((zoom + z).toFixed(1), 0.0, 1.0);

        changePTZMovement({ angle: "pan", value: newPan });
        changePTZMovement({ angle: "tilt", value: newTilt });
        changePTZMovement({ angle: "zoom", value: newZoom });
    }

    useEffect(() => {
        document.documentElement.style.setProperty("--PTZ-joystick-bottom", robotMode === MODE_SIT ? "10%" : "30%");
    }, [robotMode]);

    return (
        <>
            <div className={PTZStyles["joystick"]}>
                <div className={PTZStyles["square"]}>
                    {isGetPTZFrameLoading ?
                        <div className={PTZStyles["loader"]}></div> :
                        <MenuButton
                            icon={IconPreview}
                            iconType="svg"
                            action={() => {
                                addToast({
                                    type: "info",
                                    message: "Previewing",
                                    duration: 2000
                                });
                                getPTZFrame();
                            }} />}
                </div>
                <div className={PTZStyles["square"]}>
                    <MenuButton
                        icon={IconArrowUp}
                        iconType="svg"
                        action={() => {
                            handleMovement({ t: 1 });
                        }} />
                </div>
                <div className={PTZStyles["square"]}>
                    <MenuButton
                        icon={IconZoomIn}
                        iconType="svg"
                        action={() => {
                            handleMovement({ z: 0.1 });
                        }}/>
                </div>
                <div className={PTZStyles["square"]}>
                    <MenuButton
                        icon={IconArrowLeft}
                        iconType="svg"
                        action={() => {
                            handleMovement({ p: 1 });
                        }} />
                </div>
                <div className={PTZStyles["square"]}><span className={PTZStyles["banner"]}>For PTZ Camera</span></div>
                <div className={PTZStyles["square"]}>
                    <MenuButton
                        icon={IconArrowRight}
                        iconType="svg"
                        action={() => {
                            handleMovement({ p: -1 });
                        }} />
                </div>
                <div className={PTZStyles["square"]}>
                    {isPTZInspectionLoading ?
                        <div className={PTZStyles["loader"]}></div> :
                        <MenuButton
                            icon={IconInspection}
                            iconType="svg"
                            action={() => {
                                addToast({
                                    type: "info",
                                    message: "Inspecting",
                                    duration: 2000
                                });
                                inspectPTZImage();
                            }} />}
                </div>
                <div className={PTZStyles["square"]}>
                    <MenuButton
                        icon={IconArrowDown}
                        iconType="svg"
                        action={() => {
                            handleMovement({ t: -1 });
                        }} />
                </div>
                <div className={PTZStyles["square"]}>
                    <MenuButton
                        icon={IconZoomOut}
                        iconType="svg"
                        action={() => {
                            handleMovement({ z: -0.1 });
                        }} />
                </div>
            </div>
        </>);
}

export default PTZJoystick;