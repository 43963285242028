import React, { useEffect, useState } from "react";
import { subscribe, unsubscribe } from "../lib/socket";
import { dummyAirq } from "../testdata";
import { useSocket } from "./ControlSocketProvider";

const USE_DUMMY = false;

/* each airqSample in airqSamples:
 * name: the particle measured. e.g. CO2, TVOC
 * label: the time of sample the value represents. latest is the special label that shows the latest value; other labels may show an average value over longer period of time e.g. 1hr, 24hr
 * value: a number
 * unit: the unit
 * high: 0 for not high, 1 for high, -1 for not applicable
 * low: 0 for not low, 1 for low, -1 for not applicable
 */
/* airqStatuses: arbitrary values showing different statuses provided by the sampling device. each status
 * name: an arbitrary string, often human readable
 * value: an arbitrary string, can be any stringified value.
 */
/*
 * airqTime: time of the sample (millisecond since linux epoch)
 */
// note airqDummy is passed as one prop to children with the 3 keys mentioned above. but the 3 keys mentioned above as real data is passed down as 3 SEPARATE props
export const AirQContext = React.createContext({
    airqSamples: null,
    airqStatuses: null,
    airqTime: null,
});
AirQContext.displayName = "AirQContext";

export const AirQContextConsumer = AirQContext.Consumer;

export const hasIAQ = JSON.parse(process.env.REACT_APP_HAS_IAQ);

export const TOPIC_AIRQ = "/airq";

function AirQProvider({ children }) {
    const [airqSamples, setSamples] = useState([]);
    const [airqTime, setTime] = useState(null);
    const [airqStatuses, setStatuses] = useState([]);
    const { controlSocket: socket } = useSocket();

    useEffect(() => {
        const airqHandler = (data) => {
            const { samples, time, statuses } = data;
            setSamples(samples);
            setTime(time);
            setStatuses(statuses);
        };

        subscribe(socket, TOPIC_AIRQ, airqHandler);

        if (USE_DUMMY) {
            const { samples, time, statuses } = dummyAirq;
            setSamples(samples);
            setTime(time);
            setStatuses(statuses);
        }
        return () => {
            socket && unsubscribe(socket, TOPIC_AIRQ, airqHandler);
        };
    }, [socket]);

    return (
        <AirQContext.Provider value={{ hasIAQ, airqSamples, airqStatuses, airqTime }}>
            {children}
        </AirQContext.Provider>
    );
}

export default AirQProvider;
