import { useEffect, useState } from "react";
import { debounce } from "lodash";
import throttle from "lodash/throttle";
import moment from "moment";
import { DEFAULT_CONTROL_PORT, DEFAULT_HOST } from "../consts";

import JSZip from "jszip";

export const throttledLog = throttle(function () {
    console.log(...arguments);
}, 50);

// transform text from (CAPITAL, Title, normal) to kebab-case
export const toKebabCase = (string) => string.toLowerCase().split(" ").join("-");

// transform time to format - dd/mm/yyyy hh:mm:ss
export const toTimestamp = (datetime) =>
    moment(datetime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");

export const downloadTableAsCSV = (table_id, separator = ",", file_name = null) => {
    // Select rows from table_id
    var rows = document.querySelectorAll("table#" + table_id + " tr");
    // Construct csv
    var csv = [];
    for (var i = 0; i < rows.length; i++) {
        var row = [],
            cols = rows[i].querySelectorAll("td, th");
        for (var j = 0; j < cols.length; j++) {
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, "").replace(/(\s\s)/gm, " ");
            // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
            data = data.replace(/"/g, '""');
            // Push escaped string
            row.push('"' + data + '"');
        }
        csv.push(row.join(separator));
    }
    var csv_string = csv.join("\n");
    // Download it
    var filename =
        file_name ?? "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string));
    link.setAttribute("download", filename);
    link.click();
};

export const downloadIAQAsCSV = (IAQ = []) => {
    if (IAQ.length === 0) return;

    let rows = [],
        header = [];
    IAQ.forEach((data) => {
        if (header.length === 0) {
            Object.keys(data).forEach((key) => {
                if (key === "time") return header.push(key);
                Object.keys(data[key]).forEach((k) => header.push(k));
            });
            rows.push(header.join(","));
            return;
        }
        let row = [];
        Object.keys(data).forEach((key) => {
            if (key === "time") return row.push(data[key]);
            Object.keys(data[key]).forEach((k) =>
                row.push(`${data[key][k].value}${data[key][k].unit}`)
            );
        });
        rows.push(row.join(","));
    });

    // Download it
    var csv = rows.join("\n");
    var filename = "export_IAQ_" + new Date().toLocaleDateString() + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csv));
    link.setAttribute("download", filename);
    link.click();
};

export const downloadArtInspectionAsCSV = (artInspection = []) => {
    if (artInspection.length === 0) return;

    let rows = [],
        header = [];
    artInspection.forEach((data) => {
        if (header.length === 0) {
            Object.keys(data).forEach((key) => {
                if (key !== "data") return header.push(key);
                Object.keys(data[key]).forEach((k) => header.push(k));
            });
            rows.push(header.join(","));
            return;
        }
        let row = [];
        Object.keys(data).forEach((key) => {
            if (key !== "data") return row.push(data[key]);
            Object.keys(data[key]).forEach((k) => row.push(data[key][k]));
        });
        rows.push(row.join(","));
    });

    // Download it
    var csv = rows.join("\n");
    var filename = "export_Art_Inspection_" + new Date().toLocaleDateString() + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csv));
    link.setAttribute("download", filename);
    link.click();
};

export const normalizeDetectResultLink = (filename) => {
    const addr = DEFAULT_HOST;
    let link = `${window.location.protocol}//${addr}/art_inspection/detection/${filename}`;
    return link;
};

export const copyToClipboard = (text) => {
    if (window.isSecureContext) {
        navigator.clipboard?.writeText(text);
        return;
    }
    if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        } finally {
            document.body.removeChild(textarea);
        }
    }
};

export const strCompare =
    ({ reverse = false } = {}) =>
    (a, b) => {
        const first = reverse ? b : a;
        const second = reverse ? a : b;
        return first > second ? 1 : first === second ? 0 : -1;
    };

export const blobToArrayBuffer = async (blob, reader = null) => {
    return await new Promise((resolve, reject) => {
        if (reader === null) {
            reader = new FileReader();
        }
        const onLoadEnd = (e) => {
            reader.removeEventListener("loadend", onLoadEnd);
            if (e.loaded !== e.total) {
                reject(new Error("Failed to read the whole blob"));
            } else {
                resolve(reader.result);
            }
        };
        reader.addEventListener("loadend", onLoadEnd);
        reader.readAsArrayBuffer(blob);
    });
};
const padNum = (num, size) => {
    return ("" + num).padStart(size, "0");
};
const padTime = (num) => {
    return padNum(num, 2);
};
export const toHHMMSS = (date) => {
    return `${padTime(date.getHours())}:${padTime(date.getMinutes())}:${padTime(
        date.getSeconds()
    )}`;
};

export const onClickAndTouchEnd = (fn) => ({
    onClick: (e) => fn(e),
    onTouchEnd: (e) => {
        e.preventDefault();
        return fn(e);
    },
});
export const getContainedSize = (streamWidth, streamHeight) => {
    const ratio = 16 / 9;
    let width = streamHeight * ratio;
    let height = streamHeight;
    if (width > streamWidth) {
        width = streamWidth;
        height = streamWidth / ratio;
    }
    return { width: width, height: height };
};

export const isSecure = () => {
    return window.location.protocol === "https:";
};

export const wsAddress = ({ host = DEFAULT_HOST, port = DEFAULT_CONTROL_PORT, subpath = "" }) => {
    const protocol = isSecure() ? "wss:" : "ws:";
    return address({ protocol, host, port, subpath });
};

export const address = ({
    protocol = null,
    host = DEFAULT_HOST,
    port = DEFAULT_CONTROL_PORT,
    subpath = "",
}) => {
    return `${protocol ? `${protocol}//` : ""}${host}:${port}/${subpath}`;
};

/**
 * Concatenate 2 or more array buffers into 1
 * @param  {...ArrayBuffer} buffers ArrayBuffers to concat
 * @returns {ArrayBuffer} the concatenated ArrayBuffer object
 */
export const concatArrayBuffer = (...buffers) => {
    const totalLength = buffers.reduce((acc, b) => acc + b.byteLength, 0);
    const array = new Uint8Array(totalLength);
    let offset = 0;
    for (const b of buffers) {
        array.set(new Uint8Array(b), offset);
        offset += b.byteLength;
    }
    return array.buffer;
};

/**
 * Find whether a string is in another, regardless of case
 * @param  {String} haystack The string to search in
 * @param  {String} needle The string to search for
 * @returns {Boolean} whether there's a match
 */
export const includesCaseInsensitive = (haystack, needle) => {
    return haystack.toLowerCase().includes(needle.toLowerCase());
};

export const useDebouncedCallback = (cb, interval, deps = [], lodashDebounceOpts = {}) => {
    const [debouncedCb, setDebouncedCb] = useState(() => () => {});

    useEffect(() => {
        setDebouncedCb(() => debounce(cb, interval, lodashDebounceOpts));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return debouncedCb;
};
/**
 * Change coordinate system value from image to right-hand rule
 * @param {int} value
 * @returns {int}
 */
export const imageCoordinateToRightHandCoordinate = (value) => {
    return -value;
};

export const constrainValue = (value, min, max) => Math.max(Math.min(value, max), min);

export const downloadImage = (imageURL, imageName) => {
    // Download it
    var filename = imageName + ".jpeg";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute("href", imageURL);
    link.setAttribute("download", filename);
    link.click();
};

export const downloadArtInspectionsAsZip = async (
    artInspection = [],
    onError = (e) => console.log(e),
    filename = null
) => {
    if (artInspection.length === 0) return;

    let images = artInspection.map((data) => data.data.img);

    await Promise.all(images.map((img) => fetch(normalizeDetectResultLink(img))))
        .then((result) => result.map((r) => r.blob()))
        .then((result) => {
            let zip = new JSZip();
            result.forEach((r, index) => zip.file(images[index], r));

            let rows = [],
                header = [];
            artInspection.forEach((data) => {
                if (header.length === 0) {
                    Object.keys(data).forEach((key) => {
                        if (key !== "data") return header.push(key);
                        Object.keys(data[key]).forEach((k) => header.push(k));
                    });
                    rows.push(header.join(","));
                }
                let row = [];
                Object.keys(data).forEach((key) => {
                    if (key !== "data") return row.push(data[key]);
                    Object.keys(data[key]).forEach((k) => row.push(data[key][k]));
                });
                rows.push(row.join(","));
            });

            let csv = rows.join("\n");
            zip.file(
                filename ??
                    `export_Art_Inspection_${new Date()
                        .toLocaleDateString()
                        .replaceAll("/", "-")}.csv`,
                csv
            );

            return zip;
        })
        .then(async (zip) => downloadZip(await zip.generateAsync({ type: "blob" })))
        .catch(onError);
};

export const downloadZip = (zipFile) => {
    console.log(zipFile);

    let url = URL.createObjectURL(zipFile);
    let downloadA = document.createElement("a");
    downloadA.download = "export.zip";
    downloadA.href = url;
    downloadA.style.display = "none";

    document.body.appendChild(downloadA);
    downloadA.click();
    downloadA.remove();

    URL.revokeObjectURL(url);
};
