import { onClickAndTouchEnd } from "../lib/utils";
import styles from "./css/Menu.module.css";
import Icon from "./Icon";

function MenuButton({
    style,
    action,
    iconType,
    icon,
    description,
    btnClass,
    divClass,
    iconClass,
    ...otherProps
}) {
    return (
        <div className={`${styles["action-btn"]} ${divClass ?? ""}`.trim()}>
            <button
                type="button"
                {...onClickAndTouchEnd(() => action())}
                style={style}
                className={styles[btnClass]}
            >
                <Icon
                    icon={icon}
                    type={iconType}
                    width="24px"
                    height="24px"
                    className={iconClass}
                    {...otherProps}
                />
            </button>
            {description && <p>{description}</p>}
        </div>
    );
}

export default MenuButton;
