function TimeDisplay({ className, style, showTime = true }) {
    const newDate = new Date();
    const time = showTime ? Object.assign({}, { hour: "2-digit", minute: "2-digit" }) : {};
    const result = newDate
        .toLocaleDateString(
            "en-GB",
            Object.assign(time, { year: "numeric", month: "2-digit", day: "2-digit" })
        )
        .replace(",", "");

    return (
        <div className={`${className || ""}`} style={style}>
            {result}
            {/*{showDate &&*/}
            {/*    `${new Date().getDate()}/${new Date().getMonth()}/${new Date().getFullYear()}`}{" "}*/}
            {/*&nbsp;*/}
            {/*{showTime && `${new Date().getHours()}:${new Date().getMinutes()}`}*/}
        </div>
    );
}
export default TimeDisplay;
