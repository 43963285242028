import React, { useCallback, useContext, useEffect, useState } from "react";
import { matchPath, withRouter } from "react-router-dom";

import { useSocket } from "./ControlSocketProvider";
import { useSnack } from "./SnackProvider";

import {
    missionDelete,
    missionList,
    missionRename,
    missionRunPause,
    missionRunStop,
} from "../lib/protocols/control";
import { callSpotService } from "../lib/service";
import { subscribe } from "../lib/socket";
import {
    STATES_MISSION_END_STATES,
    STATES_RECORD_END_STATES,
    STATE_MISSION_LOCATION,
    STATE_MISSION_PAUSED,
    STATE_MISSION_STARTED,
    STATE_MISSION_STARTING,
    TOPIC_STATE_INSPECTION_RESULT,
    TOPIC_STATE_MISSION_RECORD,
    TOPIC_STATE_MISSION_RUN,
} from "../lib/state";

import { dummyMissions } from "../testdata";

export const MissionContext = React.createContext();
MissionContext.displayName = "Mission";
export const MissionConsumer = MissionContext.Consumer;
export const useMission = () => useContext(MissionContext);

const USE_DUMMY = false;

const useFindMissionIdFromRouteOnce = (location, setMissionId) => {
    useEffect(() => {
        const params = matchPath(location.pathname, "/main/mission/:missionId")?.params;
        if (!params) return null;
        const { missionId = null } = params;
        setMissionId(missionId);
    }, []);
};

function MissionProvider({ children, location, history }) {
    const { controlSocket: socket } = useSocket();
    const { addToast, setSnack } = useSnack();
    const [recordState, setRecordState] = useState(null);
    const [recordPayload, setRecordPayload] = useState({});
    const [missionState, setMissionState] = useState(null);
    const [missionPayload, setMissionPayload] = useState({});

    const [missionId, setMissionId] = useState(null);

    useFindMissionIdFromRouteOnce(location, setMissionId);

    useEffect(() => {
        if (!missionId) return;
        history.push({
            pathname: `/main/mission/${missionId}`,
        });
    }, [history, missionId]);

    const [missions, setMissions] = useState(null);
    const [recordEnded, setRecordEnded] = useState(true);
    const [missionEnded, setMissionEnded] = useState(true);
    const [inspection, setInspection] = useState(null);
    const [inspectionPayload, setInspectionPayload] = useState({});
    const [missionStarting, setMissionStarting] = useState(false);
    const [missionRunning, setMissionRunning] = useState(false);
    const [missionPaused, setMissionPaused] = useState(false);
    const [waypointID, setWaypointID] = useState(null);

    const [showGraph, setShowGraph] = useState(false);

    const listMission = (invalidateCurrent = false) => {
        // not loaded when missions is set to null
        invalidateCurrent && setMissions(null);
        if (USE_DUMMY) {
            setMissions(dummyMissions);
            return new Promise((resolve) => resolve(dummyMissions));
        }
        return callSpotService(...missionList())
            .then((res) => {
                setMissions(res.missions);
            })
            .catch((err) => {
                setMissions([]); // stop skeleton loading and set empty mission list
                addToast({
                    type: "error",
                    message: `Could not list mission: ${err.message}`,
                    duration: 7000,
                });
                // expects errJson.err to be a human readable message
                // will cause unhandled error if not commented out
                // throw new Error(`Could not list mission: ${err.message}`);
            });
    };

    const deleteMission = (id) =>
        callSpotService(...missionDelete(id)).catch((err) => {
            throw new Error(`Could not delete mission: ${err.message}`);
        });

    const renameMission = (currName, newName) =>
        callSpotService(...missionRename(currName, newName)).catch((err) => {
            throw new Error(`Could not rename mission: ${err.message}`);
        });

    const handleRename = (oldId, newId, setError = () => {}) => {
        const newName = newId?.trim();
        setError(null);
        if (oldId !== newName) {
            if (newName === "") {
                setError("Mission name cannot be empty");
                return;
            }
            renameMission(oldId, newName)
                .then(() => {
                    setSnack({ message: `Changed Name to ${newName}` });
                    setRecordState(null);
                })
                .catch((err) => {
                    setError(err.message);
                });
        } else {
            setError("Same with original name");
        }
    };

    const handleDelete = (id, setError = () => {}) => {
        setError(null);
        deleteMission(id)
            .then(() => {
                setSnack({ message: `Cancel saving record` });
            })
            .catch((err) => {
                setError(err.message);
            });
        setRecordState(null);
    };

    // Pause mission
    const pauseMissionRun = () =>
        callSpotService(...missionRunPause()).catch((err) =>
            addToast({
                type: "error",
                message: `Could not pause mission: ${err}`,
                duration: 5000,
            })
        );

    const stopMissionRun = () =>
        callSpotService(...missionRunStop()).catch((err) =>
            addToast({
                type: "error",
                message: `Could not stop mission: ${err}`,
                duration: 5000,
            })
        );

    // Subscribe to ROS
    useEffect(() => {
        subscribe(socket, TOPIC_STATE_MISSION_RECORD, (data) => {
            const { event, ...payload } = data;
            setRecordState(event);
            setRecordEnded(STATES_RECORD_END_STATES.includes(event));
            setRecordPayload(payload);
        });

        subscribe(socket, TOPIC_STATE_MISSION_RUN, (data) => {
            const { event, ...rawPayload } = data;
            const payload = {
                ...rawPayload,
                extra_info: rawPayload.extra_info ? JSON.parse(rawPayload.extra_info) : {},
            };
            setMissionId(payload?.mission_id ?? null);
            setMissionState(event);
            const missionEnded = STATES_MISSION_END_STATES.includes(event);
            setMissionEnded(missionEnded);
            setMissionPaused(!missionEnded && event === STATE_MISSION_PAUSED);
            setMissionPayload(payload);
            setMissionStarting(event === STATE_MISSION_STARTING);
            setMissionRunning(event === STATE_MISSION_STARTED || event === STATE_MISSION_LOCATION);
            setWaypointID(payload?.waypoint_id ?? null);
        });

        subscribe(socket, TOPIC_STATE_INSPECTION_RESULT, (data) => {
            const { action_name, ...payload } = data;
            setInspection(action_name);
            setInspectionPayload(payload);
        });
    }, [socket]);

    useEffect(() => {
        if (!missionState) return;
        setMissionStarting(missionState === STATE_MISSION_STARTING);
        setMissionRunning(missionState === STATE_MISSION_STARTED);
        setMissionPaused(missionState === STATE_MISSION_PAUSED);
    }, [missionState]);

    const clearMissionState = useCallback(() => {
        setMissionState(null);
    }, [setMissionState]);

    return (
        <MissionContext.Provider
            value={{
                recordState,
                setRecordState,
                recordPayload,
                missionState,
                setMissionState,
                missionPayload,
                missions,
                missionId,
                setMissionId,
                recordEnded,
                missionStarting,
                setMissionStarting,
                missionRunning,
                setMissionRunning,
                missionEnded,
                missionPaused,
                inspection,
                inspectionPayload,
                listMission,
                deleteMission,
                renameMission,
                handleRename,
                handleDelete,
                pauseMissionRun,
                stopMissionRun,
                clearMissionState,
                waypointID,
                showGraph,
                setShowGraph,
            }}
        >
            {children}
        </MissionContext.Provider>
    );
}

export default withRouter(MissionProvider);
