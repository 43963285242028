import { io } from "socket.io-client";

const subscriptionStore = [];

const registerHandler = (s, event, handler) => {
    if (!s) return;
    if (s.listeners(event).includes(handler)) {
        // console.log("handler already registered for event.", event, handler);
        return;
    }
    s.emit("subscribe", event);
    s.on(event, handler);
};

export const connect = (addr) => {
    const s = io(addr);
    s.on("connect", () => {
        for (const sub of subscriptionStore) {
            registerHandler(s, sub.event, sub.handler);
        }
    });
    return s;
};

export const send = (s, event, data = {}) => {
    // console.log("s:", s);
    if (!s) return;
    // console.log("emit:", event, ";", data);
    s.emit(event, data);
};

export const subscribe = (s, event, handler) => {
    // if a combination of this event and this handler not found in subscriptionstore, add them
    if (subscriptionStore.findIndex((sub) => sub.event === event && sub.handler === handler) < 0) {
        subscriptionStore.push({ event, handler });
    }
    if (!s) return;
    registerHandler(s, event, handler);
};

export const unsubscribe = (s, event, handler) => {
    if (handler === undefined) handler = () => {};
    s.emit("unsubscribe", event);
    s.off(event, handler);
    const i = subscriptionStore.findIndex((sub) => sub.event === event && sub.handler === handler);
    if (i > -1) {
        subscriptionStore.splice(i, 1);
    }
};
