import React, { useContext, useEffect, useState } from "react";
import { DEFAULT_ROBOT_STATE } from "../consts";
import { subscribe, unsubscribe } from "../lib/socket";
import {
    TOPIC_STATE_BATTERY_CHARGE,
    TOPIC_STATE_BATTERY_STATUS,
    TOPIC_STATE_BATTERY_TEMP,
    TOPIC_STATE_CLAIM_ESTOP,
    TOPIC_STATE_CLAIM_MOTOR,
    TOPIC_STATE_ESTOP,
    TOPIC_STATE_HEIGHT,
    TOPIC_STATE_MAX_SPEED,
    TOPIC_STATE_MODE,
    TOPIC_STATE_MOTOR,
    TOPIC_STATE_ROBOT_CONNECTION,
    TOPIC_STATE_PROXIMITIES,
} from "../lib/state";
import { useSocket } from "./ControlSocketProvider";

export const RobotStateContext = React.createContext();
RobotStateContext.displayName = "RobotState";
export const RobotStateConsumer = RobotStateContext.Consumer;
export const useRobotState = () => useContext(RobotStateContext);

const topicStateMap = {
    robotMode: TOPIC_STATE_MODE,
    robotHeight: TOPIC_STATE_HEIGHT,
    robotConnectionState: TOPIC_STATE_ROBOT_CONNECTION,
    motorState: TOPIC_STATE_MOTOR,
    estopState: TOPIC_STATE_ESTOP,
    maxSpeed: TOPIC_STATE_MAX_SPEED,
    batteryPercent: TOPIC_STATE_BATTERY_CHARGE,
    batteryStatus: TOPIC_STATE_BATTERY_STATUS,
    batteryTemperature: TOPIC_STATE_BATTERY_TEMP,
    motorClaimState: TOPIC_STATE_CLAIM_MOTOR,
    eStopClaimState: TOPIC_STATE_CLAIM_ESTOP,
    proximities: TOPIC_STATE_PROXIMITIES,
};

const USE_DUMMY = false;
const stateNormalizers = {
    proximities: (val) => val["proximities"],
};

const ROBOT_STATE_KEYS = Object.keys(DEFAULT_ROBOT_STATE);

function RobotStateProvider({ children }) {
    const { controlSocket: socket } = useSocket();
    const [robotStates, setRobotStates] = useState(DEFAULT_ROBOT_STATE);
    // derived states
    // TODO: subscribe to robotControllable and robotMovable from backend
    const [robotControllable, setRobotControllable] = useState(true);
    const [robotMovable, setRobotMovable] = useState(true);
    const [isEstopped, setIsEstopped] = useState(true);
    const setRobotState = (key, state) => {
        setRobotStates((prev) => ({
            ...prev,
            [key]: state,
        }));
    };

    useEffect(() => {
        const { motorState, motorClaimState, estopState, eStopClaimState, robotConnectionState } =
            robotStates;
        const _isEstopped = Object.values(estopState).some((state) => state);
        setRobotControllable(true);
        setRobotMovable(true);
        setIsEstopped(_isEstopped);
    }, [robotStates]);

    useEffect(() => {
        const handlers = {};
        ROBOT_STATE_KEYS.forEach((key) => {
            const handler = (data) => {
                if (typeof stateNormalizers[key] === "function") {
                    data = stateNormalizers[key](data);
                }
                setRobotState(key, data);
            };
            handlers[key] = handler;
            subscribe(socket, topicStateMap[key], handler);
        });

        return () => {
            if (!socket) return;
            ROBOT_STATE_KEYS.forEach((key) => {
                unsubscribe(socket, topicStateMap[key], handlers[key]);
            });
        };
    }, [socket]);

    return (
        <RobotStateContext.Provider
            value={{
                ...robotStates,
                setRobotState,
                isEstopped,
                robotControllable,
                robotMovable,
            }}
        >
            {children}
        </RobotStateContext.Provider>
    );
}

export default RobotStateProvider;
