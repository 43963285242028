import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { subscribe, unsubscribe } from "../lib/socket";
import { notiDummy } from "../testdata";
import { useSocket } from "./ControlSocketProvider";
import { useSnack } from "./SnackProvider";

export const NotificationContext = React.createContext();
NotificationContext.displayName = "Notification";
export const NotificationConsumer = NotificationContext.Consumer;
export const useNoti = () => useContext(NotificationContext);

export const TOPIC_NOTIFICATIONS = "/notifications";

const USE_DUMMY = false;

function NotificationProvider({ children }) {
    const { controlSocket: socket } = useSocket();

    /**
     * Expected format of notification
     * {
     *      id: string, // "type/time" internally generated, not from socket
     *      type: string,
     *      title: string,
     *      body: string,
     *      time: string,
     *      isRead: Boolean, // internally generated, not from socket
     * }
     */
    const [notis, setNotis] = useState([]);
    const [hasUnreadNoti, setHasUnreadNoti] = useState(false);
    const [hasNewNoti, setHasNewNoti] = useState(false);
    const { setSnack } = useSnack();
    const newNotiTimeout = useRef(null);

    const read = (id) => {
        const temp = notis.slice();
        const index = temp.findIndex((noti) => noti.id === id);
        if (index === -1) return;

        temp[index].isRead = true;
        setNotis(temp);
    };

    const readAll = () => {
        const temp = notis.slice();
        temp.forEach((noti) => (noti.isRead = true));
        setNotis(temp);
    };

    const handleNoti = useCallback((noti) => {
        setNotis((prev) => [noti, ...prev]);
        setHasNewNoti(true);
        newNotiTimeout.current && clearTimeout(newNotiTimeout.current);
        newNotiTimeout.current = setTimeout(() => setHasNewNoti(false), 5000);
    }, []);

    useEffect(() => {
        if (USE_DUMMY) setNotis(notiDummy);

        subscribe(socket, TOPIC_NOTIFICATIONS, handleNoti);

        setSnack({
            // on login
            type: "info",
            message:
                "It might take 2 minutes to connect with the JetPack. Please DO NOT close the window before any connection is created.",
        });

        return () => {
            socket && unsubscribe(socket, TOPIC_NOTIFICATIONS, handleNoti);
        };
    }, [socket, handleNoti]);

    useEffect(() => {
        setHasUnreadNoti(notis.some((noti) => !noti.isRead));
    }, [notis]);

    return (
        <NotificationContext.Provider
            value={{ notis, read, readAll, hasUnreadNoti, hasNewNoti, setSnack }}
        >
            {children}
        </NotificationContext.Provider>
    );
}

export default NotificationProvider;
