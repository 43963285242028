import { useContext } from "react";
import AirQItem from "./AirQItem";
import { AirQContext, hasIAQ } from "./AirQProvider";
import styles from "./css/AirQ.module.css";

function AirQBar({ airqToShow, isShow = true }) {
    const { airqSamples, airqTime, airqStatuses } = useContext(AirQContext);
    const categories = airqToShow || ["TVOC", "PM2.5", "PM10", "CO2"];

    const getFirstofKind = (name) => airqSamples.find((sample) => sample.name === name);
    const getLatest = (name) =>
        airqSamples.find((sample) => sample.name === name && sample.label == "latest");

    if (hasIAQ && isShow) {
        return (
            <div className={styles.container}>
                {categories.map((name) => {
                    const sample = getLatest(name) || { name, value: null, unit: null };
                    return <AirQItem sample={sample} key={sample.name}></AirQItem>;
                })}
            </div>
        );
    } else {
        return <></>;
    }
}

export default AirQBar;
