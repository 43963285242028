import { useCallback, useEffect, useRef } from "react";
import * as THREE from "three";
import useStorage from "../lib/hooks/useStorage";
import MyUtils from "../lib/three/MyUtils";
import { ReactComponent as IconSwitch } from "../svg/IconSwitch.svg";
import { useArmState } from "./ArmStateProvider";
import styles from "./css/ArmPoseView.module.css";
import MenuButton from "./MenuButton";

const INDEX_SH = 0;
const INDEX_EL = 1;
const INDEX_WR = 2;
const INDEX_TIP = 3;
const JOINT_NAMES = ["sh", "el", "wr", "tip"];
const ARM_JOINTS_COUNT = 4;

// prettier-ignore
const DEFAULT_ARM_POSE = [
    // x, y, z
    0, 0, 0, // sh
    -1, 0, 0, // el
    0, 0, 0, // wr
    0.3, 0, 0, // tip
]

const getJoint = (pose, index) => {
    const start = index * 3;
    const end = start + 3;
    return pose.slice(start, end);
};

const OFF_X = 0;
const OFF_Y = 1;
const OFF_Z = 2;

// get all x's, y's or z's from a flattened array of vertices
const getComponentAll = (vertices, componentOffset) => {
    const all = [];
    for (let i = 0; i < vertices.length; i += 3) {
        all.push(vertices[i + componentOffset]);
    }
    return all;
};

const flipPose = (pose, compOffset) =>
    pose.map((comp, offset) => (offset % 3 === compOffset ? 0 - comp : comp));

const v = (x, y, z) => new THREE.Vector3(x, y, z);

const isValidCoord = (coord) => typeof coord === "number" && isFinite(coord); // also covers NaN

const flattenArmPose = (objPose) => {
    if (!objPose) return null;
    const coordObjs = objPose.point;
    if (!coordObjs || coordObjs.length !== ARM_JOINTS_COUNT) {
        return null;
    }
    return coordObjs.reduce((acc, o) => {
        if (!isValidCoord(o.x) || !isValidCoord(o.y) || !isValidCoord(o.z))
            throw new Error("Invalid coord object. should have x, y, z as fields");
        return [...acc, o.x, o.y, o.z];
    }, []);
};

const VIEW_TOP = "VIEW_TOP";
const VIEW_SIDE = "VIEW_SIDE";

const CAM_POS_SIDE = new THREE.Vector3(0, 0, 10);
const CAM_POS_TOP = new THREE.Vector3(0, 10, 0);

const setSideView = (camera) => {
    camera.position.copy(CAM_POS_SIDE);
    camera.lookAt(0, 0, 0);
};

const setTopView = (camera) => {
    camera.position.copy(CAM_POS_TOP);
    camera.setRotationFromEuler(new THREE.Euler(0, 0, 0));
    camera.lookAt(0, 0, 0);
    camera.rotateZ(Math.PI / 2);
    console.log("camera position: ", camera.position);
    console.log("camera rotation: ", camera.rotation);
};

class ArmPoseRenderer {
    constructor(domRef) {
        this.container = typeof domRef === "string" ? document.querySelector(domRef) : domRef;
        if (!this.container)
            throw new Error("Failed to construct ArmPoseRenderer because there is no ");
        this.armPose = null;
        this.scene = null;
        this.camera = null;
        this.renderer = null;
        this.animateFrame = null;
        // arm in the scene is made up of lines and joints (points)
        this.lines = null;
        this.joints = null;
    }

    init() {
        if (this.scene) return;
        this.renderer = new THREE.WebGLRenderer({
            alpha: true,
        });
        this.scene = new THREE.Scene();

        const width = this.container.clientWidth;
        const height = this.container.clientHeight;
        // const aspect = width / height;
        this.renderer.setSize(width, height);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        // this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.1, 1000);

        this.camera = new THREE.PerspectiveCamera(27, width / height, 5, 3500);
        // setSideView(this.camera)
        // setTopView(this.camera);
        // this.camera.position.y = 0;
        // this.camera.position.x = 0;
        // this.camera.position.z = 10;
        // this.camera.zoom = 600;
        // this.camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 2000);
        // this.camera = new THREE.OrthographicCamera();
        this.loadPose(DEFAULT_ARM_POSE);
        this.container.innerHTML = "";
        this.container.appendChild(this.renderer.domElement);
    }

    setCameraViewType(viewType) {
        switch (viewType) {
            case VIEW_TOP:
                setTopView(this.camera);
                break;
            case VIEW_SIDE:
                setSideView(this.camera);
                break;
            default:
                break;
        }
    }

    getContainerDimensions() {
        if (!this.container) return { width: 0, height: 0 };
        return { width: this.container.clientWidth, height: this.container.clientHeight };
    }

    resize() {
        const { width, height } = this.getContainerDimensions();

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(width, height);
    }

    render() {
        this.animate();
    }

    stopRender() {
        this.animateFrame && cancelAnimationFrame(this.animateFrame);
    }

    animate() {
        this.animateFrame = requestAnimationFrame(() => this.animate());
        this.resize();
        // this.camera.updateProjectionMatrix();
        this.renderer.render(this.scene, this.camera);
    }

    loadPose(pose) {
        if (!this.scene) return;
        pose = flipPose(pose, OFF_X);
        this.scene.clear();
        // map joint connections to arm lines
        const lines = [
            [INDEX_SH, INDEX_EL], // shoulder is connected to elbow
            [INDEX_EL, INDEX_WR], // elbow is connected to wrist
            [INDEX_WR, INDEX_TIP], // wrist is connected to fingertip
        ].map(([joint1, joint2]) => {
            const geo = new MyUtils.LineGeometry();
            geo.setPositions([...getJoint(pose, joint1), ...getJoint(pose, joint2)]);
            const line = new MyUtils.Line2(
                geo,
                MyUtils.getLineMaterial(0xffffff, {
                    linewidth: 0.03,
                    dashed: false,
                    worldUnits: false,
                    alphaToCoverage: true,
                })
            );
            line.computeLineDistances();
            line.userData = { from: INDEX_SH, to: INDEX_EL };
            line.name = `${JOINT_NAMES[joint1]}_${JOINT_NAMES[joint2]}`;
            return line;
        });
        // console.log("loaded pose. lines: ", lines);
        lines.forEach((l) => this.scene.add(l));

        const jointMaterial = new THREE.MeshBasicMaterial({ color: 0x888888 });

        const shoulderGeo = new THREE.SphereGeometry(
            0.25,
            16,
            16,
            0,
            2 * Math.PI,
            0,
            Math.PI / 1.5
        );
        const shoulderMaterial = new THREE.MeshBasicMaterial({ color: 0x666666 });
        const shoulder = new THREE.Mesh(shoulderGeo, shoulderMaterial);
        const shoulderPos = v(...getJoint(pose, INDEX_SH));
        shoulderPos.y = shoulderPos.y - 0.15;
        shoulder.position.copy(shoulderPos);
        this.scene.add(shoulder);

        const elbowGeo = new THREE.SphereGeometry(0.15, 16, 16, 0, 2 * Math.PI, 0, Math.PI);
        const elbow = new THREE.Mesh(elbowGeo, jointMaterial);
        elbow.position.copy(v(...getJoint(pose, INDEX_EL)));
        this.scene.add(elbow);

        const wristGeo = new THREE.SphereGeometry(0.1, 16, 16, 0, 2 * Math.PI, 0, Math.PI);
        const wrist = new THREE.Mesh(wristGeo, jointMaterial);
        wrist.position.copy(v(...getJoint(pose, INDEX_WR)));
        this.scene.add(wrist);

        const bodyGeo = new THREE.BoxGeometry(3, 0.4, 0.85);
        const bodyMaterial = new THREE.MeshBasicMaterial({ color: 0xaaaa00 });
        const body = new THREE.Mesh(bodyGeo, bodyMaterial);
        body.position.copy(v(1.2, -0.4, 0));
        this.scene.add(body);
    }
}

const viewTypeToReadable = (viewType) => {
    switch (viewType) {
        case VIEW_TOP:
            return "Top";
        case VIEW_SIDE:
            return "Side";
        default:
            return "Unknown";
    }
};

const VIEW_TYPES = [VIEW_TOP, VIEW_SIDE];
const DEFAULT_VIEW_TYPE = VIEW_SIDE;

function ArmPoseView({ className }) {
    const containerRef = useRef(null);
    const rendererRef = useRef(null);
    const { armJointPos } = useArmState();
    // flattened pose as a 1-D array
    // const [viewType, setViewType] = useState(VIEW_SIDE);
    const [viewType, setViewType] = useStorage("robot:arm_view/type", VIEW_SIDE);

    const setView = (viewType) => {
        setViewType(viewType);
        rendererRef.current.setCameraViewType(viewType);
    };

    const cycleViewTypes = useCallback(() => {
        const nextTypeIndex = VIEW_TYPES.findIndex((t) => t === viewType);
        if (nextTypeIndex === -1) throw new Error("current view type not found in VIEW_TYPES");
        const nextType = VIEW_TYPES[(nextTypeIndex + 1) % VIEW_TYPES.length];
        setView(nextType);
    }, [viewType]);

    useEffect(() => {
        const renderer = new ArmPoseRenderer(containerRef.current);
        renderer.init();
        rendererRef.current = renderer;
        renderer.render();
        setView(DEFAULT_VIEW_TYPE);
        return () => {
            renderer.stopRender();
        };
    }, []);
    useEffect(() => {
        const renderer = rendererRef.current;
        // console.log("armJointPos:", armJointPos);
        const pose = flattenArmPose(armJointPos);
        // setPose(pose);
        if (pose === null) return;
        renderer.loadPose(pose);
    }, [armJointPos]);
    return (
        <div className={`${className} ${styles.container}`} onClick={(e) => e.stopPropagation()}>
            <h3>Arm - {viewTypeToReadable(viewType)}</h3>
            {/* canvas holder */}
            <div ref={containerRef} className={styles.view}></div>
            <MenuButton
                divClass={styles["switch-button"]}
                icon={IconSwitch}
                iconType="svg"
                action={() => {
                    cycleViewTypes();
                }}
            ></MenuButton>
        </div>
    );
}

export default ArmPoseView;
