import React, { useContext, useMemo, useState } from "react";
import { STATE_MOTOR_POWERED_ON, STATE_ROBOT_CONNECTED } from "../lib/state";
import { RobotStateContext } from "./RobotStateProvider";

export const BatteryContent = React.createContext();
BatteryContent.displayName = "Battery";
export const BatteryContentConsumer = BatteryContent.Consumer;

function BatteryProvider({ children }) {
    const [showBatteryLow, setShowBatteryLow] = useState(true);
    const { batteryPercent, robotConnectionState, motorState } = useContext(RobotStateContext);
    const showBatteryLowPopup = useMemo(() => {
        return (
            robotConnectionState === STATE_ROBOT_CONNECTED &&
            motorState === STATE_MOTOR_POWERED_ON &&
            showBatteryLow &&
            batteryPercent <= 20
        );
    }, [robotConnectionState, motorState, showBatteryLow, batteryPercent]);
    return (
        <BatteryContent.Provider value={{ showBatteryLowPopup, showBatteryLow, setShowBatteryLow }}>
            {children}
        </BatteryContent.Provider>
    );
}
export default BatteryProvider;
