import React from "react";
import styles from "./css/Gamepad.module.css";
import GamepadItem from "./GamepadItem";

export default class GamepadList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { gamepads: [] };
    }

    componentDidMount() {
        this.tick();
    }

    tick() {
        this.setState({ gamepads: this.pollGamepads() });
        window.requestAnimationFrame(() => this.tick());
    }

    render() {
        var gamepadArr = [].slice.call(this.state.gamepads);
        return (
            <div className={styles["gamepad-list"]}>
                <GamepadItem gamepad={gamepadArr[0]} key={0} index={0}></GamepadItem>
            </div>
        );
    }

    pollGamepads() {
        return navigator.getGamepads();
    }
}
