export const zh_HK = {};

export const en = {
    login: {
        ip: "IP Address",
        connect: "Connect",

        id: "Name",
        password: "Password",
        signIn: "Sign in",
        description:
            "Jetpack is a centerpiece of the robot system to bind all sensing, communication and control technologies together into one easy to use, easy to manage system.",

        connectingInstructionA: "It might take 2 minutes to connect with the pad.",
        connectingInstructionB: "Please DO NOT close the window before any connection is created.",
        connecting: "Connecting...",
        jetpackConnecting: "Connecting Jetpack",
        robotConnecting: "Connecting Robot",

        invalidCredential: "Wrong ID or password. Please try again.",
        emptyCredential: "Please enter your user ID and password.",
    },
};
