import uniq from "lodash/uniq";
import React from "react";
import { DEFAULT_VIDEO_PORT, STREAM_LOW_FPS } from "../consts";
import { defaultCam, getCamera, selectStreams } from "../lib/protocols/streams";
import { STATE_STREAM_NONE, STATE_STREAM_OKAY, STATE_STREAM_SLOW } from "../lib/state";
import { blobToArrayBuffer, wsAddress } from "../lib/utils";

export const CAM_VIEW_MAIN = "CAM_VIEW_MAIN";
export const CAM_VIEW_AUX = "CAM_VIEW_AUX";

// currently only main / auxiliary views are defined.
// in the future we might want to define other layouts such as 4 views, 3 views, etc
// might want to decoupule views from CameraProvider in that case
export const DEFAULT_VIEWS = {
    CAM_VIEW_MAIN: defaultCam.source,
    CAM_VIEW_AUX: null,
};

export const CameraContext = React.createContext({
    sourceUrls: {},
    camConnected: false,
    streamStatus: STATE_STREAM_NONE,
    views: DEFAULT_VIEWS,
});
CameraContext.displayName = "CameraContext";

export const CameraContextConsumer = CameraContext.Consumer;

const subscriptionsFromViews = (selected) => {
    return uniq(Object.values(selected).filter((s) => s !== null));
};
class CameraProvider extends React.Component {
    constructor(props) {
        super(props);

        this.ws = null;
        this.pingByte = Uint8Array.of(0x9).buffer;
        this.timeout = 250;
        this.pingIntervalId = null;
        this.fpscheckIntervalId = null;
        this.retryId = null;
        this.fps = 0;
        this.state = {
            // to be exported as CameraContext values
            sourceUrls: {},
            camConnected: false,
            streamStatus: STATE_STREAM_NONE,
            views: DEFAULT_VIEWS,
            selectSource: (view, source) => this.selectSource(view, source),
        };
    }

    componentDidMount() {
        this.connect();
    }

    componentWillUnmount() {
        this.setState({
            views: {
                CAM_VIEW_MAIN: null,
                CAM_VIEW_AUX: null,
            },
        });
        this.send(selectStreams([]));
        this.ws && this.ws.close();
        clearTimeout(this.retryId);
    }

    send(data) {
        if (!this.ws || this.ws.readyState !== this.ws.OPEN) return;

        this.ws.send(data);
    }

    ping(interval) {
        this.pingIntervalId = setInterval(() => {
            this.send(this.pingByte);
        }, interval);
    }

    checkFPS() {
        this.fpscheckIntervalId = setInterval(() => {
            this.setState({
                streamStatus:
                    this.fps === 0
                        ? STATE_STREAM_NONE
                        : this.fps <= STREAM_LOW_FPS
                        ? STATE_STREAM_SLOW
                        : STATE_STREAM_OKAY,
            });
            this.fps = 0;
        }, 1000);
    }

    handleData(name, data) {
        // data should be a Blob
        // create an URL object. string URLs are not used because copying large string is inefficient (see setState below. we use spread to mutate sourceUrls)
        const jpegBlob = new Blob([data], {
            // make sure the url is created from a blob of type image/jpeg
            type: "image/jpeg",
        });
        const url = URL.createObjectURL(jpegBlob);
        const oldUrl = this.state.sourceUrls[name];
        this.setState({
            sourceUrls: {
                ...this.state.sourceUrls,
                [name]: url,
            },
        });
        if (oldUrl) {
            setTimeout(() => URL.revokeObjectURL(oldUrl), 0);
        }
    }

    getUrlBySource(source) {
        return this.state.sourceUrls[source];
    }

    getUrlByView(view) {
        return this.getUrlBySource(this.state.views[view]);
    }

    close() {
        this.ws.onclose = null;
        this.ws.close();
        this.ws = null;
        this.retryId && clearTimeout(this.retryId);
    }

    onOpen() {
        console.log("connected to stream");

        this.setState({
            camConnected: true,
        });

        // subscribe to the default camera
        this.send(selectStreams(subscriptionsFromViews(this.state.views)));

        // ping every 5 seconds
        this.ping(5000);
        // check Stream Health
        this.checkFPS();
    }

    async onMessage(e) {
        const nameSizeSize = 1;
        // e.data is a Blob
        // we used to use Blob.prototype.arrayBuffer but iOS version <14 did not support this method
        const arrayBuffer = await blobToArrayBuffer(e.data);
        const streamNameSize = new Uint8Array(arrayBuffer.slice(0, nameSizeSize))[0];

        const streamData = await arrayBuffer.slice(nameSizeSize, 1 + streamNameSize);
        const name = new TextDecoder().decode(streamData);
        this.send(new Uint8Array([0x01]).buffer);

        this.fps++;
        this.handleData(name, e.data.slice(nameSizeSize + streamNameSize));
    }

    onClose() {
        console.log("disconnected from stream");
        this.setState({
            camConnected: false,
        });

        // Clear all interval
        if (this.pingIntervalId !== null) {
            clearInterval(this.pingIntervalId);
            this.pingIntervalId = null;
        }
        if (this.fpscheckIntervalId !== null) {
            clearInterval(this.fpscheckIntervalId);
            this.fpscheckIntervalId = null;
        }

        // this.retryId = setTimeout(() => {
        //     this.connect();
        // }, this.timeout);
        // this.timeout = Math.min(this.timeout * 2, 10 * 1000);
    }

    onError(err) {
        console.error("Socket encountered error: ", err.message, "Closing socket");

        this.setState({
            camConnected: false,
        });

        this.ws.close();
    }

    selectSource(view, source) {
        if (!getCamera({ source })) return;
        const newViews = {
            ...this.state.views,
            [view]: source,
        };
        const subscriptions = subscriptionsFromViews(newViews);
        if (this.ws) {
            this.send(selectStreams(subscriptions));
        }
        this.setState({
            views: newViews,
        });
    }

    swapCams(view1, view2) {
        this.setState({
            views: {
                ...this.state.views,
                [view1]: this.state.views[view2],
                [view2]: this.state.views[view1],
            },
        });
    }

    connect() {
        const url = wsAddress({ port: DEFAULT_VIDEO_PORT });
        this.ws = new WebSocket(url);

        this.ws.onopen = () => this.onOpen();
        this.ws.onmessage = (e) => this.onMessage(e);
        this.ws.onclose = () => this.onClose();
        // this.ws.onerror = (err) => this.onError(err);
    }

    render() {
        return (
            <CameraContext.Provider
                value={{
                    ...this.state,
                    swapCams: this.swapCams.bind(this),
                    getUrlBySource: this.getUrlBySource.bind(this),
                    getUrlByView: this.getUrlByView.bind(this),
                }}
            >
                {this.props.children}
            </CameraContext.Provider>
        );
    }
}

export default CameraProvider;
