import { useEffect, useState } from "react";
import { toTimestamp } from "../lib/utils";
import styles from "./css/LogHistory.module.css";

const STATUS_MESSAGE_MAP = {
    0: "Inspection succeeded",
};

const statusToMessage = (status) => {
    if (typeof STATUS_MESSAGE_MAP[status] != "undefined") {
        return STATUS_MESSAGE_MAP[status];
    } else {
        return "Unknown";
    }
};

function LogHistoryInspection({ isShow = true, events = [] }) {
    const eventToImg = (event) => {
        return event?.data?.image?.data && event?.data?.image?.format
            ? `data:image/${event.data.image.format};base64,${event.data.image.data}`
            : null;
    };
    const [selectedImg, setSelectedImg] = useState(null);
    const makeResultsLegible = (data) =>
        Object.keys(data).map((key) => (
            <>
                <div>{`${key}: ${data[key]}`}</div>
            </>
        ));
    const eventToResults = (event) => makeResultsLegible(JSON.parse(event?.data?.results));
    const eventToStatus = (event) => {
        return statusToMessage(event.data.status);
    };
    useEffect(() => {
        setSelectedImg(eventToImg(events[0]));
    }, [events]);
    if (!isShow) return <></>;
    return (
        <>
            <img
                src={selectedImg}
                alt={selectedImg ? "Photo taken during inspection" : "No photo available"}
                className={styles["inspection-img"]}
            />
            <div className={styles["detail"]}>
                <h2 className={styles["sub-title"]}>Details</h2>
                {/* don't change the table id as export function depends on it */}
                <table id="inspection-events-details" className={styles["detail-table"]}>
                    <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Status</th>
                            <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        {events.length > 0 ? (
                            events.map((event, index) => (
                                <tr key={index} onClick={() => setSelectedImg(eventToImg(event))}>
                                    <td>{toTimestamp(event.timestamp)}</td>
                                    <td>{eventToStatus(event)}</td>
                                    <td>{eventToResults(event)}</td>
                                </tr>
                            ))
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default LogHistoryInspection;
