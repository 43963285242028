import styles from "./css/AirQ.module.css";

function AirQItem({ sample }) {
    const { name, label, unit, value, high, low } = sample;
    return (
        <div key={name} className={styles.box}>
            <p className={styles.title}>{name}</p>
            <p className={styles.value}>
                <span>{value ? value.toFixed(1) : "-"}</span>
                {value && <span className={styles.unit}>/{unit}</span>}
            </p>
        </div>
    );
}

export default AirQItem;
