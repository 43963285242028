import { ReactComponent as IconPlus } from "../svg/IconPlus.svg";
import styles from "./css/Crosshair.module.css";

import Icon from "./Icon";

const dashedCircleStyle = (dash, gap, offset = 0, width, color) => ({
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='${color.replace(
        "#",
        "%23"
    )}' stroke-width='${width}' stroke-dasharray='${dash}%2c ${gap}' stroke-dashoffset='${offset}' stroke-linecap='square'/%3e%3c/svg%3e")`,
});

const dashPresets = {
    "dash-circle": dashedCircleStyle(31, 16, 11, 5, "#999AFA"),
    "dash-circle-big": dashedCircleStyle(60, 30, 20, 5, "#999AFA"),
};

function Crosshair({
    className,
    children,
    isFocus = false,
    type,
    isShow = true,
    position = null,
    iconSize = 15,
}) {
    const completedPos = () => {
        if (!position) return {};
        return {
            pointerEvents: "none",
            margin: "inherit",
            top: `${position.y - iconSize / 2}px`,
            left: `${position.x - iconSize / 2}px`,
            zIndex: "1",
        };
    };
    return (
        <>
            {isShow && (
                <div
                    className={`${styles.container} ${isFocus && styles[type]} ${className || ""}`}
                    style={{
                        ...completedPos(),
                        ...(dashPresets[type] || null),
                    }}
                >
                    <div style={{ position: "relative" }}>
                        <Icon
                            style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
                            icon={IconPlus}
                            type="svg"
                        />
                        {children}
                    </div>
                </div>
            )}
        </>
    );
}

export default Crosshair;
