import React, { useState } from "react";

import styles from "./css/OverlayButtonPanel.module.css";

import { ReactComponent as IconActions } from "../svg/IconActions.svg";

function Icon({ icon }) {
    return React.createElement(icon);
}

function OverlayButton({ icon, description, action, elementStyle }) {
    return (
        <button className={styles["item"]} onClick={action}>
            <div className={styles["item-content"]} style={elementStyle}>
                <Icon icon={icon} />
                <span>{description}</span>
            </div>
        </button>
    );
}

function OverlayButtonPanel({ items }) {
    const ITEM_BLOCKS = 8;

    const angleInDegrees = (count) => {
        return 360 / count;
    };

    const drawLine = (count) => {
        const CENTER_X = 0.5;
        const CENTER_Y = 0.5;
        const RADIUS = 0.5;

        let angleInRadians = (-angleInDegrees(count) * Math.PI) / 180;
        let coordinateX = CENTER_X + RADIUS * Math.cos(angleInRadians);
        let coordinateY = CENTER_Y + RADIUS * Math.sin(angleInRadians);

        return `M${CENTER_X},${CENTER_Y} l${CENTER_X},0 A${CENTER_X},${CENTER_Y} 0 0,0 ${coordinateX},${coordinateY} z`;
    };

    return (
        <div className={styles.overlay}>
            <svg height={0} width={0}>
                <defs>
                    <clipPath clipPathUnits="objectBoundingBox" id="sector">
                        <path
                            fill="none"
                            stroke="rgba(255, 255, 255, 0.3)"
                            strokeWidth={1}
                            className="sector"
                            d={drawLine(items.length)}
                        />
                    </clipPath>
                </defs>
            </svg>

            <div className={styles["action-header"]}>
                <div className={styles["in-screen-btn"]}>
                    <IconActions />
                    <span>Actions</span>
                </div>
            </div>

            <ul className={styles.container}>
                {items.map((item, index) => (
                    <li
                        key={index}
                        className={styles["item-piece"]}
                        style={{ transform: `rotate(-${angleInDegrees(items.length) * index}deg)` }}
                    >
                        <OverlayButton
                            elementStyle={{
                                transform: `rotate(${angleInDegrees(items.length) * index}deg)`,
                            }}
                            icon={item.icon}
                            description={item.description}
                            action={item.action}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default OverlayButtonPanel;
