import PTZJoystick from "./PTZJoystick";
import PTZPreview from "./PTZPreview";



const PTZOverlays = () => {
    return (
        <>
            <PTZPreview />
            <PTZJoystick />
        </>
    );
}

export default PTZOverlays;