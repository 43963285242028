import { useContext, useMemo, useState } from "react";
import { motorOff, motorOn } from "../lib/protocols/control";
import {
    STATE_CLAIM_CLAIMED,
    STATE_MOTOR_POWERED_OFF,
    STATE_MOTOR_POWERED_ON,
    STATE_MOTOR_POWERING_OFF,
    STATE_MOTOR_POWERING_ON,
    STATE_MOTOR_UNKNOWN,
} from "../lib/state";
import { useSocket } from "./ControlSocketProvider";
import styles from "./css/PowerSwitch.module.css";
import { RobotStateContext } from "./RobotStateProvider";

import ErrorPopup from "./ErrorPopup";

function PowerSwitch({ style, className }) {
    const { sendControl } = useSocket();
    const { motorState, eStopClaimState, isEstopped } = useContext(RobotStateContext);
    const [error, setError] = useState(false);

    const powering = useMemo(
        () => [STATE_MOTOR_POWERING_ON, STATE_MOTOR_POWERING_OFF].includes(motorState),
        [motorState]
    );
    const disabled = useMemo(
        () => powering || motorState === STATE_MOTOR_UNKNOWN || isEstopped,
        [motorState, isEstopped]
    );
    const toggledOn = motorState === STATE_MOTOR_POWERED_ON;
    const togglingOn = motorState === STATE_MOTOR_POWERING_ON;

    return (
        <>
            <div className={`${className || ""} ${styles["switch-wrapper"]}`}>
                <p className={styles.label}>Power</p>
                <button
                    type="button"
                    className={`${styles.switch} ${toggledOn ? styles.on : ""} ${
                        togglingOn ? styles.blinking : ""
                    }`}
                    disabled={disabled}
                    onClick={() => {
                        if (disabled) return;
                        // cannot power on the robot if estop is not claimed
                        if (eStopClaimState !== STATE_CLAIM_CLAIMED) {
                            setError(true);
                            return;
                        }
                        if (motorState === STATE_MOTOR_POWERED_ON) {
                            sendControl(motorOff());
                        } else if (motorState === STATE_MOTOR_POWERED_OFF) {
                            sendControl(motorOn());
                        }
                    }}
                >
                    <div className={styles.thumb}></div>
                </button>
            </div>
            {error && (
                <ErrorPopup
                    type="error"
                    title="Cannot power on robot"
                    content="For safety measures, it is mandatory to have the emergency stop ready all the time. To control the robot, make sure you have the permission to use the emergency stop."
                    onClose={() => setError(false)}
                ></ErrorPopup>
            )}
        </>
    );
}

export default PowerSwitch;
