import { Route, Switch, useRouteMatch } from "react-router-dom";

import AIMarket from "./AIMarket";
import DebugConsole from "./DebugConsole";
import FindMyRobot from "./FindMyRobot";
import Header from "./Header";
import LogHistory from "./LogHistory";
import MainScreen from "./MainScreen";
import SidePanel from "./SidePanel";
import SnackBar, { SnackGroup } from "./SnackBar";
import { useSnack } from "./SnackProvider";

import BatteryLowDialogBox from "./BatteryLowDialogBox";
import styles from "./css/MainPanel.module.css";

function MainPanel() {
    const { path } = useRouteMatch();
    const { snack, setSnack, toasts, removeToast } = useSnack();
    return (
        <div className={styles.container}>
            <Header></Header>
            <BatteryLowDialogBox />
            <div className={styles.content}>
                <SnackGroup position="bottom-left">
                    {toasts.map((toast, i) => (
                        <SnackBar
                            size="small"
                            onClose={() => {
                                removeToast(toast.id);
                            }}
                            {...toast}
                            key={toast.id}
                        >
                            {toast.message}
                        </SnackBar>
                    ))}
                </SnackGroup>
                <SidePanel></SidePanel>

                <div className={styles["content-wrapper"]}>
                    {snack && (
                        <SnackBar
                            className={styles.snackBar}
                            onClose={() => setSnack(null)}
                            {...snack}
                        >
                            {snack.message}
                        </SnackBar>
                    )}
                    <Switch>
                        <Route path={`${path}/find-my-robot`}>
                            <FindMyRobot></FindMyRobot>
                        </Route>
                        <Route exact path={`${path}/logs/`}>
                            <LogHistory></LogHistory>
                        </Route>
                        <Route path={`${path}/logs/:logId`}>
                            <LogHistory></LogHistory>
                        </Route>
                        <Route path={`${path}/mission/:missionId`}>
                            <MainScreen></MainScreen>
                        </Route>
                        <Route path={path}>
                            <MainScreen></MainScreen>
                        </Route>
                    </Switch>
                </div>

                {/* fixed floating elements */}
                <Switch>
                    <Route path={`${path}/debug-console`}>
                        <DebugConsole></DebugConsole>
                    </Route>
                    <Route path={`${path}/ai-market`}>
                        <AIMarket></AIMarket>
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

export default MainPanel;
