import React, { useContext, useState } from "react";

export const SnackContext = React.createContext();
SnackContext.displayName = "Snack";
export const SnackConsumer = SnackContext.Consumer;
export const useSnack = () => useContext(SnackContext);

function SnackProvider({ children }) {
    const [snack, setSnack] = useState(null);
    const [toasts, setToasts] = useState([]);

    /**
     * Enqueue a toast element for show
     *
     * @param {Object} t - toast
     * @param {string!} t.type - info, success, warning, error
     * @param {(string|JSX.Element)} t.children
     * @param {number} [t.duration=7000]
     * @param {function} [t.action]
     * @param {string} [t.actionDesc]
     * @param {string} [t.className]
     * @param {function} [t.onClose]
     */
    const enqueueToast = (t) => {
        t.duration = t.duration ?? 3000;
        t.id = t.type + Date.now().toString();
        setToasts((oldToasts) => [...oldToasts.slice(-2), t]);
    };

    /**
     * Delete a toast element
     *
     * @param {string} id
     */
    const dequeueToast = (id) => {
        setToasts((oldToasts) => oldToasts.filter((toast) => toast.id !== id));
    };

    return (
        <SnackContext.Provider
            value={{ snack, setSnack, toasts, addToast: enqueueToast, removeToast: dequeueToast }}
        >
            {children}
        </SnackContext.Provider>
    );
}

export default SnackProvider;
