import { useState } from "react"

import { ReactComponent as IconClose } from "../svg/IconClose.svg";
import { ReactComponent as IconDownload } from "../svg/IconDownload.svg";
import { ReactComponent as IconZoomIn } from "../svg/IconZoomIn.svg";
import { ReactComponent as IconZoomOut } from "../svg/IconZoomOut.svg";

import { usePTZControl } from "./PTZControlProvider";

import PTZStyles from "./css/PTZ.module.css";
import { downloadImage } from "../lib/utils";
import { useEffect } from "react";

const PTZPreview = () => {
    const { showPTZPreview, setShowPTZPreview, isGetPTZFrameLoading, isPTZInspectionLoading, PTZPreviewImage } = usePTZControl();

    const [isZoomInPreview, setIsZoomInPreview] = useState(false);

    const closePreview = (e) => {
        setShowPTZPreview(false);
        e.preventDefault();
    }

    const downloadPreview = (e) => {
        downloadImage(PTZPreviewImage, `${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}`);
        e.preventDefault();
    }

    const zoomPreview = (e) => {
        setIsZoomInPreview(!isZoomInPreview);
        e.preventDefault();
    }

    useEffect(() => {
        document.documentElement.style.setProperty("--PTZ-preview-max-width", isZoomInPreview ? "100%" : "45%");
    }, [isZoomInPreview])

    return (
        <>
            {showPTZPreview && <div className={PTZStyles["pop-up"]}>
                <div className={PTZStyles["preview-button-bar"]}>
                    <span><IconClose className={PTZStyles["svg-icon"]} onClick={(e) => closePreview(e)} /></span>
                    <span><IconDownload className={PTZStyles["svg-icon"]} onClick={(e) => downloadPreview(e)} /></span>
                    <span>{isZoomInPreview ? <IconZoomOut className={PTZStyles["svg-icon"]} onClick={(e) => zoomPreview(e)} /> : <IconZoomIn className={PTZStyles["svg-icon"]} onClick={(e) => zoomPreview(e)} />}</span>
                </div>
                <img className={PTZStyles["preview-image"]} src={(!isGetPTZFrameLoading && !isPTZInspectionLoading) ? PTZPreviewImage :
                    `/${process.env.REACT_APP_STREAM_DUMMY}`} alt="" />
            </div>}
        </>
    );
}

export default PTZPreview;