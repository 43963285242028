import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import Tooltip from "./Tooltip";

// I explicitly destructure the type alt title style prop for component description
function Icon({ icon, type, alt = "icon", title, style, className, tooltip, ...otherProps }) {
    const iconComp = useMemo(() => {
        switch (type) {
            case "fa":
                return (
                    <FontAwesomeIcon icon={icon} {...{ alt, style, className, ...otherProps }} />
                );
            case "svg":
                return React.createElement(icon, { alt, style, className, ...otherProps });
            case "img":
            default:
                return <img src={icon} {...{ alt, style, className, ...otherProps }} />;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, icon, className, style, otherProps, alt]);

    return (
        <Tooltip content={title || tooltip?.content} {...tooltip}>
            {iconComp}
        </Tooltip>
    );
}

export default Icon;
