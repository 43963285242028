import React from "react";

import styles from "./css/ProgressBar.module.css";

function ProgressBar({ title = "Progress", progress, options = {} }) {
    const {
        color = "hsla(137, 69%, 47%, 1)",
        bgColor: backgroundColor = "hsla(0, 0%, 17%, 1)",
        height = "10px",
        kind = "normal", // or "embed"
    } = options;

    return (
        <div className={styles.progress}>
            {title}
            <div className={styles["progress-bar"]} style={{ backgroundColor, height }}>
                <div style={{ width: `${progress}%`, color }}></div>
                {kind === "embed" && <span className={styles.text}>{`${progress}%`}</span>}
            </div>
            {kind === "normal" && <span className={styles.text}>{`${progress}%`}</span>}
        </div>
    );
}

export default ProgressBar;
