import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./AuthProvider";

import ErrorPopup from "./ErrorPopup";

function ProtectedRoute({ children, ...rest }) {
    const { user, isLoading } = useAuth();

    if (isLoading) return <ErrorPopup type="loading" title="Loading"></ErrorPopup>;

    return (
        <Route
            {...rest}
            render={(props) => {
                if (user.isAuthenticated) return children;
                else
                    return (
                        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                    );
            }}
        />
    );
}

export default ProtectedRoute;
