import { DEFAULT_CONTROL_PORT, DEFAULT_HOST } from "../consts";
import { address } from "./utils";

const SERVICE_URL = address({
    protocol: window.location.protocol,
    host: DEFAULT_HOST,
    port: DEFAULT_CONTROL_PORT,
    subpath: "service",
});

console.log('service url:', SERVICE_URL)

const parseJsonOrThrow = async (res) => {
    try {
        // console.log('res.body', JSON.parse(await res.text())) 
        return JSON.parse(await res.text());
    } catch {
        throw new Error("Unexpected response from server");
    }
};

const handleServiceErrors = async (promise) => {
    let res;
    try {
        // try to get result from promise
        res = await promise;
    } catch {
        // no response due to network errors
        throw new Error("No response from server");
    }
    if (!res.ok) {
        // server returned a response but error code is not ok
        // let it throw if it's not valid JSON
        const errJson = await parseJsonOrThrow(res);
        if (errJson.err) {
            // throw error with message from server if "err" is set
            // a service is expected to throw a human-readable message
            throw Error(errJson.err);
        } else {
            // otherwise tell user it's an unspecified error
            throw Error(`Unspecified error: ${errJson}`);
        }
    } else {
        return res;
    }
};

export const callService = (name, data) => {
    const promise = fetch(SERVICE_URL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            ...data,
            service: name,
        }),
    });
    return handleServiceErrors(promise).then((res) => parseJsonOrThrow(res));
};

export const callSpotService = (cmd, data) => {
    console.log("spot service called, ", data);
    const promise = fetch(SERVICE_URL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            service: "spot",
            cmd,
            data,
        }),
    });
    return handleServiceErrors(promise).then((res) => parseJsonOrThrow(res));
};

export const callLogService = (type, id) => {
    const promise = fetch(SERVICE_URL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            service: `/mission/log/${type}`,
            log_id: id,
        }),
    });
    return handleServiceErrors(promise).then((res) => parseJsonOrThrow(res));
};

export const callCustomActionService = (service, args) => {
    const promise = fetch(SERVICE_URL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            service,
            ...args,
        }),
    });
    return handleServiceErrors(promise).then((res) => parseJsonOrThrow(res));
};
