import throttle from "lodash/throttle";
import { useState } from "react";
import useClickOutside from "../lib/hooks/useClickOutside";
import styles from "./css/Tooltip.module.css";

// return pointer event handler that activates only for given pointer types
// `types` can be string or array of string
// for valid pointer types please reference https://www.w3.org/TR/pointerevents3/#dom-pointerevent-pointertype
const pointerTypes =
    (types, fn) =>
    (e, ...args) => {
        if (!types.includes(e.pointerType)) return;
        else fn(e, ...args);
    };

// mouse, laptop touchpad
const mouseOnly = (fn) => pointerTypes("mouse", fn);

// pen a.k.a stylus and touch
const touchOnly = (fn) => pointerTypes(["pen", "touch"], fn);

/**
 *
 * @param {Object} props
 * @param {number?} props.delay
 * @param {string?} props.direction
 * @param {string} props.content
 * @param {boolean?} props.isShow - Override on hover behaviour
 * @param {JSX.Element} props.children
 * @returns
 */
const Tooltip = ({
    defaultDelay = 400,
    duration = 1500,
    direction,
    content,
    wrapperStyle,
    tooltipStyle,
    isShow = null,
    children,
}) => {
    const [showID, setShowID] = useState(null);
    const [hideID, setHideID] = useState(null);
    const [active, setActive] = useState(false);

    const showTip = ({ delay = defaultDelay } = {}) => {
        clearTimeout(hideID);
        setShowID(
            setTimeout(() => {
                setActive(true);
            }, delay)
        );
    };

    const hideTip = () => {
        clearTimeout(showID);
        setActive(false);
    };

    const clickOutsideRef = useClickOutside(hideTip);

    const delayedHideTip = throttle(() => {
        setHideID(setTimeout(hideTip, duration));
    }, 1000);

    return (
        <div
            ref={clickOutsideRef}
            className={styles["tooltip-wrapper"]}
            style={wrapperStyle}
            // we want touch / stylus to immediately trigger tooltip on click and hide after a delay
            onPointerDown={touchOnly(() => showTip({ delay: 0 }))}
            onPointerUp={touchOnly(delayedHideTip)}
            // we don't want a hovering stylus to activate tooltip
            onPointerEnter={mouseOnly(showTip)}
            onPointerLeave={mouseOnly(hideTip)}
        >
            {children}
            {(isShow ?? active) && content && (
                <div className={`${styles["tooltip"]} ${styles[direction || "bottom"]}`}>
                    {content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
