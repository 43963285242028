import React, { useCallback, useContext, useEffect, useState } from "react";
import { DEFAULT_CONTROL_PORT, DEFAULT_HOST } from "../consts";
import {
    STATE_JETPACK_CONNECTED,
    STATE_JETPACK_CONNECTING,
    STATE_JETPACK_DISCONNECTED,
} from "../lib/state";

import { connect as socketIOConnect, send } from "../lib/socket";
import { address } from "../lib/utils";

const DEFAULT_SOCKET_ADDRESS = address({ host: DEFAULT_HOST, port: DEFAULT_CONTROL_PORT });

export const ControlSocketContext = React.createContext({
    controlSocket: null,
    socketAddress: DEFAULT_SOCKET_ADDRESS,
    socketState: STATE_JETPACK_DISCONNECTED,
});
ControlSocketContext.displayName = "ControlSocket";

export const ControlSocketConsumer = ControlSocketContext.Consumer;
export const useSocket = () => useContext(ControlSocketContext);

function ControlSocketProvider({ children }) {
    const [controlSocket, setControlSocket] = useState(null); // one instance of socket
    const [socketAddress, setSocketAddress] = useState(DEFAULT_SOCKET_ADDRESS);
    const [socketState, setSocketState] = useState(STATE_JETPACK_DISCONNECTED);

    useEffect(() => {
        if (!socketAddress) return;
        console.log("connecting control socket...");
        const socket = socketIOConnect(socketAddress);
        setSocketState(STATE_JETPACK_CONNECTING);
        const timeoutId = setTimeout(() => setSocketState(STATE_JETPACK_DISCONNECTED), 5000);

        socket.on("connect", () => {
            console.log("control socket connected.");
            setControlSocket(socket);
            clearTimeout(timeoutId);
            setSocketState(STATE_JETPACK_CONNECTED);
        });

        socket.on("disconnect", () => {
            console.log("control socket disconnected.");
            setSocketState(STATE_JETPACK_DISCONNECTED);
        });

        return () => {
            socket.close();
            setSocketState(STATE_JETPACK_DISCONNECTED);
            setControlSocket(null);
        };
    }, [socketAddress]);

    const sendControl = useCallback((args) => send(controlSocket, ...args), [controlSocket]);

    return (
        <ControlSocketContext.Provider
            value={{ controlSocket, socketAddress, socketState, setSocketAddress, sendControl }}
        >
            {children}
        </ControlSocketContext.Provider>
    );
}

export default ControlSocketProvider;
