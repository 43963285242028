import styles from "./css/ProximityAlert.module.css";

import { useMemo } from "react";
import { STATE_STREAM_NONE, STATE_STREAM_OKAY, STATE_STREAM_SLOW } from "../lib/state";
const ProximityBar = ({ name, value, ...otherProps }) => {
    // isShow require direction & distance <= 60
    const isShow = value <= 60;
    const color = value <= 44 ? `red` : value >= 45 && value <= 60 ? `yellow` : "";
    // console.log(isShow)
    // console.log(color)
    // console.log(name)
    const barDirection = () => {
        switch (name) {
            case "front":
                return styles[`top-${color}-bar`];
            case "back_depth":
                return styles[`bottom-${color}-bar`];
            case "left_depth":
                return styles[`left-${color}-bar`];
            case "right_depth":
                return styles[`right-${color}-bar`];
            default:
                return "";
        }
    };

    return (
        <>
            {" "}
            <div className={`${barDirection() ?? ""} `} {...otherProps} />
        </>
    );
};

function ProximityAlert({ camConnected = false, streamStatus, proximities = [] }) {
    // avoidance List should have Object that include direction and distance of obstacle
    /** params
    direction : 'top' || 'bottom' || 'left' || 'right'
     distance : Number
     */
    // { direction:'top', distance: 45 }
    const isShow = useMemo(() => {
        if (!camConnected) return false;
        else {
            switch (streamStatus) {
                case STATE_STREAM_OKAY:
                    return true;
                case STATE_STREAM_SLOW:
                    return true;
                case STATE_STREAM_NONE:
                    return false;
                default:
                    return false;
            }
        }
    }, [camConnected, streamStatus]);
    // console.log(proximities)
    return (
        <>
            <div className={`${styles["container"]}`}>
                {/* Should not exist 5 directions */}
                {proximities.length < 6 && proximities.map((item) => <ProximityBar {...item} />)}
            </div>
        </>
    );
}

export default ProximityAlert;
