import React, { useContext } from "react";
import { deleteCookie, digest, setCookie } from "../lib/auth";
import useFindUser from "../lib/hooks/useFindUser";
import { en } from "../lib/locale";
import { STATE_LOGGED_IN, STATE_LOGGED_OUT } from "../lib/state";

export const AuthContext = React.createContext();
AuthContext.displayName = "Auth";
export const AuthConsumer = AuthContext.Consumer;
export const useAuth = () => useContext(AuthContext);

function AuthProvider({ children }) {
    const { user, setUser, isLoading } = useFindUser(
        process.env.REACT_APP_USER,
        process.env.REACT_APP_PASSWORD
    );

    const setUserData = (data_or_callback) => {
        if (typeof data_or_callback === "function") {
            const newMetadata = data_or_callback(user.metadata) ?? {};
            setUser((prevUser) => ({
                ...prevUser,
                metadata: { ...prevUser.metadata, newMetadata }, // append
            }));
        } else if (typeof data_or_callback === "object")
            setUser((prevUser) => ({ ...prevUser, metadata: data_or_callback })); // replace
    };

    const logIn = async (user, pw) => {
        // not accessing the env vars outside the function to make them in scope and delete automatically
        const username = process.env.REACT_APP_USER;
        const password = process.env.REACT_APP_PASSWORD;
        const hashedPW = await digest(pw);

        if (hashedPW === password && user === username) {
            setUser((prevUser) => ({ ...prevUser, isAuthenticated: true }));
            setCookie("user", await digest(user + hashedPW), 1); // expire after one day
            return STATE_LOGGED_IN;
        } else {
            if (hashedPW === "" || user === "") throw en.login.emptyCredential;
            else throw en.login.invalidCredential;
        }
    };

    const logOut = async () => {
        setUser((prevUser) => ({ ...prevUser, isAuthenticated: false }));
        deleteCookie("user");
        return STATE_LOGGED_OUT;
    };

    return (
        <AuthContext.Provider value={{ user, setUserData, isLoading, logIn, logOut }}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
