import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import React from "react";
import styles from "./css/FindMyRobot.module.css";
import { isLost } from "./GpsProvider";
// import { ReactComponent as IconQuadRobot } from "../svg/IconQuadRobot.svg";
import IconQuadRobotPath from "../svg/IconQuadRobot.svg";

const humanOrigin = {
    lat: -13.996835775926973,
    lng: 24.538921806701858,
};

const DEFAULT_ZOOM_LV = 19;

// can see whole world at this zoom level
const NO_COORDS_ZOOM_LV = 2;

function GoogleMapWrapper({ gpsLatLong, gpsStatus, showLast = false }) {
    const lost = isLost(gpsStatus);
    const showLocation = gpsLatLong && ((lost && showLast) || !lost);
    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
            <GoogleMap
                mapContainerClassName={styles["map-container"]}
                center={showLocation ? gpsLatLong : humanOrigin}
                zoom={showLocation ? DEFAULT_ZOOM_LV : NO_COORDS_ZOOM_LV}
            >
                {showLocation && (
                    <Marker
                        icon={IconQuadRobotPath}
                        className={styles["map-marker"]}
                        position={gpsLatLong}
                    ></Marker>
                )}
            </GoogleMap>
        </LoadScript>
    );
}

export default React.memo(GoogleMapWrapper);
