import React, { useCallback, useContext, useEffect, useState } from "react";
import { subscribe, unsubscribe } from "../lib/socket";
import { useSocket } from "./ControlSocketProvider";

export const RobotErrorContext = React.createContext();
RobotErrorContext.displayName = "RobotError";
export const RobotErrorsConsumer = RobotErrorContext.Consumer;
export const useRobotErrors = () => useContext(RobotErrorContext);

export const TOPIC_ROBOT_ERRORS = "/error";

function RobotErrorProvider({ children }) {
    const { controlSocket: socket } = useSocket();
    /**
     * Expected format of robot error
     * {
     *      type: string, // WARNING, ERROR or INFO
     *      message: string, // "type/time" internally generated, not from socket
     *      description: string, // optional, appears randomly
     * }
     */
    const [robotErrors, setRobotErrors] = useState([]);
    const [hasNewErrors, setHasNewErrors] = useState(false);

    const addRobotError = useCallback((error) => {
        setRobotErrors((prev) => [{ ...error, time: new Date(), hasExpired: false }, ...prev]);
    }, []);

    useEffect(() => {
        subscribe(socket, TOPIC_ROBOT_ERRORS, addRobotError);

        return () => {
            socket && unsubscribe(socket, TOPIC_ROBOT_ERRORS, addRobotError);
        };
    }, [socket, addRobotError]);

    // clear old robot errors
    useEffect(() => {
        const id = setInterval(() => {
            setRobotErrors((prev) => {
                const now = Date.now(); // ms since epoch
                return prev.map((p) =>
                    now - p.time.getTime() > 25000 ? { ...p, hasExpired: true } : p
                );
            });
        }, 1000);

        return () => {
            clearInterval(id);
        };
    }, []);

    useEffect(() => {
        setHasNewErrors(robotErrors.some((e) => !e.hasExpired));
    }, [robotErrors]);

    return (
        <RobotErrorContext.Provider
            value={{
                robotErrors,
                hasNewErrors,
                // for dev only. Currently used by DummyForDev
                addRobotError,
            }}
        >
            {children}
        </RobotErrorContext.Provider>
    );
}

export default RobotErrorProvider;
