import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { copyToClipboard } from "../lib/utils";

import {
    faBug,
    faEnvelope,
    faMobileAlt,
    faPhoneAlt,
    faSync,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconFB } from "../svg/IconFB.svg";
import { ReactComponent as IconReload } from "../svg/IconReload.svg";
import { ReactComponent as IconWhatsapp } from "../svg/IconWhatsapp.svg";
import styles from "./css/Menu.module.css";

import { motorOnOff } from "../lib/protocols/control";
import { STATE_MOTOR_POWERED_OFF, STATE_MOTOR_POWERED_ON } from "../lib/state";
import { useControl } from "./RobotControlProvider";
import { RobotStateContext } from "./RobotStateProvider";

import DialogBox from "./DialogBox";
import { SubMenu } from "./Menu";
import MenuButton from "./MenuButton";

const isDebugMode = new URLSearchParams(window.location.search).get("debug");

function SupportMenu({ onClose }) {
    const history = useHistory();
    const { motorState } = useContext(RobotStateContext);
    const { restartStreaming } = useControl();
    const [confirmPrompt, setConfirmPrompt] = useState(null);
    const [restarting, setRestarting] = useState(false);

    const restartJetpack = () => setRestarting(true);

    useEffect(() => {
        if (!restarting) return;

        if (motorState === STATE_MOTOR_POWERED_ON) {
            motorOnOff(false);
        } else if (motorState === STATE_MOTOR_POWERED_OFF) {
            motorOnOff(true);
            setRestarting(false);
        }
    }, [motorState, restarting]);

    return (
        <SubMenu title="Troubleshoot" onClose={onClose}>
            <div className={styles["btn-grid"]}>
                {/* not implemented yet */}
                {false && (
                    <MenuButton
                        icon={IconReload}
                        iconType="svg"
                        description="Restart Jetpack"
                        btnClass={"red-btn"}
                        action={() =>
                            setConfirmPrompt({
                                title: "Restart JetPack",
                                textConfirm: "Restart",
                                content: "Are you sure you want to restart JetPack?",
                                onConfirm: () => restartJetpack(),
                                onCancel: () => setConfirmPrompt(null),
                            })
                        }
                    ></MenuButton>
                )}
                <MenuButton
                    icon={faSync}
                    iconType="fa"
                    size="lg"
                    description="Restart stream"
                    action={() => restartStreaming()}
                ></MenuButton>
                {isDebugMode === "syslog" && (
                    <MenuButton
                        icon={faBug}
                        iconType="fa"
                        size="lg"
                        description="Debug Console"
                        action={() => history.push("/main/debug-console")}
                    ></MenuButton>
                )}
                <MenuButton
                    icon={faPhoneAlt}
                    iconType="fa"
                    size="lg"
                    description="Contact R2C2"
                    action={() => {
                        setConfirmPrompt({
                            title: "Contact R2C2",
                            textConfirm: null,
                            textCancel: "Dismiss",
                            content: <ContactInfo />,
                            onCancel: () => setConfirmPrompt(null),
                        });
                    }}
                ></MenuButton>
            </div>
            {confirmPrompt && <DialogBox {...confirmPrompt}>{confirmPrompt.content}</DialogBox>}
        </SubMenu>
    );
}

function ContactInfo() {
    const [showCopyNum, setShowCopyNum] = useState(false);

    const copyNum = (num) => {
        copyToClipboard(num);
        setShowCopyNum(true);
        setTimeout(() => setShowCopyNum(false), 2000);
    };
    return (
        <section className={styles["contact-container"]}>
            <p>
                <MenuButton
                    icon={faEnvelope}
                    iconType="fa"
                    size="lg"
                    action={() => window.open("mailto:support@r2c2.ai", "_blank")}
                />
                support@r2c2.ai
            </p>
            <p>
                <MenuButton
                    icon={faMobileAlt}
                    iconType="fa"
                    size="lg"
                    style={{ fontSize: "16px" }}
                    tooltip={{
                        isShow: showCopyNum,
                        content: "Copied to clipboard",
                        direction: "right",
                    }}
                    action={() => copyNum("6423 2543")}
                />
                +852 6423 2543 (San Wong)
            </p>
            <p>
                <MenuButton
                    icon={IconWhatsapp}
                    iconType="svg"
                    size="lg"
                    action={() => {
                        window.open("https://wa.me/85264232543?text=", "_blank");
                    }}
                />
                +852 6423 2543 (San Wong)
            </p>
            <p>
                <MenuButton
                    icon={IconFB}
                    iconType="svg"
                    action={() => window.open("https://www.facebook.com/r2c2robotics/", "_blank")}
                />
                @r2c2robotics
            </p>
        </section>
    );
}

export default SupportMenu;
