export const XBOX = {
    buttons: [
        "A",
        "B",
        "X",
        "Y",
        "LB",
        "RB",
        "LT",
        "RT",
        "Back",
        "Start",
        "LS",
        "RS",
        "DPadUp",
        "DPadDown",
        "DPadLeft",
        "DPadRight",
    ],
    axes: ["LeftX", "-LeftY", "RightX", "-RightY"],
    triggers: [null, null, null, null, null, null, "LeftTrigger", "RightTrigger"],
};
