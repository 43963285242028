import React from "react";
import styles from "./css/Gamepad.module.css";

class AxisItem extends React.Component {
    render() {
        return (
            <li className={this.props.className} style={this.props.style}>
                <label>{this.props.label}</label>
                <span className={styles["value"]}>{this.props.value}</span>
            </li>
        );
    }
}

export default class GamepadItem extends React.Component {
    axisStyle(n) {
        return {
            opacity: Math.abs(n) + 0.3,
        };
    }

    buttonStyle(id) {
        var val = this.buttonValue(id);
        return {
            opacity: Math.abs(val) + 0.3,
            border: this.buttonPressed(id) ? "1px solid #888" : "1px solid transparent",
        };
    }

    buttonValue(b) {
        return typeof b == "number" ? b : b.value;
    }

    buttonPressed(b) {
        return typeof b == "number" ? b > 0.1 : b.pressed;
    }

    mappingString(mapping) {
        return mapping || "n/a";
    }

    formatFloat(n, places) {
        var m = Math.pow(10, places);
        return "" + parseFloat("" + Math.round(n * m) / m).toFixed(places);
    }

    testVibration() {
        if (this.props.gamepad && this.props.gamepad.vibrationActuator) {
            this.props.gamepad.vibrationActuator.playEffect("dual-rumble", {
                startDelat: 0,
                duration: 1000,
                weakMagnitude: 1.0,
                strongMagnitude: 1.0,
            });
        }
    }

    render() {
        var gamepad = this.props.gamepad;
        if (gamepad) {
            return (
                <div className={styles["gamepad"]}>
                    <h2 className={styles["active"]}>
                        <span className={styles["icon"]}>{this.props.index + 1} </span>
                        <span>{gamepad.id}</span>
                    </h2>

                    <ul className={styles["info"]}>
                        <AxisItem label="INDEX" value={gamepad.index} />
                        <AxisItem
                            className={styles["med"]}
                            label="CONNECTED"
                            value={gamepad.connected ? "Yes" : "No"}
                        />
                        <AxisItem
                            className={styles["med"]}
                            label="MAPPING"
                            value={this.mappingString(gamepad.mapping)}
                        />
                        <AxisItem
                            className={styles["large"]}
                            label="TIMESTAMP"
                            value={this.formatFloat(gamepad.timestamp, 5)}
                        />
                    </ul>

                    <ul className={styles["axes"]}>
                        {gamepad.axes.map((axis, i) => (
                            <AxisItem
                                key={i}
                                label="INDEX"
                                value={this.formatFloat(axis, 5)}
                                style={this.axisStyle(axis)}
                            />
                        ))}
                    </ul>

                    <ul className={styles["buttons"]}>
                        {gamepad.buttons.map((button, i) => (
                            <AxisItem
                                key={i}
                                label={"B" + i}
                                value={this.formatFloat(this.buttonValue(button), 2)}
                                style={this.buttonStyle(button)}
                            />
                        ))}
                    </ul>

                    <ul className={styles["info"]}>
                        <AxisItem
                            className={styles["med"]}
                            label="Pose"
                            value={
                                gamepad.pose && Object.keys(gamepad.pose).length > 0 ? "Yes" : "n/a"
                            }
                        />
                        <AxisItem
                            className={styles["med"]}
                            label="HapticActuators"
                            value={
                                gamepad.hapticActuators &&
                                Object.keys(gamepad.hapticActuators).length > 0
                                    ? "Yes"
                                    : "n/a"
                            }
                        />
                        <AxisItem
                            className={styles["med"]}
                            label="Hand"
                            value={gamepad.hand ? gamepad.hand : "n/a"}
                        />
                        <AxisItem
                            className={styles["med"]}
                            label="DisplayId"
                            value={gamepad.displayId != null ? gamepad.displayId : "n/a"}
                        />
                        <AxisItem
                            className={styles["med"]}
                            label="Vibration"
                            value={gamepad.vibrationActuator ? "Yes" : "n/a"}
                        />
                        {gamepad.vibrationActuator && (
                            <li
                                className={styles["med test-button"]}
                                onClick={() => this.testVibration()}
                            >
                                <label>TEST</label>
                                <span className={styles["value"]}>Vibration</span>
                            </li>
                        )}
                    </ul>
                    <pre>
                        Axes:
                        {JSON.stringify(gamepad.axes, null, 2)}
                    </pre>
                    <pre>
                        Buttons:
                        {JSON.stringify(
                            gamepad.buttons.map((b) => b.pressed),
                            null,
                            2
                        )}
                    </pre>
                </div>
            );
        } else {
            return (
                <div className={styles["gamepad"]}>
                    <h2 className={styles["inactive"]}>
                        <span className={styles["icon"]}>{this.props.index + 1} </span>
                        <span>n/a</span>
                    </h2>
                </div>
            );
        }
    }
}
