import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import styles from "./css/LockJoystickButton.module.css";
import MenuButton from "./MenuButton";

function LockJoystickButton({ onClick, onPointerUp, className, status = false }) {
    return (
        <div
            className={`${className || ""} ${styles.container}`}
            onPointerUp={(e) => onPointerUp(e)}
        >
            <MenuButton
                id={"LockButton"}
                description={`${status ? `Unlock` : `Lock`}`}
                icon={status ? faLockOpen : faLock}
                iconType="fa"
                action={(e) => {
                    onClick(e);
                }}
            />
        </div>
    );
}
export default LockJoystickButton;
