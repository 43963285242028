import { useContext } from "react";
import { BatteryContent } from "./BatteryProvider";
import DialogBox from "./DialogBox";

function BatteryLowDialogBox() {
    const { setShowBatteryLow, showBatteryLowPopup } = useContext(BatteryContent);
    return (
        <>
            {showBatteryLowPopup && (
                <DialogBox
                    title={"Battery low, please return to charger ASAP"}
                    textCancel={"CANCEL"}
                    textConfirm={null}
                    formStyle={{ width: "768px", backgroundColor: "black" }}
                    titleStyle={{ fontSize: "22px", color: "red", textAlign: "center" }}
                    cancelStyle={{ maxWidth: "200px" }}
                    onCancel={() => setShowBatteryLow(false)}
                />
            )}

            {!showBatteryLowPopup && null}
        </>
    );
}

export default BatteryLowDialogBox;
