import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useContext, useState } from "react";
import useClickOutside from "../lib/hooks/useClickOutside";
import {
    STATE_WIFI_CONNECTED,
    STATE_WIFI_CONNECTING,
    STATE_WIFI_MODIFIED,
    STATE_WIFI_PW_INCORRECT,
    STATE_WIFI_SSID_NOT_FOUND,
} from "../lib/state";
import { ReactComponent as IconInfo } from "../svg/IconInfo.svg";
import { ReactComponent as IconQuestion } from "../svg/IconQuestion.svg";
import { ReactComponent as LoadingSpinner } from "../svg/LoadingSpinner.svg";
import styles from "./css/WifiSettings.module.css";
import Icon from "./Icon";
import MenuButton from "./MenuButton";
import Select from "./Select";
import SnackBar from "./SnackBar";
import Tooltip from "./Tooltip";
import { WifiSwitchingContext } from "./WifiSwitchingProvider";

function WifiSettings({ device, onClose }) {
    const [mode, setMode] = useState(0);
    const [SSID, setSSID] = useState("");
    const [password, setPassword] = useState("");
    const [showHelp, setShowHelp] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { configWifi, wifiStatus } = useContext(WifiSwitchingContext);

    const clickOutsideRef = useClickOutside(() => {
        // disable for now after discussion
        // onClose();
    });

    const modes = [
        { id: 0, label: "Client Mode" },
        { id: 1, label: "AP Mode" },
    ];
    const defaultMode = modes[0];
    const getMode = (id) => modes.find((mode) => mode.id === id);

    const helpMsgs = {
        Jetpack:
            "If you can’t find Jetpack after changing connection settings, press the connection reset button on the side of Jetpack, in order to reset to defualt settings.",
        SPOT: "If you can’t find your Jetpack after changing wifi settings, you can connect an ethernet cable (lan cable) from Jetpack’s ethernet part to Robot’s ethernet part.",
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowPassword(false);
        configWifi(getMode(mode).label, SSID, password, device);
    };

    const clearInputs = () => {
        setSSID("");
        setPassword("");
    };

    return (
        <div className={styles.container}>
            <div className={styles.card} ref={clickOutsideRef}>
                <h1 className={styles.header}>{`${device} Connection Settings`}</h1>
                {device === "SPOT" && ( // AP mode is only allowed on SPOT
                    <>
                        <section className={styles.mode}>
                            <div className={styles.select}>
                                <span>Current Mode</span>
                                <Select
                                    options={modes}
                                    placeholder={defaultMode.label}
                                    onSelect={(id) => setMode(id)}
                                ></Select>
                            </div>
                            <p className={styles.message}>
                                <Icon icon={IconInfo} type="svg" width="24px" height="24px" />
                                <span>{`${device} is currently a wifi hotspot with ${
                                    mode ? modes[mode].label : defaultMode.label
                                }. Connect its wifi to control your ${device}`}</span>
                            </p>
                        </section>
                        <hr className={styles.separator} />
                    </>
                )}
                <form className={styles.form} onSubmit={handleSubmit}>
                    <p style={{ maxWidth: "30em" }}>
                        Set up your SSID (hotspot name) and Password to connect your {device} with
                        wifi:
                    </p>
                    <div style={{ display: "flex" }}>
                        <button type="reset" className={styles.reset} onClick={() => clearInputs()}>
                            reset all
                        </button>
                    </div>
                    <div
                        data-error="SSID not found in current network!"
                        className={`${styles.input} ${
                            wifiStatus === STATE_WIFI_SSID_NOT_FOUND && styles.error
                        }`}
                    >
                        <label htmlFor="ssid">SSID</label>
                        <input
                            value={SSID}
                            onInput={(e) => setSSID(e.target.value)}
                            type="text"
                            placeholder="Enter SSID"
                            id="ssid"
                            name="ssid"
                        />
                    </div>
                    <div
                        data-error="password is incorrect!"
                        className={`${styles.input} ${
                            wifiStatus === STATE_WIFI_PW_INCORRECT && styles.error
                        }`}
                    >
                        <label htmlFor="password">Password</label>
                        <div className={styles.password}>
                            <input
                                type={showPassword ? "text" : "password"}
                                autoComplete="new-password"
                                value={password}
                                onInput={(e) => setPassword(e.target.value)}
                                placeholder="Enter Password"
                                id="password"
                                name="password"
                            ></input>
                            <Icon
                                className={styles.icon}
                                icon={showPassword ? faEyeSlash : faEye}
                                type="fa"
                                size="1x"
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </div>
                    </div>
                    <Tooltip content="Enter SSID to connect" wrapperStyle={{ width: "100%" }}>
                        <button
                            type="submit"
                            className={styles.btn}
                            disabled={SSID === "" || wifiStatus === STATE_WIFI_CONNECTING}
                        >
                            {wifiStatus === STATE_WIFI_CONNECTED ? (
                                "Connect"
                            ) : wifiStatus === STATE_WIFI_CONNECTING ? (
                                <span>
                                    <Icon
                                        icon={LoadingSpinner}
                                        type="svg"
                                        className={styles.spinner}
                                    ></Icon>
                                    Connecting... It may take a while
                                </span>
                            ) : wifiStatus === STATE_WIFI_MODIFIED ? (
                                "Settings applied!"
                            ) : (
                                "Try again"
                            )}
                        </button>
                    </Tooltip>
                </form>
                <div className={styles.footer}>
                    <span className={styles.close}>
                        <MenuButton
                            icon={faTimes}
                            iconType="fa"
                            size="lg"
                            action={onClose}
                        ></MenuButton>
                    </span>
                    <span
                        className={styles.help}
                        onPointerEnter={() => setShowHelp(true)}
                        onMouseLeave={() => setShowHelp(false)}
                    >
                        <Icon icon={IconQuestion} type="svg" width="1.5em" height="1.5em" />
                        Help
                        {showHelp && (
                            <div className={styles["help-msg"]}>
                                <SnackBar type="info">{helpMsgs[device]}</SnackBar>
                            </div>
                        )}
                    </span>
                    {/* <span className={styles["close-hint"]}>
                        or <br />
                        Tap outside to exit
                    </span> */}
                </div>
            </div>
        </div>
    );
}

export default WifiSettings;
