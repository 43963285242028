import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./css/LogsTab.module.css";
import { useLog } from "./MissionLogProvider";

import { onClickAndTouchEnd, strCompare, includesCaseInsensitive } from "../lib/utils";
import EntityAbsence from "./EntityAbsence";
import SearchBar from "./SearchBar";
import Section from "./Section";

export function LogBox({ onChoose, entry, isSelected }) {
    return (
        <button
            className={`${styles.box} ${isSelected ? `${styles["selected"]}` : ``}`}
            onClick={() => onChoose()}
        >
            <div className={styles.entry}>
                <div>{entry.time}</div>
                <div>{entry.name}</div>
            </div>
        </button>
    );
}

function LogsTab() {
    const history = useHistory();
    const [selectedId, setSelectedId] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [selectedSort, setSelectedSort] = useState("time");
    const [isAscending, setIsAscending] = useState({
        name: true,
        time: false,
    });
    const toggleAscending = useCallback(
        (sortField) =>
            setIsAscending({
                ...isAscending,
                [sortField]: !!!isAscending[sortField],
            }),
        [isAscending, setIsAscending]
    );
    const searchBarProps = {
        isAscending,
        toggleAscending,
        setSelectedSort,
    };
    const match = useRouteMatch("/main/logs/:logId");
    const { logId = null } = match?.params || {};
    const { logs, getLogs } = useLog();
    const search = (array, searchText, fields) => {
        if (!searchText) return array;
        return array.filter(
            (item) =>
            includesCaseInsensitive(item["name"], searchText) ||
            includesCaseInsensitive(item["time"], searchText) ||
            item["name"].includes(selectedId)
        );
    };
    useEffect(() => {
        getLogs();
    }, []);
    // TODO: generalize and extract search logic as separate components. now logstab and missionstab use the same set of functions to implement search logic
    const computedLogsList = useMemo(() => {
        if (!logs) return [];
        return search(logs, searchText, selectedId).sort((a, b) =>
            strCompare({ reverse: !isAscending[selectedSort] })(a[selectedSort], b[selectedSort])
        );
    }, [logs, searchText, isAscending, selectedId, selectedSort]);
    return (
        <div style={{ overflowY: "auto", width: "301px" }}>
            <Section retractable={false}>
                <Section.Header>
                    Logs
                    <button
                        className={styles["sm-action-btn"]}
                        {...onClickAndTouchEnd(() => getLogs())}
                    >
                        (Refresh)
                    </button>
                </Section.Header>
                <Section.Content className={styles.content}>
                    <SearchBar
                        text={searchText}
                        onChange={setSearchText}
                        {...searchBarProps}
                        showBackBtn={match}
                        btnFn={() => {
                            getLogs();
                            history.goBack();
                        }}
                    />
                    {logs.length > 0 ? (
                        computedLogsList.map((entry, index) => (
                            <LogBox
                                key={index}
                                entry={entry}
                                onChoose={() => {
                                    history.push(`/main/logs/${entry.log_id}`);
                                }}
                                isSelected={logId === entry.log_id}
                            ></LogBox>
                        ))
                    ) : (
                        <EntityAbsence
                            title={"No Logged Mission Yet"}
                            btnText={"Start a Mission to Log One"}
                        ></EntityAbsence>
                    )}
                </Section.Content>
            </Section>
        </div>
    );
}

export default React.memo(LogsTab);
