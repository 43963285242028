export const MODE_AP = "AccessPoint";
export const MODE_CLIENT = "Client";

export const TARGET_ROBOT = "";
export const TARGET_JETPACK = "/nano";

export const TOPIC_WIFI_CONFIG = "/config/wifi";
export const TOPIC_WIFI_RESULT = "/config/wifi/result";

export const CODE_CONNECTING = 0;
export const CODE_OKAY = 200;
export const CODE_PARAMETER_ERROR = 403;
export const CODE_SSID_OR_PW_INVALID = 406;
export const CODE_CONNECTION_TIMEOUT = 408;
export const CODE_SPOT_ERROR = 500;

export const codeToMsg = (code) => {
    switch (code) {
        case CODE_OKAY:
            return "Wifi settings are successfully applied";
        case CODE_SSID_OR_PW_INVALID:
            return "SSID is not found or password is incorrect";
        case CODE_CONNECTION_TIMEOUT:
            return "Connection timeout. Failed to connect to SPOT";
        case CODE_PARAMETER_ERROR:
            return "Invalid parameter received. Please try again";
        case CODE_SPOT_ERROR:
            return "Network error! Please consult developers for help";
        default:
            return "Unknown error! Please consult developers for help";
    }
};
