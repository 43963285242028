import { faChevronLeft, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./css/FindMyRobot.module.css";
import GoogleMap from "./GoogleMap";
import { isLost, STATE_GPS_LOST, STATE_GPS_SETUPERROR, useGps } from "./GpsProvider";
import Icon from "./Icon";
import MenuButton from "./MenuButton";
import { useSnack } from "./SnackProvider";

function FindMyRobot() {
    const { gpsLatLong, gpsStatus } = useGps();
    const history = useHistory();
    const { setSnack } = useSnack();
    const [showLast, setShowLast] = useState(false);

    useEffect(() => {
        if (gpsStatus === STATE_GPS_SETUPERROR) {
            setSnack({
                type: "error",
                message: "GPS hardware error. It might be missing or damaged.",
            });
        } else if (gpsStatus === STATE_GPS_LOST) {
            setSnack({
                type: "warning",
                message: "GPS lost view of satellite",
            });
        }
    }, [gpsStatus]);

    const noGpsDesc = useMemo(() => {
        if (gpsLatLong && showLast) {
            return "Showing last location";
        } else if (gpsStatus === STATE_GPS_SETUPERROR) {
            return "GPS malfunction";
        } else if (!gpsLatLong) {
            return "No previous data";
        } else {
            return null;
        }
    }, [gpsStatus, gpsLatLong, showLast]);

    const showLastBtnDisabled = useMemo(() => {
        return !gpsLatLong || !isLost(gpsStatus);
    }, [gpsLatLong, gpsStatus]);

    return (
        <div className={`${styles.container} ${styles.content}`}>
            <div className={`${styles.header}`}>
                <div className={styles["back-btn"]}>
                    <MenuButton
                        icon={faChevronLeft}
                        iconType="fa"
                        action={() => history.goBack()}
                    />
                    <span className={styles["back-label"]}>Back to Menu</span>
                </div>

                <div className={styles.titles}>
                    <p className={styles.title}>Find My Robot</p>
                    <p className={styles.subtitle}>
                        <Icon
                            icon={faMapMarkerAlt}
                            type="fa"
                            alt="location"
                            size="1x"
                            className={styles.marker}
                        />
                        <span>
                            {isLost(gpsStatus)
                                ? `No GPS currently. ${noGpsDesc || ""}`
                                : `Approximate location of your SPOT`}
                        </span>
                    </p>
                </div>

                <div>
                    <button
                        className={`${styles["showlast-button"]} ${
                            showLast ? styles["showing-last"] : ""
                        } ${showLastBtnDisabled ? styles.hidden : ""}`}
                        onClick={() => setShowLast(!showLast)}
                    >
                        {showLast ? "Hide" : "Show"} last location
                    </button>
                </div>
            </div>
            <GoogleMap gpsLatLong={gpsLatLong} gpsStatus={gpsStatus} showLast={showLast} />
        </div>
    );
}

export default FindMyRobot;
