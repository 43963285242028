import React, { useEffect, useState } from "react";
import { useSnack } from "./SnackProvider";

export const FullScreenContext = React.createContext();
FullScreenContext.displayName = "FullScreen";
export const FullScreenConsumer = FullScreenContext.Consumer;

export const checkFullscreenSupport = () => {
    const docEl = document.documentElement;
    const requestFullscreen =
        (docEl.requestFullscreen ||
            docEl.mozRequestFullScreen ||
            docEl.webkitRequestFullScreen ||
            docEl.msRequestFullScreen) ??
        null;
    return requestFullscreen !== null;
};

export const getFullscreenElement = () => {
    const doc = window.document;
    return (
        (doc.fullscreenElement ||
            doc.mozFullScreenElement ||
            doc.webkitFullscreenElement ||
            doc.msFullscreenElement) ??
        null
    );
};

export const enterFullscreen = (el) => {
    const requestFullscreen =
        el.requestFullscreen ||
        el.mozRequestFullScreen ||
        el.webkitRequestFullScreen ||
        el.msRequestFullScreen;
    if (requestFullscreen) {
        requestFullscreen.call(el);
    } else {
        console.error("Fullscreen is not supported on your browser!");
    }
};

export const exitFullscreen = () => {
    const doc = window.document;
    var cancelFullscreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen ||
        doc.msExitFullscreen;
    if (cancelFullscreen) {
        cancelFullscreen.call(doc);
    } else {
        console.error("Fullscreen is not supported on your browser!");
    }
};

export const toggleFullscreen = (el) => {
    getFullscreenElement() === el ? exitFullscreen() : enterFullscreen(el);
};

export const FullScreenProvider = ({ children }) => {
    const { setSnack } = useSnack();
    const [isFullscreen, setFullscreen] = useState(
        () => getFullscreenElement() === document.documentElement
    );
    const [isFullscreenSupported] = useState(() => checkFullscreenSupport());

    useEffect(() => {
        const handler = () => {
            setFullscreen(getFullscreenElement() === document.documentElement);
        };
        document.addEventListener("fullscreenchange", handler);
        document.addEventListener("webkitfullscreenchange", handler);
        return () => {
            document.removeEventListener("fullscreenchange", handler);
            document.removeEventListener("webkitfullscreenchange", handler);
        };
    }, []);

    useEffect(() => {
        !isFullscreenSupported &&
            setSnack({
                type: "warning",
                message: "Fullscreen is not supported on your browser",
            });
    }, [isFullscreenSupported]);

    return (
        <FullScreenContext.Provider
            value={{
                isFullscreen,
                isFullscreenSupported,
                enterFullscreen: () => enterFullscreen(document.documentElement),
                exitFullscreen,
                toggleFullscreen: () => toggleFullscreen(document.documentElement),
            }}
        >
            {children}
        </FullScreenContext.Provider>
    );
};

export default FullScreenProvider;
