import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import styles from "./css/FloatingWindow.module.css";

import MenuButton from "./MenuButton";

function FloatingWindow({ title, children }) {
    const history = useHistory();

    return (
        <div className={styles.container}>
            <div className={styles.box}>
                <div className={styles.header}>
                    <MenuButton
                        icon={faChevronLeft}
                        iconType="fa"
                        btnClass={styles.closeBtn}
                        size="2x"
                        action={() => history.goBack()}
                    ></MenuButton>
                    <p className={styles.title}>{title}</p>
                </div>

                {children}
            </div>
        </div>
    );
}

export default FloatingWindow;
