import React from "react";
import { DEFAULT_SPEAKER_PORT } from "../consts";
import AudioStreamer from "../lib/audio-streamer";
import { blobToArrayBuffer, wsAddress } from "../lib/utils";

export const AudioContext = React.createContext();
AudioContext.displayName = "Audio";
export const AudioConsumer = AudioContext.Consumer;

const SEND_CHUNK_COUNT = 2;
class TicketCounter {
    constructor(initTicket = 10) {
        this.tickets = initTicket;
        this.AUTO_INCREMENT_INTERVAL = 2000; // 2s
        setInterval(() => {
            if (this.tickets <= 0) {
                this.increment();
            }
        }, this.AUTO_INCREMENT_INTERVAL);
    }

    increment() {
        // console.log("adding ticket...");
        this.ticket += 1;
    }

    use() {
        if (this.ticket <= 0) {
            console.log("no ticket left.");
            return false;
        } else {
            // console.log("using ticket...");
            this.ticket -= 1;
            return true;
        }
    }
}

class AudioProvider extends React.Component {
    constructor(props) {
        super(props);

        this.ws = null;
        this.accLength = 0;
        this.accStart = 0;
        this.canSend = false;
        this.retryId = 0;
        this.state = {
            // export
            micOpened: false,
        };
        this.streamer = null;

        this.ticketCounter = new TicketCounter();
        this.bufferedAudio = [];
    }

    componentDidMount() {
        // this.connect();
    }

    componentWillUnmount() {
        clearTimeout(this.retryId);
        this.disconnect();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.micOpened !== prevState.micOpened) {
            if (this.state.micOpened) {
                this.connect();
            } else {
                this.disconnect();
            }
        }
    }

    sendWhenReady(data) {
        if (!this.ws || this.ws.readyState !== this.ws.OPEN) return;

        this.ws.send(data);
    }

    bufferAudio(data) {
        this.bufferedAudio.push(data);
    }

    sendAudio(data) {
        // data is an instance of ArrayBuffer
        if (!this.ticketCounter.use()) {
            // ran out of ticket. not sending audio.
            // return;
        }
        // else we have ticket. sending!
        this.accLength += data.byteLength;
        if (this.accSgtart === 0) this.accStart = Date.now();
        const currTime = Date.now();
        if (currTime - this.accStart >= 1000) {
            console.log("avg data rate (KiB/s): ", this.accLength / 1024);
            this.accLength = 0;
            this.accStart = currTime;
        }
        if (!this.ws || !this.canSend || !this.state.micOpened) return;
        this.ws.send(data);
    }

    onOpen() {
        console.log("audio stream connected");
        this.canSend = true;
        this.streamer = new AudioStreamer("/worklets/send-input-processor.js", (data) => {
            this.sendAudio(data);
            // if (this.bufferedAudio.length === SEND_CHUNK_COUNT) {
            //     const chunksToSend = this.bufferedAudio.splice(0, SEND_CHUNK_COUNT);
            //     this.sendAudio(concatArrayBuffer(...chunksToSend));
            // } else {
            //     this.bufferedAudio.push(data);
            // }
        });
        this.streamer.start();
    }

    async onMessage(e) {
        const arrayBuffer = await blobToArrayBuffer(e.data);
        if (arrayBuffer.byteLength < 1) {
            return;
        }
        const firstByte = new Uint8Array(arrayBuffer.slice(0, 1))[0];
        switch (firstByte) {
            case 0x01:
                this.ticketCounter.increment();
                break;
            default:
                return;
        }
    }

    stopAudioStream() {
        this.streamer && this.streamer.stop();
        this.streamer = null;
        this.setMicOpen(false);
    }

    onClose() {
        console.log("audio stream disconnected");
        this.canSend = false;
        this.ws = null;
        this.stopAudioStream();
    }

    onError(err) {
        console.error("Socket encountered error: ", err.message, "Closing socket");

        this.disconnect();
    }

    connect() {
        const url = wsAddress({ port: DEFAULT_SPEAKER_PORT });
        this.ws = new WebSocket(url);

        this.ws.onopen = () => this.onOpen();
        this.ws.onmessage = (e) => this.onMessage(e);
        this.ws.onclose = () => this.onClose();
        this.ws.onerror = (err) => this.onError(err);
    }

    disconnect() {
        this.ws && this.ws.close();
    }

    setMicOpen(on) {
        this.setState({ micOpened: on });
    }

    render() {
        const { children } = this.props;

        return (
            <AudioContext.Provider
                value={{ ...this.state, setMicOpen: (on) => this.setMicOpen(on) }}
            >
                {children}
            </AudioContext.Provider>
        );
    }
}

export default AudioProvider;
