import React, { useEffect, useState } from "react";
import {
    codeToMsg,
    CODE_CONNECTING,
    CODE_OKAY,
    CODE_SSID_OR_PW_INVALID,
    TARGET_JETPACK,
    TOPIC_WIFI_CONFIG,
    TOPIC_WIFI_RESULT,
} from "../lib/protocols/wifi";
import { send, subscribe } from "../lib/socket";
import {
    STATE_WIFI_CONNECTED,
    STATE_WIFI_CONNECTING,
    STATE_WIFI_FAILED,
    STATE_WIFI_MODIFIED,
    STATE_WIFI_PW_INCORRECT,
    STATE_WIFI_SSID_NOT_FOUND,
} from "../lib/state";
import { useSocket } from "./ControlSocketProvider";

export const WifiSwitchingContext = React.createContext();
WifiSwitchingContext.displayName = "WifiSwitching";
export const WifiSwitchingConsumer = WifiSwitchingContext.Consumer;

function WifiSwitchingProvider({ children }) {
    const { controlSocket: socket } = useSocket();
    const [wifiStatus, setWifiStatus] = useState(STATE_WIFI_CONNECTED);

    const configJetpackWifi = (ssid, password) => {
        send(socket, TARGET_JETPACK + TOPIC_WIFI_CONFIG, { ssid, password });
    };

    const configWifi = (mode, ssid, password, device) => {
        /**
         * AP Mode don't change ip and change hostip to 192.168.3.100 (maybe)
         * change nano too after 5 seconds
         * Client ip change to 192.168.3.100, hostip 192.168.80.3
         */
        setWifiStatus(STATE_WIFI_CONNECTING);
        if (device === "Jetpack") {
            configJetpackWifi(ssid, password);
            return;
        }

        if (mode === "AP Mode") {
            send(socket, TOPIC_WIFI_CONFIG, {
                mode: "AccessPoint",
                ssid,
                password,
                ip: "",
                hostip: "192.168.3.100",
            });
            setTimeout(() => configJetpackWifi(ssid, password), 5000);
        } else if (mode === "Client Mode") {
            send(socket, TOPIC_WIFI_CONFIG, {
                mode: "Client",
                ssid,
                password,
                ip: "192.168.3.100",
                hostip: "192.168.80.3",
            });
        }
    };

    const handleResult = (res, msg, device) => {
        setWifiStatus(STATE_WIFI_MODIFIED);
        const { mode, ssid, password, ip, hostip } = res;
        console.log(mode, ssid, password, ip, hostip);
        console.info(msg);

        // set back to default state
        setTimeout(() => setWifiStatus(STATE_WIFI_CONNECTED), 3000);
    };

    const handleError = (code, msg, device) => {
        setWifiStatus(STATE_WIFI_FAILED);
        console.error("Wifi error code %d %s", code, msg);
        console.error(codeToMsg(code));
        if (code === CODE_SSID_OR_PW_INVALID) {
            msg.includes("SSID") && setWifiStatus(STATE_WIFI_SSID_NOT_FOUND);
            msg.includes("password") && setWifiStatus(STATE_WIFI_PW_INCORRECT);
        }
    };

    const handleData = (data, device) => {
        const { code, result, msg } = data;

        if (code === CODE_CONNECTING) console.log("Switching WiFi for", device);
        else if (code === CODE_OKAY) handleResult(result, msg, device);
        else handleError(code, msg, device);
    };

    useEffect(() => {
        if (socket) {
            subscribe(socket, TOPIC_WIFI_RESULT, (data) => {
                handleData(data, "SPOT");
            });
            subscribe(socket, TARGET_JETPACK + TOPIC_WIFI_RESULT, (data) => {
                handleData(data, "Jetpack");
            });
        }
    }, [socket]);

    return (
        <WifiSwitchingContext.Provider value={{ configWifi, wifiStatus }}>
            {children}
        </WifiSwitchingContext.Provider>
    );
}

export default WifiSwitchingProvider;
