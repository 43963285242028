import { createContext, useContext, useState } from "react";
import { DEFAULT_PTZ_STATE } from "../consts";

import { useSocket } from "./ControlSocketProvider";

export const PTZStateContext = createContext();
PTZStateContext.displayName = "PTZState";
export const PTZStateConsumer = PTZStateContext.Consumer;
export const usePTZState = () => useContext(PTZStateContext);

function PTZStateProvider({ children }) {
    // Connection
    const { controlSocket: socket } = useSocket();

    // PTZ variable
    const [ptzState, setPTZState] = useState(DEFAULT_PTZ_STATE);

    return (
        <PTZStateContext.Provider
            value = {{
                ...ptzState,
                setPTZState
            }}>
            {children}
        </PTZStateContext.Provider>
    );
}

export default PTZStateProvider;