import { useContext, useMemo, useState } from "react";
import useClickOutside from "../lib/hooks/useClickOutside";
import { ReactComponent as IconEllipsis } from "../svg/IconEllipsis.svg";
import styles from "./css/MissionItem.module.css";
import Icon from "./Icon";
import MissionEditor from "./MissionEditor";
import { useMission } from "./MissionProvider";
import { RobotStateContext } from "./RobotStateProvider";
import VideoButtons from "./VideoButtons";

function MissionItem({
    id,
    name,
    time,
    onClick,
    isLoading = false,
    className,
    isSelected = false,
    disabled = false,
    setSelectedId,
}) {
    const { missionEnded, missionId } = useMission();
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [editAction, setEditAction] = useState(null);
    const { robotControllable } = useContext(RobotStateContext);
    const editMenuRef = useClickOutside(() => {
        setShowEditMenu(false);
    });
    const usable = useMemo(() => {
        // mission item only usable when there's no mission running or when the mission is THE running mission.
        return missionEnded || id === missionId;
    }, [missionEnded, missionId, id]);

    if (!isLoading)
        return (
            <button
                disabled={disabled || !usable}
                className={`${className ?? ""} ${styles.container} ${
                    isSelected ? `${styles["selected"]}` : ``
                }`}
                onClick={onClick}
            >
                <div className={styles["name-wrapper"]}>
                    <div title={time}>{time}</div>
                    <div title={name}>{name}</div>
                </div>
                <div className={styles["icon-wrapper"]}>
                    {usable && (
                        <VideoButtons
                            disabled={!robotControllable || disabled}
                            type="run"
                            selectedId={id}
                        />
                    )}
                    {missionEnded && (
                        <Icon
                            onClick={() => setShowEditMenu((prev) => !prev)}
                            icon={IconEllipsis}
                            type="svg"
                            width="24px"
                            height="24px"
                        />
                    )}

                    {showEditMenu && (
                        <div className={styles["mission-edit"]} ref={editMenuRef}>
                            <button onClick={() => setEditAction("rename")}>Rename Mission</button>
                            <div aria-label="separator"></div>
                            <button
                                onClick={() => setEditAction("delete")}
                                style={{ color: "red" }}
                            >
                                Delete Mission
                            </button>
                        </div>
                    )}
                    {editAction && (
                        <MissionEditor
                            oldId={name}
                            currName={name}
                            editAction={editAction}
                            onClose={(newName) => {
                                setSelectedId(newName);
                                setShowEditMenu(false);
                                setEditAction(null);
                            }}
                        ></MissionEditor>
                    )}
                </div>

                {/*<Icon className={styles.open} icon={faChevronRight} type="fa" size="1x" />*/}
            </button>
        );
    else return <div className={styles.item} />;
}

export default MissionItem;
