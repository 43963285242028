import { useEffect, useState } from "react";
import styles from "./css/SelectArtPiece.module.css";
import DialogBox from "./DialogBox";
import { useInspection } from "./InspectionProvider";
import { useSnack } from "./SnackProvider";

function SelectArtPiece({ artId, onClose, listArt = false }) {
    const { inspectionData, getArtPieces, prepareArtRef, error } = useInspection();
    const [selectedData, setSelectedData] = useState(null);
    const { addToast } = useSnack();

    const errToast = () => {
        addToast({
            type: "error",
            message: `Could not retrieve art references: ${error}`,
            duration: 7000,
        });
    };

    useEffect(() => {
        // setSelectedData(artId);
        (async () => {
            await getArtPieces();
            error !== null && errToast();
        })();
    }, []);

    return (
        <>
            <DialogBox
                title={
                    listArt
                        ? "Delete selected art reference"
                        : "Select the reference photos for inspection"
                }
                onConfirm={() => onClose(selectedData)}
                onCancel={() => onClose(null)}
                options={{ confirmDisabled: error !== null }}
                style={{ maxWidth: "90vw" }}
            >
                <p>Reference Pictures</p>
                <div className={styles["data-container"]}>
                    <div className={styles.grid}>
                        {inspectionData.length > 0 ? (
                            inspectionData
                                .sort(({ id: id1 }, { id: id2 }) => id2 - id1)
                                .map(({ id, path, desc }) => (
                                    <img
                                        className={`${styles.data} ${
                                            id === selectedData ? styles.selected : ""
                                        }`}
                                        key={id}
                                        src={path}
                                        alt={desc}
                                        onClick={() => setSelectedData(id)}
                                    />
                                ))
                        ) : (
                            <p>
                                Sorry, art references are currently unavailable. Please try again
                                later.
                            </p>
                        )}
                    </div>
                </div>
            </DialogBox>
        </>
    );
}

export default SelectArtPiece;
