const DEFAULT_WORKLET_PATH = "worklets/send-input-processor.js";

var downsampleBuffer = function (buffer, sampleRate, outSampleRate) {
    if (outSampleRate === sampleRate) {
        return buffer;
    }
    if (outSampleRate > sampleRate) {
        throw RangeError("downsampling rate should be smaller than original sample rate");
    }
    var sampleRateRatio = sampleRate / outSampleRate;
    var newLength = Math.round(buffer.length / sampleRateRatio);
    var result = new Int16Array(newLength);
    // var result = new Float32Array(newLength);
    var offsetResult = 0;
    var offsetBuffer = 0;
    while (offsetResult < result.length) {
        var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
        var accum = 0,
            count = 0;
        for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
            accum += buffer[i];
            count++;
        }

        result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
        offsetResult++;
        offsetBuffer = nextOffsetBuffer;
    }
    return result.buffer;
};

export default class AudioStreamer {
    audioContext = null;
    workletPath = null;
    dataCb = null;

    constructor(workletPath = DEFAULT_WORKLET_PATH, dataCb = null) {
        if (!workletPath) {
            throw new Error("Invalid worklet path. Expected a non-empty string");
        }
        this.workletPath = workletPath;
        this.dataCb = dataCb;
    }

    async createRoutingGraph(audioContext, sourceStream) {}

    async addDownsampleModule() {
        console.log("audioContext.audioWorklet: ", this.audioContext.audioWorklet);
        try {
            await this.audioContext.audioWorklet.addModule(this.workletPath);
        } catch (e) {
            const msg = "Could not initiate audio processing module";
            console.error(`${msg}: `, e);
            throw new Error(msg);
        }
    }

    async onStreamObtained(stream) {
        this.audioContext = new AudioContext();
        this.audioContext.suspend();

        const mergerNode = this.audioContext.createChannelMerger(1);

        await this.addDownsampleModule();

        const downsampleNode = new AudioWorkletNode(this.audioContext, "send-input-processor");
        downsampleNode.channelCount = 1;
        downsampleNode.channelCountMode = "explicit";

        const audioSourceNode = new MediaStreamAudioSourceNode(this.audioContext, {
            mediaStream: stream,
        });
        audioSourceNode.connect(mergerNode);

        mergerNode.connect(downsampleNode);

        audioSourceNode.channelCount = 1;
        audioSourceNode.channelCountMode = "explicit";
        console.log("audioSourceNode:");
        console.log("inputs:", audioSourceNode.numberOfInputs);
        console.log("outputs:", audioSourceNode.numberOfOutputs);
        console.log("channelCount:", audioSourceNode.channelCount);
        console.log("channelCountMode:", audioSourceNode.channelCountMode);
        console.log("channelInterpretation:", audioSourceNode.channelInterpretation);
        downsampleNode.connect(this.audioContext.destination);
        downsampleNode.port.onmessage = (e) => {
            // console.log("collected audio", e.data);
            typeof this.dataCb === "function" && this.dataCb(e.data);
        };
    }

    checkAudioContextOrThrow() {
        if (this.audioContext === null) {
            throw new Error("audio stream not obtained yet");
        }
    }

    async start() {
        this.stream = await navigator.mediaDevices.getUserMedia({
            audio: {
                sampleRate: 44100,
                sampleSize: 16,
                channelCount: 2,
            },
            video: false,
        });
        await this.onStreamObtained(this.stream);
        this.checkAudioContextOrThrow();
        this.audioContext.resume();
    }

    async stop() {
        this.checkAudioContextOrThrow();
        this.audioContext.suspend();
        this.stream.getTracks().forEach((t) => {
            t.stop();
        });
        this.audioContext.close();
    }
}
