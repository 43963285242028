import React from "react";
import { onClickAndTouchEnd, toKebabCase } from "../lib/utils";
import styles from "./css/Tabs.module.css";

class Tabs extends React.Component {
    state = { currentTab: 0, labels: null };

    switchTab(tabIndex, label) {
        if (typeof this.props.onSwitchTab === "function")
            this.props.onSwitchTab({ tabIndex, label });
        this.setState({ currentTab: tabIndex });
    }

    static Item = ({ index, label, hidden, children }) => <>{children}</>;

    renderTab() {
        return this.props.children[this.state.currentTab];
    }

    updateAndNotify = () => {
        const i = this.labels.indexOf(toKebabCase(this.props.tab));
        if (i !== -1) this.setState({ currentTab: i });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.tab !== this.props.tab) {
            this.updateAndNotify();
        }
    }

    componentWillMount() {
        if (this.props.children.length <= 0) return;
        const { children, defaultTab } = this.props;
        const indices = children.reduce((indices, tab) => [...indices, tab.props.index], []);
        const labels = children.reduce(
            (lables, tab) => [...lables, toKebabCase(tab.props.label)],
            []
        );
        this.labels = labels;

        let defaultIndex = indices[0]; // first tab
        if (typeof defaultTab === "string") {
            const i = labels.indexOf(toKebabCase(defaultTab));
            if (i !== -1) defaultIndex = i; // found
        } else if (typeof defaultTab === "number") {
            if (defaultTab in indices) defaultIndex = defaultTab;
        }

        this.setState({
            currentTab: defaultIndex,
        });
    }

    render() {
        const { currentTab } = this.state;
        const tabs = this.props.children;

        return (
            <div className={styles.container}>
                <div className={styles["tab-btn-wrapper"]}>
                    {tabs.length > 0 &&
                        tabs.map((tab) => (
                            <button
                                style={{ display: tab.props.hidden ? "none" : null }}
                                key={tab.props.index}
                                className={`${styles["tab-btn"]} ${
                                    tab.props.index === currentTab && styles.selected
                                }`}
                                {...onClickAndTouchEnd(() =>
                                    this.switchTab(tab.props.index, tab.props.label)
                                )}
                            >
                                {tab.props.label ?? "Untitled"}
                            </button>
                        ))}
                </div>
                <div className={styles.content}>{this.renderTab()}</div>
            </div>
        );
    }
}

export default Tabs;
