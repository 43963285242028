import { ReactComponent as IconError } from "../svg/IconError.svg";
import { ReactComponent as LoadingSpinner } from "../svg/LoadingSpinner.svg";
import styles from "./css/Error.module.css";

/**
 * Socket should also be implemented at here to check if any fatal error
 * or loading status need to be stated at here.
 *
 * Need to discuss what error should be displayed with this popup.
 */

function ErrorPopup({ type = "error", title = "", content = "", onClose = () => {} }) {
    return (
        <div className={styles["error-container"]}>
            <div className={styles.card}>
                <p className={styles["card-title"]}>
                    {type === "error" && <IconError width="48px" height="48px"></IconError>}
                    {type === "loading" && <LoadingSpinner className={styles.spinner} />}
                    <span style={{ marginLeft: "16px" }}>{title}</span>
                </p>

                <p className={styles.content}>{content}</p>

                <button
                    className={styles.btn}
                    onClick={() => {
                        onClose();
                    }}
                >
                    Close
                </button>
            </div>
        </div>
    );
}

export default ErrorPopup;
