import { toTimestamp } from "../lib/utils";
import styles from "./css/LogHistory.module.css";
import {
    getIAQSamplesFromEvent,
    TYPE_CUSTOM_ACTION,
    TYPE_IAQ,
    TYPE_INSPECTION_RESULTS,
    TYPE_MISSION_FAILED,
    TYPE_MISSION_LOCATION,
    TYPE_MISSION_STARTING,
    TYPE_MISSION_STOPPED,
    TYPE_OBJECT_DETECTION,
    TYPE_VIDEO_RECORD_STATE,
    VIDEO_RECORD_STATE_RECORD_FAILED,
    VIDEO_RECORD_STATE_RECORD_STARTED,
    VIDEO_RECORD_STATE_RECORD_STOPPED,
} from "./MissionLogProvider";

const videoRecordStateToString = (state) => {
    switch (state) {
        case VIDEO_RECORD_STATE_RECORD_STOPPED:
            return "Record stopped";
        case VIDEO_RECORD_STATE_RECORD_FAILED:
            return "Record failed";
        case VIDEO_RECORD_STATE_RECORD_STARTED:
            return "Record started";
        default:
            return "Unknown record event";
    }
};

function LogHistoryDetail({ isShow, events = [] }) {
    const eventToString = (event) => {
        switch (event.type) {
            case TYPE_OBJECT_DETECTION:
                return `${event.data.length} objects detected`;
            case TYPE_IAQ:
                return `${Object.entries(event.data.samples).length} air qualities recorded`;
            case TYPE_MISSION_LOCATION:
                return "Mission location";
            case TYPE_MISSION_STARTING:
                return `Mission started`;
            case TYPE_MISSION_STOPPED:
                return `Mission stopped`;
            case TYPE_MISSION_FAILED:
                return `Mission failed`;
            case TYPE_CUSTOM_ACTION:
                return "Custom action";
            case TYPE_VIDEO_RECORD_STATE:
                return videoRecordStateToString(event.data);
            case TYPE_INSPECTION_RESULTS:
                return "Inspection completed";
            default:
                return `Unknown event`;
        }
    };

    const eventDataToString = (event) => {
        switch (event.type) {
            case TYPE_IAQ:
                // get all the types of IAQ
                const names = Object.entries(getIAQSamplesFromEvent(event).samples).map(
                    ([_, sample]) => sample.name
                );
                // const names = Object.entries(event.data.samples).reduce(
                //     (arr, sample) => [...arr, sample.name],
                //     []
                // );
                return names.join(", ");
            case TYPE_OBJECT_DETECTION:
                return event.data.join(", ");
            case TYPE_MISSION_STARTING:
            case TYPE_MISSION_STOPPED:
            case TYPE_MISSION_FAILED:
            default:
                return "";
        }
    };

    if (isShow) {
        return (
            <div className={styles["detail"]}>
                <h2 className={styles["sub-title"]}>Details</h2>
                {/* don't change the table id as export function depends on it */}
                <table id="mission-events-details" className={styles["detail-table"]}>
                    <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Event</th>
                            <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        {events.length > 0 ? (
                            events.map((event, index) => (
                                <tr key={index}>
                                    <td>{toTimestamp(event.timestamp)}</td>
                                    <td>{eventToString(event)}</td>
                                    <td>{eventDataToString(event)}</td>
                                </tr>
                            ))
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>
            </div>
        );
    } else {
        return <></>;
    }
}

export default LogHistoryDetail;
