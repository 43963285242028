import { useContext, useEffect, useRef, useState } from "react";
import { Joystick } from "../lib/joystick";
import { MODE_STAND, MODE_WALK } from "../lib/protocols/control";
import LockJoystickButton from "./LockJoystickButton";
import { RobotStateContext } from "./RobotStateProvider";

function JoystickWrapper({
    size = 140,
    onChange,
    className,
    disabled,
    onClick,
    isLocked,
    setIsLocked,
    enableLockFn = false,
    ...otherProps
}) {
    const { robotMode } = useContext(RobotStateContext);
    const joystick = useRef(new Joystick(size));
    const [showLockButton, setShowLockButton] = useState(false);
    const handleMovingJoystick = (y) => {
        // x <=-0.8 show Lock Button (-1 to 1, 1 is bottom, -1 is top)
        const limitOfX = -0.8;
        y <= limitOfX ? setShowLockButton(true) : setShowLockButton(false);
    };
    const showLocker = (enableLockFn && showLockButton) || joystick.current.isLocked;
    const walkMode = robotMode === MODE_WALK;
    useEffect(() => {
        isLocked ? joystick.current.lock() : joystick.current.unlock();
        setShowLockButton(isLocked);
    }, [isLocked]);
    useEffect(() => {
        joystick.current.isLocked && robotMode === MODE_STAND
            ? setIsLocked(true)
            : setIsLocked(false);
    }, [joystick.current.isLocked]);
    useEffect(() => {
        // joystick.current.unlock alone would not trigger a re-render.
        joystick.current.unlock();
        onChange({ x: 0, y: 0, z: 0 });
        // you need a setState here
        setIsLocked(false);
        setShowLockButton(false);
    }, [robotMode]);
    useEffect(() => {
        const joystickOnChange = (event) => {
            onChange(event.detail);
            handleMovingJoystick(event.detail?.y);
        };
        const perhapsUnlock = () => {
            if (walkMode) joystick.current.unlock();
        };
        joystick.current.getDomElement().addEventListener("pointerdown", perhapsUnlock);
        joystick.current.getDomElement().addEventListener("change", joystickOnChange);
        return () => {
            joystick.current.getDomElement().removeEventListener("change", joystickOnChange);
            joystick.current.getDomElement().removeEventListener("pointerdown", perhapsUnlock);
            joystick.current.stop();
        };
    }, [onChange, walkMode]);

    useEffect(() => {
        if (disabled && joystick.current.operating) joystick.current.stop();
        else if (!disabled && !joystick.current.operating) joystick.current.operate();
    });

    const handleRef = (ref) => {
        if (!ref) return;
        const joystickElem = joystick.current.getDomElement();
        if (Array(ref.children).includes(joystickElem)) return;
        ref.appendChild(joystickElem);
        joystick.current.operate();
    };

    return (
        <div
            className={className}
            onClick={onClick}
            ref={handleRef}
            disabled={disabled}
            {...otherProps}
        >
            {showLocker && (
                <LockJoystickButton
                    status={joystick.current.isLocked}
                    onPointerUp={() => {
                        joystick.current.isLocked
                            ? joystick.current.unlock()
                            : joystick.current.lock();
                    }}
                    onClick={() => {
                        joystick.current.unlock();
                    }}
                />
            )}
        </div>
    );
}

export default JoystickWrapper;
