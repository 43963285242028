import { customActionNameToMsg } from "../lib/state";
import { useMission } from "./MissionProvider";

function CustomActionStatus() {
    const {
        missionPayload: { extra_info: { custom_action_name = null } = {} },
    } = useMission();
    return (
        <div>
            {custom_action_name ? customActionNameToMsg(custom_action_name) : "Custom Action"}{" "}
            Running
        </div>
    );
}

export default CustomActionStatus;
