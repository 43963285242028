import React from "react";
import EntityAbsence from "./EntityAbsence";

class LogHistoryErrorFallback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }
    componentDidCatch(err, errInfo) {
        console.error("LogHistoryErrorFallback got error:");
        console.error(err);
        console.error(errInfo);
        this.setState({
            hasError: true,
        });
    }
    componentDidUpdate() {
        if (this.state.hasError) {
            this.setState({ hasError: false });
        }
    }
    render() {
        if (!this.state.hasError) {
            return this.props.children;
        } else {
            return (
                <EntityAbsence
                    title={"It looks like mission log is corrupted..."}
                    description={"If all mission logs appeared corrupted, please contact support."}
                    btnText={"Select another mission"}
                    path={"/main/logs"}
                ></EntityAbsence>
            );
        }
    }
}

export default LogHistoryErrorFallback;
