import { createContext, useContext, useEffect, useState } from "react";

import { DEFAULT_ARM_STATE } from "../consts";
import { subscribe, unsubscribe } from "../lib/socket";
import {
    TOPIC_STATE_ARM_END_POS as TOPIC_STATE_ARM_END_EFFECTOR_STATE,
    TOPIC_STATE_ARM_END_POS,
    TOPIC_STATE_ARM_GRIP_DEPTH,
    TOPIC_STATE_ARM_JOINT_POS,
    TOPIC_STATE_ARM_MODE,
    TOPIC_STATE_ARM_TORQUE,
    TOPIC_STATE_HAS_ARM,
} from "../lib/state";
import { useSocket } from "./ControlSocketProvider";

export const ArmStateContext = createContext();
ArmStateContext.displayName = "ArmState";
export const ArmStateConsumer = ArmStateContext.Consumer;
export const useArmState = () => useContext(ArmStateContext);

const topicStateMap = {
    armMode: TOPIC_STATE_ARM_MODE,
    armJointPos: TOPIC_STATE_ARM_JOINT_POS,
    armGripperPos: TOPIC_STATE_ARM_END_POS,
    hasArm: TOPIC_STATE_HAS_ARM,
    armGripDepth: TOPIC_STATE_ARM_GRIP_DEPTH,
    armTorque: TOPIC_STATE_ARM_TORQUE,
    armEndEffectorState: TOPIC_STATE_ARM_END_EFFECTOR_STATE,
};

const ARM_STATE_KEYS = Object.keys(DEFAULT_ARM_STATE);

const computeExtraStates = (armStates) => {
    const gripperFraction = armStates.armEndEffectorState?.open_fraction || 0;
    // console.log("gripper Fraction", gripperFraction);
    const isGripperOpen = gripperFraction >= 0.1;
    // console.log("is open? ", isGripperOpen);
    return { ...armStates, isGripperOpen };
};

function ArmStateProvider({ children }) {
    const { controlSocket: socket } = useSocket();
    const [armStates, setArmStates] = useState(DEFAULT_ARM_STATE);

    const setArmState = (key, state) => {
        setArmStates((prev) => {
            return computeExtraStates({
                ...prev,
                [key]: state,
            });
        });
    };

    useEffect(() => {
        const handlers = {};

        ARM_STATE_KEYS.forEach((key) => {
            const handler = (data) => {
                // console.log("arm state: handling ", key, "; data: ", data);
                setArmState(key, data);
            };
            handlers[key] = handler;
            subscribe(socket, topicStateMap[key], handler);
        });

        return () => {
            if (!socket) return;
            ARM_STATE_KEYS.forEach((key) => {
                unsubscribe(socket, topicStateMap[key], handlers[key]);
            });
        };
    }, [socket]);

    // useEffect(() => {
    //     console.log("arm joint pos: ", armStates.armJointPos);
    // }, [armStates]);

    return (
        <ArmStateContext.Provider value={{ ...armStates, setArmState }}>
            {children}
        </ArmStateContext.Provider>
    );
}

export default ArmStateProvider;
