import styles from "./css/ActionBanner.module.css";
import Icon from "./Icon";

function ActionBanner({ icon, iconType, action, actionDesc, canExec, onCancel, onExecute }) {
    return (
        <div className={styles.banner}>
            <Icon icon={icon} type={iconType} width="24px" height="24px"></Icon>
            <div className={styles.action}>
                <h3>{action}</h3>
                <p>{actionDesc}</p>
            </div>
            <button className={styles["btn-cancel"]} onClick={onCancel}>
                Cancel Action
            </button>
            {canExec && (
                <button className={styles["btn-exec"]} onClick={onExecute}>
                    Execute
                </button>
            )}
        </div>
    );
}

export default ActionBanner;
