import { useContext, useState } from "react";
import { cameras } from "../lib/protocols/streams";
import { CameraContext } from "./CameraProvider";
import { useSnack } from "./SnackProvider";

import ActionInspection from "./ActionInspection";
import DialogBox from "./DialogBox";
import Section from "./Section";

import styles from "./css/ActionTab.module.css";

function ActionTab() {
    const { selectedCam, onSelectCam } = useContext(CameraContext);
    const [showActionConfirm, setShowActionConfirm] = useState(false);
    const [showRetryConfirm, setShowRetryConfirm] = useState(false);
    const [selectedAction, setSelectedAction] = useState("Inspection");
    const [movementSelected, setMovementSelected] = useState(null);
    const { setSnack } = useSnack();

    const actions = [
        // { label: "Interactive actions", component: ActionInteractive },
        { label: "Inspection", component: ActionInspection },
        // { label: "Snapshot", component: ActionSnapshot },
    ];

    const movements = [
        {
            label: "Look up & down",
            onSelect: () => {},
        },
        {
            label: "Look Sideways",
            onSelect: () => {},
        },
        {
            label: "Look around 360",
            onSelect: () => {},
        },
    ];

    return (
        <>
            <div className={styles["action-container"]}>
                <Section
                    style={{
                        margin: "10px 10px 0 10px",
                        "border-bottom-color": "transparent",
                    }}
                >
                    <Section.Header>Action</Section.Header>
                    <Section.Content>
                        <div className={styles.tabs}>
                            {actions.map(({ label }, index) => (
                                <h3
                                    key={index}
                                    className={
                                        selectedAction === label ? styles["tab-selected"] : null
                                    }
                                    onClick={() => setSelectedAction(label)}
                                >
                                    {label}
                                </h3>
                            ))}
                        </div>
                        {actions.map(({ label, component: Component }) => (
                            <Component isShow={selectedAction === label}></Component>
                        ))}
                    </Section.Content>
                </Section>
            </div>
            {false && (
                <>
                    <Section>
                        <Section.Header>Select Camera</Section.Header>
                        <Section.Content>
                            <div className={styles.grid}>
                                {cameras.map((c) => {
                                    const selected = selectedCam === c.name;

                                    return (
                                        <button
                                            key={c.name}
                                            type="button"
                                            onClick={() => onSelectCam(c.name)}
                                            className={`${styles.camera} ${
                                                selected && styles.selected
                                            }`}
                                        >
                                            {c.name}
                                        </button>
                                    );
                                })}
                            </div>
                        </Section.Content>
                    </Section>
                    <Section>
                        <Section.Header>Movement</Section.Header>
                        <Section.Content>
                            <div className={styles.grid}>
                                {movements.map(({ label, onSelect }) => (
                                    <button
                                        className={`${styles.btn} ${
                                            movementSelected === label ? styles.active : ""
                                        }`.trim()}
                                        onClick={() => {
                                            setMovementSelected(label);
                                            onSelect();
                                        }}
                                    >
                                        {label}
                                    </button>
                                ))}
                            </div>
                        </Section.Content>
                    </Section>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button
                            className={styles["btn-finish"]}
                            onClick={() => setShowActionConfirm(true)}
                        >
                            Finish Action
                        </button>
                    </div>
                </>
            )}

            {showActionConfirm && (
                <DialogBox
                    title="Save Action Record"
                    textConfirm="Next"
                    textCancel="Retry"
                    onCancel={() => setShowRetryConfirm(true)}
                    onConfirm={() => {
                        setSnack({
                            type: "success",
                            message: "The action record is successfully saved",
                        });
                        setShowActionConfirm(false);
                    }}
                >
                    <p>Confirm the AI Inspection position to save action record</p>
                    <video className={styles["video-confirm"]} controls>
                        <source src={"/video"} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </DialogBox>
            )}
            {showRetryConfirm && (
                <DialogBox
                    options={{ areYouSure: "Retry" }}
                    onCancel={() => setShowRetryConfirm(false)}
                    onConfirm={() => {
                        setShowActionConfirm(false);
                        setShowRetryConfirm(false);
                    }}
                ></DialogBox>
            )}
        </>
    );
}

export default ActionTab;
