import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
    ARM_AUTO_GRIP,
    ARM_MANIP_MANUAL,
    ARM_MANIP_VALVE_TURN,
    autoGrip,
    joyCtrl,
    joyValveCtrl,
} from "../lib/protocols/control";

import useStorage from "../lib/hooks/useStorage";
import { subscribe, unsubscribe } from "../lib/socket";
import { TOPIC_STATE_AUTO_GRIP } from "../lib/state";
import { useSocket } from "./ControlSocketProvider";
import { useControl } from "./RobotControlProvider";
import { useSnack } from "./SnackProvider";

export const ArmControlContext = React.createContext();
ArmControlContext.displayName = "ArmControl";
export const ArmControlConsumer = ArmControlContext.Consumer;
export const useArmControl = () => useContext(ArmControlContext);

const isZero = (val) => Math.abs(val) === 0;

function ArmControlProvider({ children }) {
    const { controlSocket: socket } = useSocket();
    const { setSnack } = useSnack();
    const { sendControl } = useSocket();
    const [movement, setMovement] = useState({ leftX: 0, leftY: 0, rightX: 0, rightY: 0 });
    const [inArmControl, setInArmControl] = useStorage("arm:control/inArmControl", false);
    const [armAction, setArmAction] = useState(null);
    const [crosshair, setCrosshair] = useState(null);
    const [pointerPos, setPointerPos] = useState(null);
    const [armIsMain, setArmIsMain] = useState(false);
    // localStow , localGrip should be deleted after backend fix Stow and Grip State
    const [localStow, setLocalStow] = useState(true);
    const [localGrip, setLocalGrip] = useState(false);
    const { speed } = useControl();
    const [armManipMode, setArmManipMode] = useStorage(
        "arm:control/armManipMode",
        ARM_MANIP_MANUAL
    );
    const isAutoGripMode = armManipMode === ARM_AUTO_GRIP;
    const handleArmAction = useCallback((event) => {
        console.log(`TOPIC_STATE_AUTO_GRIP : ${event}`);
    }, []);

    useEffect(() => {
        subscribe(socket, TOPIC_STATE_AUTO_GRIP, handleArmAction);
        return () => {
            socket && unsubscribe(socket, TOPIC_STATE_AUTO_GRIP, handleArmAction);
        };
    }, [socket]);

    const sendPointerPos = () => {
        if (!pointerPos) return;
        const res = { x: pointerPos.positionX, y: pointerPos.positionY };
        sendControl(autoGrip(res));
        setSnack({ message: "Grabbing" });
        setArmManipMode(ARM_MANIP_VALVE_TURN);
    };
    const handleAutoGrip = () => {
        sendPointerPos();
        setPointerPos(null);
    };
    const changeMovement = ({ position, value = 0 }) => {
        if (!position || !["leftX", "leftY", "rightX", "rightY"].includes(position)) return;

        setMovement((prev) => ({ ...prev, [position]: value }));
    };

    const movementRef = useRef(movement);
    const isStoppedRef = useRef(true);
    const periodicMovementId = useRef(0);

    const sendMovement = useCallback(
        (movement) => {
            const {
                leftX: leftXRaw,
                leftY: leftYRaw,
                rightX: rightXRaw,
                rightY: rightYRaw,
            } = movement;
            const joyInputs = [
                leftXRaw * speed,
                leftYRaw * speed,
                rightXRaw * speed,
                rightYRaw * speed,
                // triggers
                0,
                0,
            ];
            if (armManipMode === ARM_MANIP_VALVE_TURN) {
                sendControl(joyValveCtrl(...joyInputs));
            } else if (armManipMode === ARM_MANIP_MANUAL) {
                sendControl(joyCtrl(...joyInputs));
            }
        },
        [sendControl, armManipMode, speed]
    );

    const noMovement = (m) => Object.entries(m).every(([_, v]) => isZero(v));

    // update movementRef when movement changes. the interval will uses the reference to periodically send current movement info to robot and keep it moving if movements are not zero
    useEffect(() => {
        movementRef.current = movement;
    }, [movement]);
    useEffect(() => {
        periodicMovementId.current = setInterval(() => {
            const m = movementRef.current;
            // const hasNoMovement = true;
            const hasNoMovement = noMovement(m);
            // if it's stopped already and there's no movement, don't do anything
            if (isStoppedRef.current && hasNoMovement) return;
            // else send movement
            // console.log("sending arm movement...", m);
            sendMovement(m);
            // if there is no movement in m, mark as stopped. this interval will not send movement next time.
            isStoppedRef.current = hasNoMovement;
        }, 100);
        return () => {
            periodicMovementId.current && clearInterval(periodicMovementId.current);
        };
    }, [sendMovement]);

    return (
        <ArmControlContext.Provider
            value={{
                inArmControl,
                toggleArmControl: setInArmControl,
                changeArmMovement: changeMovement,
                armAction,
                setArmAction,
                crosshair,
                pointerPos,
                setPointerPos,
                armManipMode,
                setArmManipMode,
                handleAutoGrip,
                isAutoGripMode,
                localStow,
                localGrip,
                setLocalStow,
                setLocalGrip,
                armIsMain,
                setArmIsMain,
            }}
        >
            {children}
        </ArmControlContext.Provider>
    );
}

export default ArmControlProvider;
